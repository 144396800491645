import React from "react";
import { Box, Paper } from "@mui/material";
import paperBg from "../../images/paper_background.png";
import soccerBlack from "../../images/soccer_black.png";
import soccerRed from "../../images/soccer_red.png";
import soccerWhite from "../../images/soccer_white.png";
import basketball from "../../images/basketball.png";
import { useIsMobile } from "../../hooks";

export function PaperBackground({
  children,
  images,
}: {
  children: any;
  images: string[];
}) {
  const isMobile = useIsMobile();
  if (isMobile) {
    return children;
  }

  return (
    <Paper
      sx={{
        backgroundImage: `url('${paperBg}')`,
        backgroundSize: "cover",
        width: "80%",
        height: "fit-content",
        py: 8,
        margin: "auto",
        mb: 8,
        position: "relative",
        overflow: images.includes("soccer_black") ? "hidden" : "visible",
      }}
    >
      {!!images.includes("soccer_black") && (
        <Box
          component="img"
          src={soccerBlack}
          sx={{ position: "absolute", bottom: 0 }}
        />
      )}
      {!!images.includes("soccer_red") && (
        <Box
          component="img"
          src={soccerRed}
          sx={{ position: "absolute", top: 0, left: 0, width: 500 }}
        />
      )}
      <Box sx={{ zIndex: 100, position: "relative" }}>{children}</Box>
      {!!images.includes("basketball") && (
        <Box
          component="img"
          src={basketball}
          sx={{ position: "absolute", bottom: 8, right: 8, zIndex: 99 }} // TODO why? 200
        />
      )}
      {!!images.includes("soccer_white") && (
        <Box
          component="img"
          src={soccerWhite}
          sx={{
            position: "absolute",
            bottom: 8,
            right: -100,
            zIndex: 99,
            width: 500,
          }}
        />
      )}
    </Paper>
  );
}

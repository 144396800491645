import { FC } from 'react';
import { MatchOverviewProps } from '../match-overview';
import { formatDateWithName, setLocalTime } from '../../../../../utils';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { MatchOverviewRowMobile } from './match-overview-row-mobile';
import { BetSlipMatchInfo } from '../../../../bet-slip/stores/bet-slip-store';
import { BetSlipMatchContext } from '../../../contexts/bet-slip-match-context';

export const MatchOverviewMobile: FC<MatchOverviewProps> = ({
    matchOverview,
    onMatchClick,
}) => {
    const { match } = matchOverview;

    const betSlipMatchInfo: BetSlipMatchInfo = {
        id: match.id,
        teams: match.teams!.map(({ name }) => name!),
    };

    const day = formatDateWithName(match.startDate, true);
    const time = setLocalTime(match.startDate);

    return (
        <BetSlipMatchContext.Provider value={betSlipMatchInfo}>
            <Box
                sx={{
                    background: 'white',
                    p: 2,
                    pt: 0,
                }}
            >
                <Typography variant={'caption'} color={'textSecondary'}>
                    {day} {time}
                </Typography>
                {matchOverview.odds.rows?.length === 1 ? (
                    <MatchOverviewRowMobile
                        onMatchClick={onMatchClick}
                        matchOverview={matchOverview}
                        currentOdds={matchOverview.odds.rows![0].odds!}
                    />
                ) : (
                    <Box display={'flex'} flexDirection={'column'} gap={0.2}>
                        {matchOverview.match.teams?.map((team, index) => {
                            return (
                                <MatchOverviewRowMobile
                                    onMatchClick={onMatchClick}
                                    key={team.id}
                                    matchOverview={matchOverview}
                                    currentTeam={team}
                                    currentOdds={
                                        matchOverview.odds.rows![index].odds!
                                    }
                                />
                            );
                        })}
                    </Box>
                )}
            </Box>
        </BetSlipMatchContext.Provider>
    );
};

import { inject, injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState, FormState } from 'formstate';
import { formStateValidators } from '../../common/utils/form-state-validators';
import { apiClient } from '../../common/api/api-client';
import { IUserLoginModel, UserLoginModel } from '../../common/api/api';
import { AuthStore } from '../../common/stores/auth-store';
import { LoadStatus } from '../../common/enums/load-status';

@injectable()
export class LoginStore {
    @inject(AuthStore) private readonly authStore!: AuthStore;

    formState = new FormState({
        username: new FieldState('').validators(formStateValidators.required),
        password: new FieldState('').validators(formStateValidators.required),
    });
    loginLoadStatus = LoadStatus.None;

    constructor() {
        this.formState.disableAutoValidation();
        makeAutoObservable(this);
    }

    login = async () => {
        try {
            runInAction(() => {
                this.loginLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload: IUserLoginModel = {
                login: this.formState.$.username.$,
                password: this.formState.$.password.$,
            };

            const jwtAuthResult = await apiClient.accessToken(
                new UserLoginModel(payload)
            );

            this.authStore.authorize(jwtAuthResult);
        } catch {
            runInAction(() => {
                this.loginLoadStatus = LoadStatus.Error;
            });

            throw new Error('Unable to login');
        }
    };
}

import { inject, injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { LeaderboardView } from '../enums/leaderboard-view';
import { apiClient } from '../../common/api/api-client';
import { BetGetModel, GameParticipantModel } from '../../common/api/api';
import { AuthStore } from '../../common/stores/auth-store';

@injectable()
export class LeaderboardStore {
    @inject(AuthStore) private readonly authStore!: AuthStore;

    leaders: GameParticipantModel[] = [];
    gameId!: number;
    userIdForBetHistory?: (typeof this.leaders)[number]['id'];
    leaderboardView = LeaderboardView.Leaderboard;
    betHistory: BetGetModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init = async (gameId: number) => {
        runInAction(() => {
            this.gameId = gameId;
        });

        try {
            const { items: leaders } = await apiClient.leaderboard2(
                this.gameId,
                undefined,
                undefined
            );

            runInAction(() => {
                this.leaders = leaders ?? [];
            });
        } catch {
            // skip
        }
    };

    showBetHistory = (leaderId: typeof this.userIdForBetHistory) => {
        this.leaderboardView = LeaderboardView.BetHistory;
        this.userIdForBetHistory = leaderId;
        this.fetchBetHistory();
    };

    hideBetHistory = () => {
        this.leaderboardView = LeaderboardView.Leaderboard;
        this.userIdForBetHistory = undefined;
        this.betHistory = [];
    };

    fetchBetHistory = async () => {
        if (!this.userIdForBetHistory) {
            return;
        }

        try {
            const betHistory = await apiClient.getBets(
                this.gameId,
                this.userIdForBetHistory,
                undefined,
                undefined
            );

            runInAction(() => {
                this.betHistory = betHistory;
            });
        } catch {
            // skip
        }
    };

    get me() {
        const myIndex = this.leaders.findIndex(
            (leader) => leader.id === this.authStore.me?.id
        );

        if (myIndex !== -1) {
            return {
                info: this.leaders[myIndex],
                rank: myIndex + 1,
            };
        }
    }

    get userForBetHistory() {
        const index = this.leaders.findIndex(
            (leader) => leader.id === this.userIdForBetHistory
        );

        if (index !== -1) {
            return {
                info: this.leaders[index],
                rank: index + 1,
            };
        }
    }
}

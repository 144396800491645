import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { Leagues } from '../../../../common/components/leagues/leagues';

export interface SportLeaguesProps {
    onClickSportLeague: (sportLeagueId: number) => void;
}

export const CurrentGameSportLeagues: FC<SportLeaguesProps> = observer(
    ({ onClickSportLeague }) => {
        const [{ sportLeagueId, sports }] = useDependencies(
            CurrentLeagueGameStore
        );

        return (
            <Leagues
                sports={sports}
                selectedLeagueId={sportLeagueId}
                onChangeLeagueId={onClickSportLeague}
            />
        );
    }
);

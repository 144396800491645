import { createSlice } from "@reduxjs/toolkit";
import { gamesApi } from "../apiService/games";
import { TTournamentParticipatingById } from "../types";
import { MakeOddBet } from "../pages/Slate/types";

interface IGamesInitialState {
  tournament: TTournamentParticipatingById | null;
  activeOddsData: MakeOddBet[];
  selectedBetId: number | null;
  conflictBets: number[];
}

const initialState: IGamesInitialState = {
  tournament: null,
  activeOddsData: [],
  selectedBetId: null,
  conflictBets: [],
};

export const gameSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
    setActiveOddsData: (state, action) => {
      state.activeOddsData = action.payload;
    },
    addNewBet: (state, action) => {
      // const activeOddsData = state.activeOddsData;
      // const newBet = action.payload as MakeOddBet;
      state.activeOddsData = action.payload;

      // const isTheSameBet = activeOddsData.findIndex(
      //   (oddData: MakeOddBet) => oddData.odd.id === newBet.odd.id
      // );
      // if (isTheSameBet >= 0) {
      //   const updatedData = activeOddsData.filter(
      //     (oddData) => oddData.odd.id !== newBet.odd.id
      //   );
      //   state.activeOddsData = updatedData;
      // } else {
      // }
      // const isFromSameMatch = activeOddsData.findIndex(
      //   (oddData: MakeOddBet) => oddData.matchId === newBet.matchId
      // );
      // if (isTheSameBet < 0 && isFromSameMatch < 0) {
      //   state.activeOddsData = [...activeOddsData, newBet];
      // } else if (isTheSameBet >= 0) {
      //   // same bet remove
      //   const updatedData = activeOddsData.filter(
      //     (oddData) => oddData.odd.id !== newBet.odd.id
      //   );
      //   state.activeOddsData = updatedData;
      // } else if (isFromSameMatch >= 0) {
      //   // same match replace
      //   state.activeOddsData = activeOddsData.map((oddData) =>
      //     oddData.matchId === newBet.matchId ? newBet : oddData
      //   );
      // }
    },
    deleteAllBets: (state) => {
      state.activeOddsData = [];
      state.selectedBetId = null;
    },
    setSelectedBetId: (state, action) => {
      state.selectedBetId = action.payload;
    },
    deleteSingleBet: (state, action) => {
      state.selectedBetId = null;
      state.activeOddsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      gamesApi.endpoints.getTournamentsParticipating.matchFulfilled,
      (state, action) => {
        state.tournament = action.payload;
      }
    );
    builder.addMatcher(
      gamesApi.endpoints.getBetsConflicts.matchFulfilled,
      (state, action) => {
        state.conflictBets = action.payload;
      }
    );
  },
});

export const {
  addNewBet,
  setActiveOddsData,
  deleteSingleBet,
  deleteAllBets,
  setSelectedBetId,
} = gameSlice.actions;

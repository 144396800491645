import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { SlateStore } from '../stores/slate-store';
import { Leagues } from '../../common/components/leagues/leagues';
import { FC } from 'react';

export interface SlateLeaguesProps {
    onClickLeague: (leagueId: number) => void;
}

export const SlateLeagues: FC<SlateLeaguesProps> = observer(
    ({ onClickLeague }) => {
        const [{ sportLeagueId, sports }] = useDependencies(SlateStore);

        return (
            <Leagues
                sports={sports}
                selectedLeagueId={sportLeagueId}
                onChangeLeagueId={onClickLeague}
            />
        );
    }
);

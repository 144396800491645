import { injectable } from 'inversify';
import { makeAutoObservable } from 'mobx';

@injectable()
export class MyGamesStore {
    isCreatingGame = false;

    constructor() {
        makeAutoObservable(this);
    }

    showCreateGame = () => {
        this.isCreatingGame = true;
    };

    hideCreateGame = () => {
        this.isCreatingGame = false;
    };
}

import { TableHead, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { formatDateWithName } from '../../../../utils';
import { Balance } from '../../../../components/smallComponents';
import Box from '@mui/material/Box';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import Button from '@mui/material/Button';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Table from '@mui/material/Table';
import { FC, Fragment } from 'react';
import { LobbyGamesMobile } from './mobile/lobby-games-mobile';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { GameModel } from '../../../common/api/api';
import { noop } from '../../../common/utils/noop';
import { observer } from 'mobx-react-lite';
import { LobbyStore } from '../../stores/lobby-store';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import {
    LastElementSetter,
    TableColumnConfig,
} from '../../../common/utils/types';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';

export interface ILobbyGamesProps {
    setLastElement: LastElementSetter;
}

const lobbyColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: (game) => (
            <TypographyTableCell>#{game.id}</TypographyTableCell>
        ),
    },
    {
        title: 'Start Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.startDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.endDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: (game) => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Initial Balance',
        getComponent: (game) => (
            <Balance
                textProps={{ fontWeight: 600 }}
                amount={game.initialBalance}
            />
        ),
    },
    {
        title: 'Leagues',
        getComponent: (game) => <SportLeagueIconList leagues={game.leagues} />,
    },
];

export const LobbyGames: FC<ILobbyGamesProps> = observer(
    ({ setLastElement }) => {
        const [{ showParticipants, games, joinGame }, { notify }] =
            useDependencies(LobbyStore, NotificationsStore);

        const theme = useTheme();
        const isMobile = useIsMobile();
        const navigate = useNavigate();

        const handleJoinGame = (gameId: GameModel['id']) => {
            joinGame(gameId)
                .then((game) => {
                    notify({
                        severity: 'info',
                        children: (
                            <Fragment>
                                <Typography mr={2} variant={'body1'}>
                                    Joined game #{game.id}!
                                </Typography>
                                <Typography variant={'body2'}>
                                    You can find the game in&nbsp;
                                    <Link
                                        sx={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            // closeNotification();
                                            navigate('/my-games/upcoming');
                                        }}
                                    >
                                        Upcoming Games
                                    </Link>
                                    &nbsp;page.
                                </Typography>
                            </Fragment>
                        ),
                    });
                })
                .catch(noop);
        };

        if (isMobile) {
            return (
                <LobbyGamesMobile
                    onJoinGame={handleJoinGame}
                    setLastElement={setLastElement}
                />
            );
        }

        return (
            <CustomTable>
                <TableHead>
                    <TableRow>
                        {lobbyColumns.map((column, columnIndex) => (
                            <TableCell key={columnIndex}>
                                {column.title}
                            </TableCell>
                        ))}
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {games.map((game, gameIndex) => (
                        <TableRow
                            key={game.id}
                            ref={
                                gameIndex === games.length - 1
                                    ? setLastElement
                                    : undefined
                            }
                        >
                            {lobbyColumns.map((column, columnIndex) => (
                                <TableCell key={columnIndex}>
                                    {column.getComponent(game)}
                                </TableCell>
                            ))}
                            <TableCell align={'right'}>
                                <Box
                                    display={'flex'}
                                    gap={2}
                                    justifyContent={'flex-end'}
                                >
                                    <CustomIconButton
                                        onClick={() => showParticipants(game)}
                                        color={theme.palette.grey[800]}
                                    >
                                        <Groups2OutlinedIcon />
                                    </CustomIconButton>
                                    <Button
                                        sx={{
                                            border: '1px solid',
                                            boxShadow: 1,
                                        }}
                                        endIcon={<DirectionsWalkIcon />}
                                        onClick={() => handleJoinGame(game.id)}
                                    >
                                        Join
                                    </Button>
                                </Box>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        );
    }
);

import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { GameContentStore } from '../../stores/game-content-store';
import { CommonSportLayout } from '../../../common/components/common-sport-layout/common-sport-layout';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { useNavigate, useParams } from 'react-router-dom';
import { BetSlipStore } from '../../../bet-slip/stores/bet-slip-store';
import { LoadStatus } from '../../../common/enums/load-status';
import { GameContentLeagues } from './game-content-leagues';
import { GameContentMatchOverviews } from './game-content-match-overviews';
import { BetSlip } from '../../../bet-slip/components/bet-slip';
import { GameContentMatchOdds } from './game-content-match-odds';
import { GameContentActions } from './game-content-actions';
import { Leaderboard } from '../../../leaderboard/components/leaderboard';
import { BetHistory } from '../bet-history';

export const GameContentPage = provide([BetSlipStore, GameContentStore])(
    observer(() => {
        const [
            {
                sportLeagueId,
                sportLeaguesLoadStatus,
                init,
                selectSportLeague,
                selectMatch,
                sports,
                isShowingLeaderboard,
                hideLeaderboard,
                game,
                isShowingBetHistory,
                hideBetHistory,
            },
            { initBetSlip },
        ] = useDependencies(GameContentStore, BetSlipStore);

        const {
            gameId: gameIdParam,
            leagueId: leagueIdParam,
            matchId: matchIdParam,
        } = useParams();

        useEffect(() => {
            if (gameIdParam) {
                const gameId = +gameIdParam;

                initBetSlip(gameId);
                init(gameId);
            }
        }, []);

        useEffect(() => {
            // TODO: add number validation
            if (leagueIdParam) {
                selectSportLeague(+leagueIdParam);
            }
        }, [leagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam]);

        const navigate = useNavigate();

        const handleClickLeague = (selectedId: number, replace?: boolean) => {
            if (selectedId !== sportLeagueId) {
                navigate(`../${selectedId}`, { replace });
            }
        };

        useEffect(() => {
            if (
                sportLeaguesLoadStatus === LoadStatus.Ok &&
                leagueIdParam === undefined
            ) {
                const firstValidSport = sports.find(
                    (sport) => !!sport.leagues?.length
                );

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleClickLeague(firstLeague.id, true);
                }
            }
        }, [sportLeaguesLoadStatus]);

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <Fragment>
                <CommonSportLayout
                    leagues={
                        <GameContentLeagues onClickLeague={handleClickLeague} />
                    }
                    content={
                        matchIdParam ? (
                            <GameContentMatchOdds />
                        ) : (
                            <GameContentMatchOverviews
                                onClickLeague={handleClickLeague}
                            />
                        )
                    }
                    betSlip={<BetSlip onMatchClick={handleBetSlipMatchClick} />}
                    actions={<GameContentActions />}
                />
                {isShowingLeaderboard && game && (
                    <Leaderboard gameId={game.id} onClose={hideLeaderboard} />
                )}
                {isShowingBetHistory && game && (
                    <BetHistory gameId={game.id} onClose={hideBetHistory} />
                )}
            </Fragment>
        );
    })
);

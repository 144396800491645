import React from "react";
import Box from "@mui/material/Box";
import { Avatar, useTheme } from "@mui/material";

type Props = {
  src?: string;
  children?: JSX.Element;
  width: number;
  height: number | string;
};
const GradientContainer = (props: Props) => {
  const { src, width, height } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        width,
        height,
        margin: "1px !important",
        borderRadius: "50%",
        m: 1,
        background: `linear-gradient(to bottom, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        position: "relative",
      }}
    >
      <>
      <Avatar
        sx={{
          width: "95%",
          height: "95%",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Box component="img" src={src} sx={{ width: "100%", height: "100%" }} />
      </Avatar>
      </>
    </Box>
  );
};

export default GradientContainer;

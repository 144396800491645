import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { LoginStore } from '../../stores/login-store';
import LoginPageImage from '../../../../assets/images/login-page-image.png';
import { AuthLayout } from '../auth-layout';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import {
    Link as RouterLink,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { LoadStatus } from '../../../common/enums/load-status';
import { noop } from '../../../common/utils/noop';

export const Login = provide([LoginStore])(
    observer(() => {
        const [{ formState, loginLoadStatus, login }] =
            useDependencies(LoginStore);
        const [searchParams] = useSearchParams();

        const { username, password } = formState.$;
        const navigate = useNavigate();

        const handleSubmit = () => {
            login()
                .then(() => {
                    const returnURL = searchParams.get('returnURL');

                    if (returnURL) {
                        navigate(decodeURIComponent(returnURL));
                    }
                })
                .catch(noop);
        };

        return (
            <AuthLayout imgSrc={LoginPageImage}>
                <Box
                    component={'form'}
                    display="flex"
                    flexDirection="column"
                    width={'100%'}
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Typography
                        variant={'h4'}
                        color={'primary'}
                        fontWeight={900}
                        textAlign={'center'}
                        mb={2}
                    >
                        Log In
                    </Typography>
                    <Typography mb={3}>
                        Welcome back to Balance Bet!
                        <br />
                        Let’s play again.
                    </Typography>
                    <TextField
                        sx={{ mb: 3 }}
                        fullWidth
                        label="Email Address / Username"
                        required
                        autoComplete="username"
                        value={username.value}
                        error={username.hasError}
                        onChange={(event) => {
                            username.onChange(event.target.value);
                        }}
                        onBlur={username.enableAutoValidationAndValidate}
                    />
                    <TextField
                        sx={{ mb: 3 }}
                        fullWidth
                        label="Password"
                        required
                        autoComplete="current-password"
                        type="password"
                        value={password.value}
                        error={password.hasError}
                        onChange={(event) => {
                            password.onChange(event.target.value);
                        }}
                        onBlur={password.enableAutoValidationAndValidate}
                    />
                    <Link
                        mb={3}
                        textAlign={'right'}
                        underline={'hover'}
                        component={RouterLink}
                        to={'/forgot-password'}
                    >
                        Forgot Password?
                    </Link>
                    <Button
                        sx={{ mb: 4 }}
                        variant={'contained'}
                        type={'submit'}
                        disabled={
                            formState.hasError ||
                            loginLoadStatus === LoadStatus.Loading
                        }
                    >
                        Log In
                    </Button>
                    <Typography variant={'body1'}>
                        Don’t have an account yet?{' '}
                        <Link
                            underline={'hover'}
                            component={RouterLink}
                            to={'/signup'}
                        >
                            Sign Up
                        </Link>{' '}
                        {/*& get{' '}*/}
                        {/*<Typography component={'span'} fontWeight={900}>*/}
                        {/*    30 days of free trial!*/}
                        {/*</Typography>*/}
                    </Typography>
                </Box>
            </AuthLayout>
        );
    })
);

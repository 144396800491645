import { inject, injectable } from 'inversify';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { LeagueContentStore } from './league-content-store';
import {
    GameLeagueScoreboardItemModel,
    GameLeagueStandingsModel,
    GameLeagueWeekModel,
} from '../../common/api/api';
import { getRangeForCurrentDate } from '../utils/get-range-for-current-date';

@injectable()
export class StandingsStore {
    @inject(LeagueContentStore)
    private readonly leagueContentStore!: LeagueContentStore;

    standings?: GameLeagueStandingsModel;
    weeks: GameLeagueWeekModel[] = [];
    roundNumber?: GameLeagueWeekModel['roundNumber'];
    leagueId!: number;
    scoreboard: GameLeagueScoreboardItemModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        await when(() => !!this.leagueContentStore.league);
        this.leagueId = this.leagueContentStore.league!.id;

        try {
            const [standings, weeks] = await Promise.all([
                apiClient.standings(this.leagueId),
                apiClient.weeks(this.leagueId),
            ]);

            const currentWeek = getRangeForCurrentDate(weeks) ?? weeks[0];

            runInAction(() => {
                this.weeks = weeks;
                this.roundNumber = currentWeek.roundNumber;
                this.standings = standings;
            });

            this.fetchScoreboard();
        } catch {
            //skip
        }
    };

    changeRound = (roundNumber: typeof this.roundNumber) => {
        this.roundNumber = roundNumber;

        this.fetchScoreboard();
    };

    fetchScoreboard = async () => {
        if (this.roundNumber === undefined) {
            return;
        }

        try {
            const scoreboard = await apiClient.getScoreboard(
                this.leagueId,
                this.roundNumber
            );

            runInAction(() => {
                this.scoreboard = scoreboard;
            });
        } catch {
            //
        }
    };
}

import { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { GameFullModel } from '../../../common/api/api';
import Dialog from '@mui/material/Dialog';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { GameListItemMobile } from '../../../common/components/games/mobile/game-list-item-mobile';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { LobbyStore } from '../../stores/lobby-store';
import Link from '@mui/material/Link';
import { noop } from '../../../common/utils/noop';
import { Navigate, useNavigate } from 'react-router-dom';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { ColoredBorder } from '../../../common/components/colored-border/colored-border';

interface InvitedGameProps {
    game: GameFullModel;
}

export const InvitedGame: FC<InvitedGameProps> = observer(({ game }) => {
    const [
        { showParticipants, joinInvitedGame, cancelGameInvitation },
        { notify },
    ] = useDependencies(LobbyStore, NotificationsStore);

    const theme = useTheme();
    const navigate = useNavigate();

    const gamePathname = `/my-games/content/${game.id}`;

    // TODO: improve typing
    if (game.currentBalance !== null && game.currentBalance !== undefined) {
        return <Navigate to={gamePathname} replace />;
    }

    const handleJoinGame = () => {
        joinInvitedGame(game.id)
            .then(() => {
                notify({
                    severity: 'info',
                    children: (
                        <Fragment>
                            <Typography mr={2} variant={'body1'}>
                                Joined game #{game.id}!
                            </Typography>
                            <Typography variant={'body2'}>
                                You can find the game in&nbsp;
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        // closeNotification();
                                        navigate('/my-games/upcoming');
                                    }}
                                >
                                    Upcoming Games
                                </Link>
                                &nbsp;page.
                            </Typography>
                        </Fragment>
                    ),
                });

                navigate(gamePathname, { replace: true });
            })
            .catch(noop);
    };

    return (
        <Dialog open onClose={cancelGameInvitation} fullWidth maxWidth={'xs'}>
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                >
                    <Typography variant={'h5'} fontWeight={'700'}>
                        You’re invited to join
                    </Typography>
                    <IconButton onClick={cancelGameInvitation}>
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent>
                <ColoredBorder sx={{ borderRadius: 1 }}>
                    <GameListItemMobile
                        game={game}
                        actions={
                            <Box display={'flex'} gap={2}>
                                <CustomIconButton
                                    onClick={() => showParticipants(game)}
                                    color={theme.palette.grey[800]}
                                >
                                    <Groups2OutlinedIcon />
                                </CustomIconButton>
                                <Button
                                    onClick={handleJoinGame}
                                    endIcon={<DirectionsWalkIcon />}
                                    fullWidth
                                    variant={'outlined'}
                                    sx={{
                                        boxShadow: 1,
                                    }}
                                >
                                    <Typography>Join</Typography>
                                </Button>
                            </Box>
                        }
                    />
                </ColoredBorder>
            </DialogContent>
        </Dialog>
    );
});

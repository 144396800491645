import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface ISectionImageContainerProps {
    src: string;
    headline: string;
    description: string;
}

export const SectionImageContainer: FC<ISectionImageContainerProps> = ({
    src,
    description,
    headline,
}) => {
    return (
        <Box
            sx={{
                borderRadius: '20px',
                height: { xs: '140px', sm: '184px' },
                background:
                    'linear-gradient(white, white) padding-box, linear-gradient(to bottom, #5BE734, #2B5FD9) border-box',
                border: '4px solid transparent',
                overflow: 'hidden',
            }}
        >
            <Box
                sx={{
                    background: `linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .7)), url(${src})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: '50% 65%',
                    height: '100%',
                    padding: 4,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        textAlign: 'center',
                    }}
                >
                    <Typography variant={'h6'} color={'white'}>
                        {description}
                    </Typography>
                    <Typography variant={'h3'} fontWeight={900} color={'white'}>
                        {headline}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

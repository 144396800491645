import { inject, injectable } from 'inversify';
import { FieldState, FormState } from 'formstate';
import { formStateValidators } from '../../common/utils/form-state-validators';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import { AuthStore } from '../../common/stores/auth-store';
import {
    IUserUpdateModel,
    PlayerPrivacySetting,
    UserUpdateModel,
} from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import { NotificationsStore } from '../../common/stores/notifications-store';

@injectable()
export class ProfileStore {
    profileDataChangeLoadStatus = LoadStatus.None;

    formState = new FormState({
        fullName: new FieldState('').validators(formStateValidators.required),
        username: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.username
        ),
        phoneNumber: new FieldState('').validators(
            formStateValidators.required,
            formStateValidators.phoneNumber
        ),
        privacySetting: new FieldState(PlayerPrivacySetting.Public),
    });

    @inject(AuthStore) private readonly authStore!: AuthStore;
    @inject(NotificationsStore)
    private readonly notificationsStore!: NotificationsStore;

    constructor() {
        makeAutoObservable(this);
    }

    init = () => {
        if (!this.authStore.me) {
            return;
        }

        this.formState.$.fullName.onChange(this.authStore.me.fullName ?? '');
        this.formState.$.username.onChange(this.authStore.me.username ?? '');
        this.formState.$.phoneNumber.onChange(
            this.authStore.me.phoneNumber ?? ''
        );
        this.formState.$.privacySetting.onChange(
            this.authStore.me.privacySetting
        );
    };

    save = async () => {
        try {
            runInAction(() => {
                this.profileDataChangeLoadStatus = LoadStatus.Loading;
            });

            await this.formState.enableAutoValidationAndValidate();

            if (this.formState.hasError) {
                throw new Error('Validation error');
            }

            const payload = {
                fullName: this.formState.$.fullName.$,
                phoneNumber: this.formState.$.phoneNumber.$,
                email: undefined,
                avatarImageId: undefined,
                privacySetting: this.formState.$.privacySetting.$,
                oddPresentationSystem: undefined,
            } satisfies IUserUpdateModel;

            await apiClient.usersPUT(new UserUpdateModel(payload));

            this.notificationsStore.notify({
                severity: 'success',
                children: 'Successfully saved!',
            });

            runInAction(() => {
                this.profileDataChangeLoadStatus = LoadStatus.Ok;
                this.authStore.me!.fullName = payload.fullName;
                this.authStore.me!.phoneNumber = payload.phoneNumber;
                this.authStore.me!.privacySetting = payload.privacySetting;
            });
        } catch {
            runInAction(() => {
                this.profileDataChangeLoadStatus = LoadStatus.Error;
            });
        }
    };
}

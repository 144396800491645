import { injectable } from 'inversify';
import { makeAutoObservable, runInAction, when } from 'mobx';
import { PlayerUsernameModel } from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import axios from 'axios';
import { LoadStatus } from '../../common/enums/load-status';
import { ShareLinkGetter } from '../utils/types';

@injectable()
export class InvitePlayersStore {
    selectedPlayers: PlayerUsernameModel[] = [];
    searchedPlayers: PlayerUsernameModel[] = [];
    shareLink?: string;
    abortController?: AbortController;
    loadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    setSelectedPlayers = (players: PlayerUsernameModel[]) => {
        this.selectedPlayers = players;
    };

    init = async (getShareLink: ShareLinkGetter) => {
        this.searchPlayers('');

        try {
            const shareLink = await getShareLink();

            runInAction(() => {
                this.shareLink = shareLink;
            });
        } catch {
            //
        }
    };

    searchPlayers = async (searchTerm: string) => {
        this.abortController?.abort();
        await when(() => this.loadStatus !== LoadStatus.Loading);

        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        this.abortController = new AbortController();

        try {
            const searchedPlayers = await apiClient.usernames(
                searchTerm,
                undefined,
                this.abortController?.signal
            );

            runInAction(() => {
                this.searchedPlayers = searchedPlayers;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch (error) {
            if (axios.isCancel(error)) {
                runInAction(() => {
                    this.loadStatus = LoadStatus.Ok;
                });
            } else {
                runInAction(() => {
                    this.searchedPlayers = [];
                    this.loadStatus = LoadStatus.Error;
                });
            }
        }
    };
}

import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../common/utils/ioc/provide';
import { BetHistoryStore } from '../stores/bet-history-store';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { ButtonGroup, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { Bet } from '../../common/components/bet/bet';
import Button from '@mui/material/Button';
import { BetStateEnum } from '../../common/api/api';
import { useIsMobile } from '../../common/hooks/use-is-mobile';

interface BetHistoryProps {
    gameId: number;
    onClose: () => void;
}

export const BetHistory: FC<BetHistoryProps> = provide([BetHistoryStore])(
    observer(({ gameId, onClose }) => {
        const [{ init, betsToShow, selectBetState, selectedBetState }] =
            useDependencies(BetHistoryStore);

        const isMobile = useIsMobile();

        useEffect(() => {
            init(gameId);
        }, []);

        return (
            <Dialog
                open
                fullScreen={isMobile}
                onClose={onClose}
                fullWidth
                maxWidth={'md'}
            >
                <Box
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={'primary'}
                        >
                            History
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={onClose}
                        sx={{ p: 0, position: 'absolute', right: 22 }}
                    >
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent
                    sx={{ background: '#f9f9f9', px: { sm: '100px' } }}
                >
                    <Box display={'flex'} justifyContent={'center'} mb={3}>
                        <ButtonGroup size={'large'}>
                            {Object.values(BetStateEnum).map((betState) => {
                                return (
                                    <Button
                                        key={betState}
                                        variant={
                                            betState === selectedBetState
                                                ? 'contained'
                                                : undefined
                                        }
                                        onClick={() => selectBetState(betState)}
                                    >
                                        {betState}
                                    </Button>
                                );
                            })}
                        </ButtonGroup>
                    </Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                        key={selectedBetState}
                    >
                        {betsToShow.map((bet) => {
                            return (
                                <Bet key={bet.placedDate.getTime()} bet={bet} />
                            );
                        })}
                    </Box>
                </DialogContent>
            </Dialog>
        );
    })
);

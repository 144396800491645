import {
    ConferencePlayerInfoModel,
    LeaderboardItemModel,
} from '../../../../common/api/api';
import { FC } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import { StandingsTypographyCell } from './standings-typography-cell';

interface PlayerInfoProps {
    player: Partial<ConferencePlayerInfoModel>;
}

export const PlayerInfo: FC<PlayerInfoProps> = ({ player }) => {
    return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
            <Avatar sx={{ width: 36, height: 36 }} src={player.avatarUrl} />
            <StandingsTypographyCell sx={{ wordBreak: 'break-all' }}>
                {player.username}
            </StandingsTypographyCell>
        </Box>
    );
};

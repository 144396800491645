import { GameParticipantModel } from '../../api/api';
import { FC } from 'react';
import Box from '@mui/material/Box';
import { Rank } from './rank';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Balance } from '../../../../components/smallComponents';
import { CardActionArea } from '@mui/material';
import Card from '@mui/material/Card';

interface GamePlayerProps {
    player: GameParticipantModel;
    rank?: number;
    onClick?: () => void;
    centered?: boolean;
}

export const GamePlayer: FC<GamePlayerProps> = ({
    player,
    centered,
    rank,
    onClick,
}) => {
    const { avatarUrl, username, balance } = player;

    let content = (
        <Box
            px={3}
            py={1.5}
            display="flex"
            alignItems={'center'}
            justifyContent={centered ? 'center' : undefined}
            gap={1.5}
        >
            {rank !== undefined && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: 48,
                        height: 48,
                        flexShrink: 0,
                    }}
                >
                    <Rank rank={rank} />
                </Box>
            )}
            <Avatar sx={{ width: 48, height: 48 }} src={avatarUrl} />
            <Box display={'flex'} flexDirection={'column'} gap={0.5}>
                <Typography variant={'body1'} sx={{ wordBreak: 'break-all' }}>
                    {username}
                </Typography>
                <Balance amount={balance} textProps={{ fontWeight: 600 }} />
            </Box>
        </Box>
    );

    if (onClick) {
        content = <CardActionArea onClick={onClick}>{content}</CardActionArea>;
    }

    return <Card>{content}</Card>;
};

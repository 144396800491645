import { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { GameLeaguePublicModel } from '../../../common/api/api';
import { provide } from '../../../common/utils/ioc/provide';
import { CreateLeagueStore } from '../../stores/create-league-store';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import {
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    useTheme,
} from '@mui/material';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { noop } from '../../../common/utils/noop';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Balance } from '../../../../components/smallComponents';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { LoadStatus } from '../../../common/enums/load-status';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FieldState } from 'formstate';
import { Moment } from 'moment/moment';

interface CreateLeagueProps {
    onClose: () => void;
    successCallback?: (gameLeague?: GameLeaguePublicModel) => void;
}

// between 6 and 48 even numbers
const maxPlayerOptions = new Array(43)
    .fill(0)
    .map((_v, index) => index + 6)
    .filter((i) => !(i % 2));

export const CreateLeague: FC<CreateLeagueProps> = provide([CreateLeagueStore])(
    observer(({ onClose, successCallback }) => {
        const [
            {
                sportLeagues,
                submitCreateLeague,
                formState,
                createLeagueLoadStatus,
                initialBalanceOptions,
                selectSportLeague,
                initCreateLeague,
                changeMaxPlayers,
                playoffPlayerOptions,
            },
        ] = useDependencies(CreateLeagueStore);

        const theme = useTheme();
        const isMobile = useIsMobile();

        const {
            sportLeagueIds,
            initialBalance,
            maxPlayers,
            name,
            playoffPlayers,
            startDate,
            startTime,
        } = formState.$;

        useEffect(() => {
            initCreateLeague();
        }, []);

        const handleSubmit = () => {
            submitCreateLeague()
                .then((league) => {
                    onClose();
                    successCallback?.(league);
                })
                .catch(noop);
        };

        const handleDateChange =
            (field: FieldState<Date | null>) => (value: Moment | null) => {
                field.onChange(value?.toDate() ?? null);
            };

        return (
            <Dialog
                maxWidth="md"
                fullScreen={isMobile}
                open={true}
                onClose={onClose}
                fullWidth
            >
                <Box
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={theme.palette.primary.main}
                        >
                            League Creation
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={onClose}
                        sx={{ p: 0, position: 'absolute', right: 22 }}
                    >
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent
                    sx={{ background: '#f9f9f9', px: { sm: '100px' }, pb: 4 }}
                >
                    <Box
                        component={'form'}
                        onSubmit={(event) => {
                            event.preventDefault();
                            handleSubmit();
                        }}
                    >
                        <Box mb={2}>
                            <Typography
                                sx={{ mb: 2 }}
                                variant={'subtitle2'}
                                fontWeight={900}
                                color={
                                    sportLeagueIds.error
                                        ? theme.palette.error.main
                                        : undefined
                                }
                            >
                                Choose Sports
                            </Typography>
                            <Grid container spacing={3}>
                                {sportLeagues.map((sportLeague) => {
                                    const isActive =
                                        sportLeagueIds.value.includes(
                                            sportLeague.leagueId!
                                        );

                                    return (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={3}
                                            key={sportLeague.leagueId}
                                        >
                                            <Card
                                                sx={{
                                                    border: '4px solid',
                                                    p: '10px 20px',
                                                    borderColor: isActive
                                                        ? theme.palette.primary
                                                              .main
                                                        : 'transparent',
                                                    borderRadius: 100,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    selectSportLeague(
                                                        sportLeague.leagueId!
                                                    )
                                                }
                                            >
                                                <Box
                                                    display={'flex'}
                                                    alignItems={'center'}
                                                    gap={0.5}
                                                    justifyContent={'center'}
                                                >
                                                    <img
                                                        src={
                                                            sportLeague.logoUrl
                                                        }
                                                        height={24}
                                                    />
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        fontWeight={900}
                                                        color={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    >
                                                        {sportLeague.name}
                                                    </Typography>
                                                </Box>
                                            </Card>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </Box>
                        <Box sx={{ mb: 2 }}>
                            <Typography
                                sx={{ mb: 2 }}
                                variant={'subtitle2'}
                                fontWeight={900}
                            >
                                Specify League Details
                            </Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        label={'League name'}
                                        fullWidth
                                        value={name.value}
                                        onChange={(event) =>
                                            name.onChange(event.target.value)
                                        }
                                        onBlur={
                                            name.enableAutoValidationAndValidate
                                        }
                                        error={name.hasError}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="create-league--initial-balance">
                                            Initial Balance
                                        </InputLabel>
                                        <Select
                                            labelId="create-league--initial-balance"
                                            value={initialBalance.value}
                                            label="Initial Balance"
                                            onChange={(event) => {
                                                initialBalance.onChange(
                                                    +event.target.value
                                                );
                                            }}
                                        >
                                            {initialBalanceOptions.map(
                                                (
                                                    value: number,
                                                    index: number
                                                ) => (
                                                    <MenuItem
                                                        value={value}
                                                        key={index}
                                                    >
                                                        <Balance
                                                            amount={value}
                                                        />
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="create-league--max-players">
                                            Max Players
                                        </InputLabel>
                                        <Select
                                            error={!!maxPlayers.error}
                                            labelId="create-league--max-players"
                                            value={maxPlayers.value ?? ''}
                                            label="Max Players"
                                            onChange={(event) => {
                                                changeMaxPlayers(
                                                    event.target.value === null
                                                        ? null
                                                        : +event.target.value
                                                );
                                            }}
                                            onBlur={
                                                maxPlayers.enableAutoValidationAndValidate
                                            }
                                        >
                                            {maxPlayerOptions.map((option) => (
                                                <MenuItem
                                                    key={option}
                                                    value={option}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <InputLabel id="create-league--playoff-players">
                                            Playoff Players
                                        </InputLabel>
                                        <Select
                                            error={!!playoffPlayers.error}
                                            labelId="create-league--playoff-players"
                                            value={playoffPlayers.value ?? ''}
                                            label="Playoff Players"
                                            onChange={(event) => {
                                                playoffPlayers.onChange(
                                                    event.target.value === null
                                                        ? null
                                                        : +event.target.value
                                                );
                                            }}
                                            onBlur={
                                                playoffPlayers.enableAutoValidationAndValidate
                                            }
                                        >
                                            {playoffPlayerOptions.map(
                                                (option) => (
                                                    <MenuItem
                                                        key={option}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box mb={2}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <Typography
                                    sx={{ mb: 2 }}
                                    variant={'subtitle1'}
                                >
                                    Start Date & Time
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <DatePicker
                                            label={'Date'}
                                            onChange={handleDateChange(
                                                startDate
                                            )}
                                            value={startDate.value}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    helperText="Choose a start date within 3 weeks to 1 day before the official league begins."
                                                    fullWidth
                                                    error={startDate.hasError}
                                                    onBlur={
                                                        startDate.enableAutoValidationAndValidate
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TimePicker
                                            inputFormat={'hh A'}
                                            label={'Time'}
                                            views={['hours']}
                                            onChange={handleDateChange(
                                                startTime
                                            )}
                                            value={startTime.value}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    error={startTime.hasError}
                                                    onBlur={
                                                        startTime.enableAutoValidationAndValidate
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </LocalizationProvider>
                        </Box>
                        <Button
                            sx={{ mt: 1 }}
                            disabled={
                                formState.hasError ||
                                createLeagueLoadStatus === LoadStatus.Loading
                            }
                            size={'large'}
                            fullWidth
                            type={'submit'}
                            variant={'contained'}
                        >
                            Create League
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        );
    })
);

import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { FC } from 'react';
import { SubsectionProps } from '../subsection';
import { SubsectionRowMobile } from './subsection-row-mobile';

export const SubsectionMobile: FC<SubsectionProps> = ({ subsection }) => {
    return (
        <Box>
            <Box px={2}>
                <Typography variant={'h5'} fontWeight={900}>
                    {subsection.name}
                </Typography>
                <Grid container spacing={0.5}>
                    <Grid item xs={3} />
                    <Grid item xs={9} container spacing={0.5}>
                        {subsection.columnHeaders?.map(
                            (header, index, headers) => {
                                return (
                                    <Grid
                                        key={index}
                                        item
                                        xs={12 / headers.length}
                                        alignContent={'end'}
                                    >
                                        <Box
                                            display={'flex'}
                                            justifyContent={'center'}
                                        >
                                            <Typography
                                                variant={'caption'}
                                                color={'textSecondary'}
                                                align={'center'}
                                                sx={{
                                                    wordBreak: 'break-word',
                                                }}
                                            >
                                                {header}
                                            </Typography>
                                        </Box>
                                    </Grid>
                                );
                            }
                        )}
                    </Grid>
                </Grid>
            </Box>
            <Box
                sx={{
                    background: 'white',
                    p: 2,
                }}
            >
                {subsection.rows?.map((row, index) => {
                    return (
                        <Box mb={0.5} key={index}>
                            <SubsectionRowMobile row={row} />
                        </Box>
                    );
                })}
            </Box>
        </Box>
    );
};

import { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { CardActionArea, useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { formatAmericanOdd } from '../../utils/format-american-odd';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import {
    IBetSlipOdd,
    BetSlipStore,
} from '../../../bet-slip/stores/bet-slip-store';
import { ConnectedOddTypography } from './connected-odd-typography';
import { MatchOddModel } from '../../api/api';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';

interface ConnectedOddProps {
    odd: MatchOddModel;
    light?: boolean;
}

export const ConnectedOdd: FC<ConnectedOddProps> = observer(
    ({ odd, light }) => {
        const [{ activeOdds, conflicts, selectOdd }] =
            useDependencies(BetSlipStore);

        const { id, americanStyleOdd, param } = odd;

        const isActive = activeOdds.has(id);
        const isError = conflicts.has(id);
        const isMobile = useIsMobile();
        const theme = useTheme();

        const bgColor = isError
            ? theme.palette.error.main
            : isActive
              ? theme.palette.primary.main
              : isMobile
                ? '#F9F9FE'
                : 'transparent';

        const betSlipMatchInfo = useContext(BetSlipMatchContext);

        const betSlipOdd: IBetSlipOdd = {
            odd,
            matchInfo: betSlipMatchInfo,
        };

        return (
            <Card
                sx={{
                    height: '100%',
                    borderRadius: 0,
                    background: bgColor,
                    boxShadow: 'none',
                }}
                onClick={() => {
                    selectOdd(betSlipOdd);
                }}
            >
                <CardActionArea sx={{ height: '100%' }}>
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'wrap',
                            justifyContent: {
                                xs: 'center',
                                sm: 'space-between',
                            },
                            flexDirection: {
                                xs: 'column',
                                sm: 'row',
                            },
                            px: {
                                xs: 1,
                                sm: 2,
                            },
                            py: 0.5,
                        }}
                    >
                        {!!param && (
                            <ConnectedOddTypography light={light}>
                                {param}
                            </ConnectedOddTypography>
                        )}
                        <ConnectedOddTypography
                            light={light}
                            color={
                                isActive || isError
                                    ? 'white'
                                    : theme.palette.primary.main
                            }
                        >
                            {formatAmericanOdd(americanStyleOdd!)}
                        </ConnectedOddTypography>
                    </Box>
                </CardActionArea>
            </Card>
        );
    }
);

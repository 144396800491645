import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import Box from '@mui/material/Box';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { CurrentGamesStore } from '../../../stores/current-games-store';
import BlockIcon from '@mui/icons-material/Block';
import { CurrentGamesProps } from '../current-games';
import { LastElementSetter } from '../../../../common/utils/types';

interface CurrentGamesMobileProps extends CurrentGamesProps {
    setGameElement: LastElementSetter;
    onForfeit: (gameId: number) => void;
}

export const CurrentGamesMobile: FC<CurrentGamesMobileProps> = observer(
    ({ setGameElement, onForfeit, onGameClick }) => {
        const [{ games }] = useDependencies(CurrentGamesStore);
        const theme = useTheme();

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => (
                    <GameListItemMobile
                        key={game.id}
                        ref={
                            index === games.length - 1
                                ? setGameElement
                                : undefined
                        }
                        onClick={() => {
                            onGameClick(game.id);
                        }}
                        game={game}
                        hideInitialBalance
                        actions={
                            <Button
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onForfeit(game.id);
                                }}
                                startIcon={<BlockIcon />}
                                sx={{
                                    border: `1px solid ${theme.palette.error.main}`,
                                    color: theme.palette.error.main,
                                    boxShadow: 1,
                                }}
                            >
                                <Typography>Forfeit Game</Typography>
                            </Button>
                        }
                    />
                ))}
            </Box>
        );
    }
);

import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { TableColumnConfig } from '../../../common/utils/types';
import { GameModel } from '../../../common/api/api';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { formatDateWithName } from '../../../../utils';
import { Balance } from '../../../../components/smallComponents';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import { UpcomingGamesStore } from '../../stores/upcoming-games-store';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { FC, Fragment, useEffect } from 'react';
import { TableHead, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Box from '@mui/material/Box';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { UpcomingGamesMobile } from './mobile/upcoming-games-mobile';
import { noop } from '../../../common/utils/noop';
import { useConfirm } from 'material-ui-confirm';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { CLICKABLE_TABLE_ROW_SX } from '../../../common/utils/ui';
import { InvitePlayers } from '../../../invite-players/components/invite-players';
import ShareIcon from '@mui/icons-material/Share';
import { NotificationsStore } from '../../../common/stores/notifications-store';

const upcomingGamesColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: (game) => (
            <TypographyTableCell>#{game.id}</TypographyTableCell>
        ),
    },
    {
        title: 'Start Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.startDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.endDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: (game) => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Current Balance',
        getComponent: (game) => (
            <Balance
                textProps={{ fontWeight: 700 }}
                amount={game.currentBalance}
            />
        ),
    },
    {
        title: 'Leagues',
        getComponent: (game) => <SportLeagueIconList leagues={game.leagues} />,
    },
];

export interface UpcomingGamesProps {
    onGameClick: (gameId: number) => void;
}

export const UpcomingGames: FC<UpcomingGamesProps> = provide([
    UpcomingGamesStore,
])(
    observer(({ onGameClick }) => {
        const [
            {
                fetchGames,
                games,
                leaveGame,
                gamesLoadStatus,
                getShareLink,
                startInvitation,
                endInvitation,
                sendInvite,
                gameToShare,
            },
            { notify },
        ] = useDependencies(UpcomingGamesStore, NotificationsStore);

        const theme = useTheme();

        useEffect(() => {
            fetchGames();
        }, []);

        const isMobile = useIsMobile();
        const confirm = useConfirm();

        const handleShare = (game: GameModel) => {
            startInvitation(game);
        };

        const handleLeave = (id: number) => {
            confirm({
                description: 'Are you sure you want to leave this game?',
                title: 'Leave Game',
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error',
                    fullWidth: true,
                },
                confirmationText: 'Yes, leave',
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'inherit',
                    fullWidth: true,
                },
            })
                .then(() => {
                    leaveGame(id);
                })
                .catch(noop);
        };

        return (
            <Fragment>
                <AsyncList
                    length={games.length}
                    loadStatus={gamesLoadStatus}
                    onFetch={fetchGames}
                >
                    {(setLastElement) =>
                        isMobile ? (
                            <UpcomingGamesMobile
                                onLeave={handleLeave}
                                onShare={handleShare}
                                onGameClick={onGameClick}
                                setLastElement={setLastElement}
                            />
                        ) : (
                            <CustomTable>
                                <TableHead>
                                    <TableRow>
                                        {upcomingGamesColumns.map(
                                            (column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.title}
                                                </TableCell>
                                            )
                                        )}
                                        <TableCell></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {games.map((game, gameIndex) => (
                                        <TableRow
                                            key={game.id}
                                            ref={
                                                gameIndex === games.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                            sx={CLICKABLE_TABLE_ROW_SX}
                                            onClick={() => {
                                                onGameClick(game.id);
                                            }}
                                        >
                                            {upcomingGamesColumns.map(
                                                (column, columnIndex) => (
                                                    <TableCell
                                                        key={columnIndex}
                                                    >
                                                        {column.getComponent(
                                                            game
                                                        )}
                                                    </TableCell>
                                                )
                                            )}
                                            <TableCell align={'right'}>
                                                <Box
                                                    display={'flex'}
                                                    gap={2}
                                                    justifyContent={'flex-end'}
                                                >
                                                    <CustomIconButton
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleShare(game);
                                                        }}
                                                        color={
                                                            theme.palette
                                                                .primary.main
                                                        }
                                                    >
                                                        <ShareIcon />
                                                    </CustomIconButton>
                                                    <CustomIconButton
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleLeave(
                                                                game.id
                                                            );
                                                        }}
                                                        color={
                                                            theme.palette.error
                                                                .main
                                                        }
                                                    >
                                                        <LogoutIcon />
                                                    </CustomIconButton>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
                {!!gameToShare && (
                    <InvitePlayers
                        onInvite={sendInvite}
                        onClose={endInvitation}
                        getShareLink={getShareLink}
                    />
                )}
            </Fragment>
        );
    })
);

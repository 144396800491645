import type { SVGProps } from "react";

const ActiveLeague = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="3"
    height="24"
    viewBox="0 0 3 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="3" height="24" fill="#2B5FD9" />
  </svg>
);

export default ActiveLeague;

import { useIsMobile } from '../../common/hooks/use-is-mobile';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { FC, PropsWithChildren } from 'react';

interface AuthLayoutProps extends PropsWithChildren {
    imgSrc: string;
}

export const AuthLayout: FC<AuthLayoutProps> = ({ children, imgSrc }) => {
    const isMobile = useIsMobile();

    const content = (
        <Box
            height={'100%'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            px={{
                xs: 2,
                sm: 15,
            }}
            py={{
                xs: 4,
                sm: 8,
            }}
        >
            {children}
        </Box>
    );

    return isMobile ? (
        content
    ) : (
        <Grid container sx={{ height: '100%' }}>
            <Grid item xs={6.5}>
                <Box
                    sx={{
                        background: `url(${imgSrc})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        height: '100%',
                    }}
                ></Box>
            </Grid>
            <Grid item xs={5.5}>
                {content}
            </Grid>
        </Grid>
    );
};

import { FC, Fragment, useState } from 'react';
import {
    Box,
    Button,
    Collapse,
    Divider,
    Stack,
    SvgIcon,
    Typography,
} from '@mui/material';
import { BetSlipOdd } from './bet-slip-odd';
import { TotalWinSection } from '../../../components/BetSlip/TotalSection';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { BetSlipMatchInfo, BetSlipStore } from '../stores/bet-slip-store';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import { observer } from 'mobx-react-lite';
import { Balance } from '../../../components/smallComponents';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatAmericanOdd } from '../../common/utils/format-american-odd';
import { AuthStore } from '../../common/stores/auth-store';
import { getAppBarHeight } from '../../common/utils/ui';

export interface BetSlipProps {
    isReview?: boolean;
    onMatchClick: (matchId: BetSlipMatchInfo['id']) => void;
}

export const BetSlip: FC<BetSlipProps> = observer(
    ({ isReview, onMatchClick }) => {
        const [isOpen, setIsOpen] = useState(false);
        const [
            {
                activeOdds,
                placeBet,
                calculatedOdds,
                balance,
                clearBetSlip,
                setTotalStake,
                amountToWin,
                isPlaceBetButtonDisabled,
            },
            { hasSubscriptionRemainingDays },
        ] = useDependencies(BetSlipStore, AuthStore);

        const isMobile = useIsMobile();

        const isPlaceBetButtonVisible = !isReview && !!activeOdds.size;

        const handleMatchClick = (matchId: number) => {
            setIsOpen(false);
            onMatchClick(matchId);
        };

        const content = (
            <Fragment>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    p={'20px'}
                    sx={{
                        height: isMobile
                            ? `calc(100dvh - ${80 + (isPlaceBetButtonVisible ? 96 : 0)}px)`
                            : undefined,
                        maxHeight: isMobile
                            ? undefined
                            : `calc(100vh - ${178 + getAppBarHeight(hasSubscriptionRemainingDays) + (isPlaceBetButtonVisible ? 96 : 0)}px)`,
                    }}
                >
                    {activeOdds.size > 0 ? (
                        <>
                            <Box
                                mb={1}
                                sx={{
                                    overflowY: 'auto',
                                }}
                            >
                                {Array.from(activeOdds.values()).map(
                                    (betSlipOdd) => {
                                        return (
                                            <Fragment key={betSlipOdd.odd.id}>
                                                <BetSlipOdd
                                                    betSlipOdd={betSlipOdd}
                                                    onMatchClick={
                                                        handleMatchClick
                                                    }
                                                />
                                                <Divider />
                                            </Fragment>
                                        );
                                    }
                                )}
                            </Box>
                            <Box display={'flex'} justifyContent={'end'}>
                                <Button
                                    size={'large'}
                                    sx={{
                                        color: '#707074',
                                    }}
                                    startIcon={<DeleteForeverIcon />}
                                    onClick={clearBetSlip}
                                >
                                    <Typography
                                        color={'#707074'}
                                        variant="body2"
                                    >
                                        Clear All
                                    </Typography>
                                </Button>
                            </Box>
                            <Divider
                                sx={{
                                    mb: 2,
                                    mt: 1,
                                }}
                            />
                            {calculatedOdds ? (
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    gap={2}
                                >
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                        gap={2}
                                    >
                                        <Typography
                                            variant="subtitle1"
                                            fontWeight={700}
                                        >
                                            Total Stake
                                        </Typography>
                                        <Box
                                            display={'flex'}
                                            gap={1}
                                            alignItems={'center'}
                                        >
                                            <Typography variant="body1">
                                                {activeOdds.size}x
                                            </Typography>
                                            <TotalWinSection
                                                onStakeChange={(value) =>
                                                    setTotalStake(+value)
                                                }
                                            />
                                        </Box>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography variant={'body1'}>
                                            Total Odds:
                                        </Typography>
                                        <Typography
                                            fontWeight={700}
                                            color={'primary'}
                                        >
                                            {formatAmericanOdd(
                                                calculatedOdds.totalAmericanOdd
                                            )}
                                        </Typography>
                                    </Box>
                                    <Box
                                        display={'flex'}
                                        justifyContent={'space-between'}
                                    >
                                        <Typography variant={'body1'}>
                                            Total Payout:
                                        </Typography>
                                        <Balance
                                            textProps={{ fontWeight: 700 }}
                                            amount={amountToWin}
                                        />
                                    </Box>
                                </Box>
                            ) : null}
                        </>
                    ) : (
                        <Box
                            sx={{
                                height: '190px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant={'h6'}
                                sx={{
                                    fontWeight: 400,
                                    color: 'textSecondary',
                                }}
                            >
                                Your Bet Slip is Empty
                            </Typography>
                        </Box>
                    )}
                </Box>
                {isPlaceBetButtonVisible && (
                    <Box p={'20px'} sx={{ background: 'white' }}>
                        <Button
                            disabled={isPlaceBetButtonDisabled}
                            fullWidth
                            sx={{
                                background: '#5BE734',
                                color: 'black',
                                py: 2,
                                opacity: isPlaceBetButtonDisabled
                                    ? 0.5
                                    : undefined,
                            }}
                            onClick={placeBet}
                        >
                            <Typography fontWeight={700}>
                                Place a Bet
                            </Typography>
                        </Button>
                    </Box>
                )}
            </Fragment>
        );

        return (
            <Box
                sx={{
                    background: '#F9F9F9',
                    height: 'max-content',
                    maxHeight: '100%',
                    width: '100%',
                }}
                id={isMobile ? 'mobileBetSlip' : undefined}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        background: 'white',
                        p: 2,
                        height: '80px',
                    }}
                >
                    <Stack direction={'row'} gap={2}>
                        <Box
                            sx={{
                                background: '#2B5FD9',
                                width: '30px',
                                height: '30px',
                                borderRadius: '30px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography color="#FFFFFF" variant="h5">
                                {activeOdds.size}
                            </Typography>
                        </Box>
                        <Typography variant="h6" fontWeight={700}>
                            Bet Slip
                        </Typography>
                    </Stack>
                    {!isReview ? (
                        <Stack direction={'row'} gap={1}>
                            <Typography
                                variant="subtitle1"
                                sx={{
                                    fontWeight: '600',
                                }}
                                component="div"
                            >
                                Balance:
                            </Typography>
                            <Balance
                                hasOdometer
                                amount={balance}
                                textProps={{ fontWeight: 700 }}
                            />
                        </Stack>
                    ) : null}
                    {isMobile ? (
                        <SvgIcon
                            sx={{
                                width: '32px',
                                height: '32px',
                                cursor: 'pointer',
                            }}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {isOpen ? (
                                <KeyboardArrowDown />
                            ) : (
                                <KeyboardArrowUp />
                            )}
                        </SvgIcon>
                    ) : null}
                </Box>
                {isMobile ? (
                    <Collapse in={isOpen}>{content}</Collapse>
                ) : (
                    content
                )}
            </Box>
        );
    }
);

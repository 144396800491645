import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import { AuthStore } from '../../stores/auth-store';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import { Link as RouterLink } from 'react-router-dom';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { useEffect } from 'react';

export const LimitationModal = observer(() => {
    const [{ subscribe, hideLimitations, isExpired }] =
        useDependencies(AuthStore);

    useEffect(
        () => () => {
            hideLimitations();
        },
        []
    );

    const handleClose = () => {
        if (isExpired) {
            return;
        }

        hideLimitations();
    };

    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems={'center'}
                >
                    <Typography
                        variant={'h5'}
                        fontWeight={700}
                        sx={{
                            background:
                                'linear-gradient(180deg, #09A129 0%, #2B5FD9 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                        }}
                    >
                        Subscription Required!
                    </Typography>
                    {!isExpired && (
                        <IconButton onClick={handleClose}>
                            <CloseIcon sx={{ width: 32, height: 32 }} />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'} mb={4}>
                    Subscribe now to unlock premium features and uninterrupted
                    access to all the latest betting opportunities. If you've
                    experienced a payment issue, please try again or{' '}
                    <Link component={RouterLink} to={'/contacts'}>
                        contact our support team
                    </Link>{' '}
                    for assistance.
                </Typography>
                <Button variant={'contained'} fullWidth onClick={subscribe}>
                    Unlock Access
                </Button>
            </DialogContent>
        </Dialog>
    );
});

import { FC } from 'react';
import { LeagueTitleModel } from '../../api/api';
import Box from '@mui/material/Box';

export const SportLeagueIconList: FC<{ leagues?: LeagueTitleModel[] }> = ({
    leagues = [],
}) => {
    return (
        <Box display={'flex'} gap={0.5} flexWrap={'wrap'}>
            {leagues.map((league) => {
                return (
                    <Box
                        display={'flex'}
                        justifyContent={'center'}
                        height={24}
                        minWidth={24}
                        key={league.id}
                    >
                        <img
                            height={'100%'}
                            alt={league.name}
                            src={league.logoUrl!}
                        />
                    </Box>
                );
            })}
        </Box>
    );
};

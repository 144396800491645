import { FC, useState } from 'react';
import { BetGetModel, BetOddGetModel, BetStatusEnum } from '../../api/api';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
    CardActionArea,
    Chip,
    ChipProps,
    Collapse,
    SvgIcon,
} from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Balance } from '../../../../components/smallComponents';
import moment from 'moment';
import { formatAmericanOdd } from '../../utils/format-american-odd';

const mapBetStatusToColor: Record<BetStatusEnum, ChipProps['color']> = {
    [BetStatusEnum.Lost]: 'error',
    [BetStatusEnum.Won]: 'success',
    [BetStatusEnum.Pushed]: 'warning',
    [BetStatusEnum.Pending]: 'primary',
};

const HistoricalOdd: FC<{
    betOdd: BetOddGetModel;
}> = ({ betOdd: { odd, match } }) => {
    return (
        <Box sx={{ p: 2, borderTop: '1px solid #DEDEDE' }}>
            <Box display={'flex'} justifyContent={'space-between'} mb={0.5}>
                <Box display={'flex'} gap={2}>
                    {!!odd.betSlipName && (
                        <Typography variant={'subtitle2'} fontWeight={900}>
                            {odd.betSlipName}
                        </Typography>
                    )}
                    <Typography variant={'subtitle2'} fontWeight={900}>
                        {odd.param}
                    </Typography>
                </Box>
                <Typography
                    color={'primary'}
                    variant={'subtitle2'}
                    fontWeight={900}
                >
                    {formatAmericanOdd(odd.americanStyleOdd!)}
                </Typography>
            </Box>
            <Typography variant={'body2'} color={'#707074'} mb={0.5}>
                {odd.betType.name}
            </Typography>
            <Typography>
                {match.teams![0].name} @ {match.teams![1].name}
            </Typography>
        </Box>
    );
};

interface BetOdds extends CardProps {
    bet: BetGetModel;
}

export const Bet: FC<BetOdds> = ({ bet, ...props }) => {
    const [isOpen, setIsOpen] = useState(false);

    const odds = bet.odds ?? [];

    const betName =
        odds.length > 1 ? `${odds.length} Pick Parlay` : 'Single Bet';

    return (
        <Card {...props}>
            <CardActionArea
                sx={{
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                }}
                onClick={() => setIsOpen((prev) => !prev)}
            >
                <Box p={2}>
                    <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        mb={1}
                    >
                        <Typography variant={'subtitle2'} fontWeight={900}>
                            {betName}
                        </Typography>
                        <Box display={'flex'} gap={1} alignItems={'center'}>
                            <Typography
                                color={'primary'}
                                variant={'subtitle2'}
                                fontWeight={900}
                            >
                                {formatAmericanOdd(bet.americanStyleOdd)}
                            </Typography>
                            <Chip
                                size={'small'}
                                color={mapBetStatusToColor[bet.status]}
                                label={bet.status}
                            />
                            <SvgIcon fontSize={'medium'}>
                                {isOpen ? <ArrowDropUp /> : <ArrowDropDown />}
                            </SvgIcon>
                        </Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'space-between'}>
                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                            <Typography variant={'body2'}>Wager: </Typography>
                            <Balance
                                textProps={{
                                    variant: 'subtitle1',
                                }}
                                amount={bet.amount}
                            />
                        </Box>
                        <Box display={'flex'} gap={0.5} alignItems={'center'}>
                            <Typography variant={'body2'}>To Win: </Typography>
                            <Balance
                                textProps={{
                                    variant: 'subtitle1',
                                }}
                                amount={bet.toWin}
                            />
                        </Box>
                    </Box>
                </Box>
            </CardActionArea>
            <Collapse in={isOpen}>
                <Box sx={{ bgcolor: '#F9F9FE' }}>
                    {odds.map((betOdd, index) => {
                        return <HistoricalOdd betOdd={betOdd} key={index} />;
                    })}
                    <Box
                        display={'flex'}
                        justifyContent={'end'}
                        p={2}
                        sx={{ borderTop: '1px solid #DEDEDE' }}
                    >
                        <Typography variant={'caption'} color={'#707074'}>
                            Placed: {moment(bet.placedDate).format('lll')}
                        </Typography>
                    </Box>
                </Box>
            </Collapse>
        </Card>
    );
};

import { Fragment, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../../common/utils/ioc/provide';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { MobileBetSlipContainer } from '../../../../common/components/mobile-bet-slip-container/mobile-bet-slip-container';
import { BetSlipStore } from '../../../../bet-slip/stores/bet-slip-store';
import { BetSlip } from '../../../../bet-slip/components/bet-slip';
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { LoadStatus } from '../../../../common/enums/load-status';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { LeagueContentStore } from '../../../stores/league-content-store';
import { CurrentGameMatchOverviews } from './current-game-match-overviews';
import { CurrentGameMatchOdds } from './current-game-match-odds';

const CurrentLeagueGameMain = provide([CurrentLeagueGameStore, BetSlipStore])(
    observer(() => {
        const [
            {
                sportLeagueId,
                sportLeaguesLoadStatus,
                init,
                selectSportLeague,
                selectMatch,
                sports,
            },
            { initBetSlip },
            { currentGameId },
        ] = useDependencies(
            CurrentLeagueGameStore,
            BetSlipStore,
            LeagueContentStore
        );

        const { sportLeagueId: sportLeagueIdParam, matchId: matchIdParam } =
            useParams();

        useEffect(() => {
            if (currentGameId) {
                initBetSlip(currentGameId);
                init(currentGameId);
            }
        }, [currentGameId]);

        useEffect(() => {
            // TODO: add number validation
            if (currentGameId && sportLeagueIdParam) {
                selectSportLeague(+sportLeagueIdParam);
            }
        }, [currentGameId, sportLeagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam]);

        const navigate = useNavigate();

        const handleClickSportLeague = (
            selectedId: number,
            replace?: boolean
        ) => {
            if (selectedId !== sportLeagueId) {
                navigate(`../${selectedId}`, { replace });
            }
        };

        useEffect(() => {
            if (
                sportLeaguesLoadStatus === LoadStatus.Ok &&
                sportLeagueIdParam === undefined
            ) {
                const firstValidSport = sports.find(
                    (sport) => !!sport.leagues?.length
                );

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleClickSportLeague(firstLeague.id, true);
                }
            }
        }, [sportLeaguesLoadStatus]);

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <Fragment>
                {matchIdParam ? (
                    <CurrentGameMatchOdds />
                ) : (
                    <CurrentGameMatchOverviews
                        onClickSportLeague={handleClickSportLeague}
                    />
                )}
                <MobileBetSlipContainer>
                    <BetSlip onMatchClick={handleBetSlipMatchClick} />
                </MobileBetSlipContainer>
            </Fragment>
        );
    })
);

export const CurrentLeagueGame = () => {
    return (
        <Routes>
            <Route
                path={':sportLeagueId?/:matchId?'}
                element={<CurrentLeagueGameMain />}
            />
            <Route path={'*'} element={<Navigate to={''} replace />} />
        </Routes>
    );
};

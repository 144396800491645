import { TableColumnConfig } from '../../common/utils/types';
import { GameLeaguePublicModel } from '../../common/api/api';
import { TypographyTableCell } from '../../common/components/table-cells/typography-table-cell';
import { Chip } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { SportLeagueIconList } from '../../common/components/sport-league-icon-list/sport-league-icon-list';

export const leaguesListColumns: TableColumnConfig<GameLeaguePublicModel>[] = [
    {
        title: 'League Name',
        getComponent: (league) => (
            <TypographyTableCell>{league.name}</TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: (league) => (
            <TypographyTableCell>
                {league.currentPlayerCount}/{league.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Created By',
        getComponent: (league) => (
            <TypographyTableCell>
                <Chip
                    sx={{ border: 'none' }}
                    variant={'outlined'}
                    avatar={<Avatar src={league.createdByPlayer.avatarUrl} />}
                    label={league.createdByPlayer.username}
                />
            </TypographyTableCell>
        ),
    },
    {
        title: 'Leagues',
        getComponent: (league) => (
            <SportLeagueIconList leagues={league.leagues} />
        ),
    },
];

import { FC } from 'react';
import { ILeaguesProps } from '../leagues';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export const LeaguesMobile: FC<ILeaguesProps> = ({
    sports,
    selectedLeagueId,
    onChangeLeagueId,
}) => {
    const theme = useTheme();

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                gap: 1,
                overflowX: 'auto',
                pb: 1,
            }}
        >
            {sports.map((sport) => {
                return sport.leagues?.map((league) => {
                    const isActive = selectedLeagueId === league.id;

                    return (
                        <Card
                            key={league.id}
                            sx={{
                                height: '56px',
                                width: '56px',
                                minWidth: '56px',
                                borderRadius: '10px',
                                border: `2px solid ${isActive ? theme.palette.primary.main : 'white'}`,
                                boxShadow: 'none',
                                cursor: 'pointer',
                                boxSizing: 'border-box',
                            }}
                            onClick={() => {
                                onChangeLeagueId(league.id);
                            }}
                        >
                            <Box
                                sx={{
                                    height: '100%',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 0.5,
                                }}
                            >
                                <img
                                    src={league.logoUrl!}
                                    alt={`${league.name} - logo`}
                                    width={'24px'}
                                    height={'24px'}
                                />
                                <Typography
                                    fontWeight={600}
                                    variant={'body2'}
                                    color={theme.palette.primary.main}
                                >
                                    {league.name}
                                </Typography>
                            </Box>
                        </Card>
                    );
                });
            })}
        </Box>
    );
};

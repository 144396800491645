import { useLayoutEffect, useRef } from 'react';
import axios, { axiosV2 } from '../../../utils/axios';
import { useDependencies } from '../utils/ioc/use-dependencies';
import { AuthStore } from '../stores/auth-store';
import { NotificationsStore } from '../stores/notifications-store';

export const useAuthInterceptors = () => {
    const [
        { unAuthorize, handlePaymentRequired, handleUnauthorized },
        { notify },
    ] = useDependencies(AuthStore, NotificationsStore);

    const retryRequestRef = useRef<Promise<any> | undefined>();

    useLayoutEffect(() => {
        [axios, axiosV2].forEach((instance) => {
            instance.interceptors.request.use(
                (config) => {
                    if (!config.headers) {
                        config.headers = {};
                    }

                    config.headers['Authorization'] =
                        `Bearer ${localStorage.getItem('token')}`;

                    return config;
                },
                (error) => Promise.reject(error)
            );

            instance.interceptors.response.use(
                (response) => response,
                async (error) => {
                    if (
                        error.response.status === 401 &&
                        !error.request.responseURL.includes('/refreshToken')
                    ) {
                        try {
                            if (!retryRequestRef.current) {
                                retryRequestRef.current = handleUnauthorized();
                            }

                            await retryRequestRef.current;
                            retryRequestRef.current = undefined;

                            return axios(error.config);
                        } catch {
                            retryRequestRef.current = undefined;
                            const isMe =
                                error.request.responseURL.endsWith('/me');
                            if (isMe) return Promise.reject(error);

                            unAuthorize();

                            notify({
                                severity: 'error',
                                children: 'You are not authorized',
                            });
                        }
                    } else if (error.response.status === 402) {
                        handlePaymentRequired();
                    } else {
                        notify({
                            severity: 'error',
                            children:
                                error.response.data.ErrorMessage ??
                                'Something went wrong',
                        });
                    }

                    return Promise.reject(error);
                }
            );
        });
    }, []);
};

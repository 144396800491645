import { FC } from 'react';
import Typography from '@mui/material/Typography';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { TypographyProps } from '@mui/material';

export const StandingsTypographyCell: FC<TypographyProps> = (props) => {
    const isMobile = useIsMobile();
    const cellTypographyVariant = isMobile ? 'body1' : 'h6';

    return (
        <Typography variant={cellTypographyVariant} {...props}>
            {props.children}
        </Typography>
    );
};

import { PlayerInfoModel } from '../../common/api/api';
import { makeAutoObservable, runInAction } from 'mobx';
import { FieldState } from 'formstate';
import { injectable } from 'inversify';
import { ParticipantsGetter } from '../utils/types';

@injectable()
export class ParticipantsStore {
    participants: PlayerInfoModel[] = [];
    searchTerm = new FieldState('');

    constructor() {
        makeAutoObservable(this);
    }

    init = async (getParticipants: ParticipantsGetter) => {
        try {
            const participants = await getParticipants();

            runInAction(() => {
                this.participants = participants;
            });
        } catch {
            // skip
        }
    };

    get filteredParticipants() {
        return this.participants.filter(({ username }) =>
            username!
                .toLowerCase()
                .includes(this.searchTerm.value.toLowerCase())
        );
    }
}

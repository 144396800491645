import { FC } from 'react';
import { MatchWithOddsModel } from '../../api/api';
import { formatDateWithName, setLocalTime } from '../../../../utils';
import { Box, Grid, Typography } from '@mui/material';
import { MatchOverviewRow, MatchOverviewRowProps } from './match-overview-row';
import { DATE_COL_SIZE } from './ui';
import { BetSlipMatchInfo } from '../../../bet-slip/stores/bet-slip-store';
import { BetSlipMatchContext } from '../../contexts/bet-slip-match-context';

export interface MatchOverviewProps {
    matchOverview: MatchWithOddsModel;
    isFirstMatchOfDay?: boolean;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];
}

export const MatchOverview: FC<MatchOverviewProps> = ({
    matchOverview,
    isFirstMatchOfDay,
    onMatchClick,
}) => {
    const betSlipMatchInfo: BetSlipMatchInfo = {
        id: matchOverview.match.id,
        teams: matchOverview.match.teams!.map(({ name }) => name!),
    };

    const day = formatDateWithName(matchOverview.match.startDate, true);

    return (
        <BetSlipMatchContext.Provider value={betSlipMatchInfo}>
            <Grid container spacing={2}>
                <Grid item xs={DATE_COL_SIZE}>
                    <Typography
                        fontWeight={700}
                        textAlign={'right'}
                        sx={{ wordBreak: 'break-word' }}
                    >
                        {isFirstMatchOfDay && day}
                    </Typography>
                    <Typography textAlign={'right'} fontSize={'14px'}>
                        {setLocalTime(matchOverview.match.startDate)}
                    </Typography>
                </Grid>
                <Grid item xs>
                    {matchOverview.odds.rows?.length === 1 ? (
                        <MatchOverviewRow
                            onMatchClick={onMatchClick}
                            matchOverview={matchOverview}
                            currentOdds={matchOverview.odds.rows![0].odds!}
                        />
                    ) : (
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            gap={0.2}
                        >
                            {matchOverview.match.teams?.map(
                                (team, index, teams) => {
                                    return (
                                        <MatchOverviewRow
                                            onMatchClick={onMatchClick}
                                            key={team.id}
                                            matchOverview={matchOverview}
                                            currentTeam={team}
                                            currentOdds={
                                                matchOverview.odds.rows![index]
                                                    .odds!
                                            }
                                        />
                                    );
                                }
                            )}
                        </Box>
                    )}
                </Grid>
            </Grid>
        </BetSlipMatchContext.Provider>
    );
};

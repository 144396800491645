import { injectable } from 'inversify';
import { makeAutoObservable, observable } from 'mobx';

@injectable()
export class AppStore {
    isMobileBetSlipVisible = false;

    constructor() {
        makeAutoObservable(this);
    }

    setIsMobileBetSlipVisible = (isVisible: boolean) => {
        this.isMobileBetSlipVisible = isVisible;
    };
}

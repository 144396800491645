import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { SignupStore } from '../../stores/signup-store';
import { AuthLayout } from '../auth-layout';
import SignupPageImage from '../../../../assets/images/signup-page-image.png';
import Box from '@mui/material/Box';
import { noop } from '../../../common/utils/noop';
import { Link as RouterLink } from 'react-router-dom';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { Checkbox, FormControlLabel, InputAdornment } from '@mui/material';
import { useEffect } from 'react';
import { getInputProps } from '../../utils/get-input-props';
import { LoadStatus } from '../../../common/enums/load-status';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { UserAvatar, UserAvatarType } from './user-avatar';

export const Signup = provide([SignupStore])(
    observer(() => {
        const [
            { signup, init, signupLoadStatus, formState, availableAvatarFiles },
        ] = useDependencies(SignupStore);

        const {
            username,
            password,
            confirmPassword,
            avatarFile,
            email,
            fullName,
            phoneNumber,
            agreeTerms,
        } = formState.$;

        useEffect(() => {
            init();
        }, []);

        const handleSubmit = () => {
            signup()
                // .then(() => {
                //     navigate('/onboarding');
                // })
                .catch(noop);
        };

        return (
            <AuthLayout imgSrc={SignupPageImage}>
                <Box
                    component={'form'}
                    display="flex"
                    flexDirection="column"
                    width={'100%'}
                    onSubmit={(event) => {
                        event.preventDefault();
                        handleSubmit();
                    }}
                >
                    <Typography
                        variant={'h4'}
                        color={'primary'}
                        fontWeight={900}
                        textAlign={'center'}
                        mb={4}
                    >
                        Sign Up
                    </Typography>
                    {/*<Typography mb={1}>*/}
                    {/*    Welcome!*/}
                    {/*    <br />*/}
                    {/*    Let’s get started with your{' '}*/}
                    {/*    <Typography component={'span'} fontWeight={900}>*/}
                    {/*        30 days free trial!*/}
                    {/*    </Typography>*/}
                    {/*</Typography>*/}
                    {/*<Typography*/}
                    {/*    variant={'body2'}*/}
                    {/*    color={'textSecondary'}*/}
                    {/*    mb={3}*/}
                    {/*>*/}
                    {/*    No credit card details required.*/}
                    {/*</Typography>*/}
                    <Box
                        mb={3}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={2}
                        alignItems={'center'}
                    >
                        <UserAvatar
                            active
                            src={avatarFile.value?.url}
                            type={UserAvatarType.Selected}
                        />
                        <Box
                            display={'flex'}
                            gap={1.5}
                            flexWrap={'wrap'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            {availableAvatarFiles.map((file) => (
                                <UserAvatar
                                    active={avatarFile.value?.id === file.id}
                                    key={file.id}
                                    src={file.url ?? ''}
                                    type={UserAvatarType.Available}
                                    onClick={() => {
                                        avatarFile.onChange(file);
                                    }}
                                />
                            ))}
                        </Box>
                    </Box>
                    <TextField label="Full Name" {...getInputProps(fullName)} />
                    <TextField
                        label="Email Address"
                        autoComplete="email"
                        {...getInputProps(email)}
                    />
                    <TextField
                        label="Phone Number"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    +
                                </InputAdornment>
                            ),
                        }}
                        {...getInputProps(phoneNumber)}
                    />
                    <TextField label="Username" {...getInputProps(username)} />
                    <TextField
                        label="Password"
                        type={'password'}
                        {...getInputProps(password)}
                    />
                    <TextField
                        label="Confirm Password"
                        type={'password'}
                        {...getInputProps(confirmPassword)}
                        sx={{ mb: 2 }}
                    />
                    <FormControlLabel
                        sx={{ mb: 2 }}
                        control={
                            <Checkbox
                                sx={{
                                    color: agreeTerms.hasError
                                        ? 'red'
                                        : undefined,
                                }}
                                checked={agreeTerms.value}
                                onChange={(_event, checked) => {
                                    agreeTerms.onChange(checked);
                                }}
                            />
                        }
                        label={
                            <Typography
                                variant={'caption'}
                                color={
                                    agreeTerms.hasError
                                        ? 'red'
                                        : 'textSecondary'
                                }
                            >
                                I agree to{' '}
                                <Link
                                    target={'_blank'}
                                    component={'a'}
                                    href={
                                        'https://balancebet.com/terms-conditions'
                                    }
                                    underline={'hover'}
                                >
                                    Terms and Conditions
                                </Link>{' '}
                                and{' '}
                                <Link
                                    target={'_blank'}
                                    component={'a'}
                                    href={
                                        'https://balancebet.com/privacy-policy'
                                    }
                                    underline={'hover'}
                                >
                                    Privacy Policy
                                </Link>
                                .
                            </Typography>
                        }
                    />
                    <Button
                        sx={{ mb: 4 }}
                        variant={'contained'}
                        type={'submit'}
                        disabled={
                            formState.hasError ||
                            signupLoadStatus === LoadStatus.Loading
                        }
                    >
                        Sign Up
                    </Button>
                    <Typography variant={'body1'} mb={3}>
                        Already have an account?{' '}
                        <Link
                            underline={'hover'}
                            component={RouterLink}
                            to={'/login'}
                        >
                            Log In
                        </Link>
                    </Typography>
                </Box>
            </AuthLayout>
        );
    })
);

import { FC } from 'react';
import { TeamModel } from '../../api/api';
import { Avatar, Box, Typography } from '@mui/material';
import { useIsMobile } from '../../hooks/use-is-mobile';

export const TeamInfo: FC<{ team: TeamModel }> = ({ team }) => {
    const isMobile = useIsMobile();

    return (
        <Box display={'flex'} gap={1} alignItems={'center'}>
            <Avatar
                sx={{
                    '& img': {
                        objectFit: 'unset',
                    },
                }}
                src={team.logoUrl}
            />
            <Typography variant={isMobile ? 'body2' : 'body1'}>
                {team.name}
            </Typography>
        </Box>
    );
};

import { observer } from 'mobx-react-lite';
import Grid from '@mui/material/Grid';
import { Player } from './player';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { MyFriendsStore } from '../stores/my-friends-store';
import { provide } from '../../common/utils/ioc/provide';
import { AsyncList } from '../../common/components/async-table-list/async-list';
import { PlayersContainer } from './players-container';

export const MyFriends = provide([MyFriendsStore])(
    observer(() => {
        const [{ fetchPlayers, players, loadStatus, removeFriend }] =
            useDependencies(MyFriendsStore);

        return (
            <PlayersContainer>
                <AsyncList
                    length={players.length}
                    loadStatus={loadStatus}
                    onFetch={fetchPlayers}
                    prefetch={true}
                >
                    {(setLastElement) => (
                        <Grid container spacing={1}>
                            {players.map((player, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={player.id}
                                    ref={
                                        index === players.length - 1
                                            ? setLastElement
                                            : undefined
                                    }
                                >
                                    <Player
                                        player={player}
                                        onRemove={removeFriend}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </AsyncList>
            </PlayersContainer>
        );
    })
);

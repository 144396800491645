import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Contact from '../Contact';
import Navigation from '../../components/Navigation';
import { ConfirmProvider } from 'material-ui-confirm';
import Forgot from '../Auth/Forgot';
import Reset from '../Auth/Reset';
import { SlatePage } from '../../modules/slate/components/slate-page';
import { asParentPath } from '../../modules/common/utils/as-parent-path';
import { LobbyPage } from '../../modules/lobby/components/lobby-page/lobby-page';
import { HistoryPage } from '../../modules/history/components/history-page/history-page';
import { observer } from 'mobx-react-lite';
import { provide } from '../../modules/common/utils/ioc/provide';
import { AuthStore } from '../../modules/common/stores/auth-store';
import { useDependencies } from '../../modules/common/utils/ioc/use-dependencies';
import { useEffect } from 'react';
import { MyGamesPage } from '../../modules/my-games/components/my-games-page';
import { useAuthInterceptors } from '../../modules/common/hooks/use-auth-interceptors';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LeaguesPage } from '../../modules/leagues/components/leagues-page';
import { LimitationModal } from '../../modules/common/components/limitation-modal/limitation-modal';
import { Login } from '../../modules/auth/components/login/login';
import { Signup } from '../../modules/auth/components/signup/signup';
import { AppStore } from '../../modules/common/stores/app-store';
import { ProfilePage } from '../../modules/profile/components/profile-page';
import { FriendsPage } from '../../modules/friends/components/friends-page';
import { CommsHubStore } from '../../modules/root/stores/comms-hub-store';
import { WithCommsHub } from '../../modules/root/components/with-comms-hub';
import { WithBettingHub } from '../../modules/root/components/with-betting-hub';
import { WithAnnouncements } from '../../modules/accouncements/components/with-announcements/with-announcements';

export const App = provide([AuthStore, AppStore, CommsHubStore])(
    observer(() => {
        const [
            {
                authorize,
                isAuthenticated,
                readyToRender,
                isExpired,
                isShowingLimitations,
            },
        ] = useDependencies(AuthStore);

        useAuthInterceptors();

        useEffect(() => {
            authorize();
        }, []);

        const location = useLocation();

        if (!readyToRender) {
            return (
                <Box
                    height={'100%'}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <CircularProgress />
                </Box>
            );
        }

        const returnURL = location.pathname + location.search + location.hash;

        const redirectionURLSearchParams = new URLSearchParams();
        redirectionURLSearchParams.set(
            'returnURL',
            encodeURIComponent(returnURL)
        );

        const content = (
            <Routes>
                <Route path="contacts" element={<Contact />} />
                <Route
                    path={'*'}
                    element={
                        isAuthenticated ? (
                            <WithCommsHub>
                                <WithAnnouncements>
                                    <Routes>
                                        <Route
                                            path="lobby"
                                            element={<LobbyPage />}
                                        />
                                        <Route
                                            path={asParentPath('my-games')}
                                            element={
                                                <WithBettingHub>
                                                    <MyGamesPage />
                                                </WithBettingHub>
                                            }
                                        />
                                        <Route
                                            path="history"
                                            element={<HistoryPage />}
                                        />
                                        <Route
                                            path={asParentPath('profile')}
                                            element={<ProfilePage />}
                                        />
                                        <Route
                                            path="friends"
                                            element={<FriendsPage />}
                                        />
                                        <Route
                                            path={asParentPath('slate')}
                                            element={
                                                <WithBettingHub>
                                                    <SlatePage />
                                                </WithBettingHub>
                                            }
                                        />
                                        <Route
                                            path={asParentPath('leagues')}
                                            element={
                                                <WithBettingHub>
                                                    <LeaguesPage />
                                                </WithBettingHub>
                                            }
                                        />
                                        {/*<Route*/}
                                        {/*    path={asParentPath('onboarding')}*/}
                                        {/*    element={<Onboarding />}*/}
                                        {/*/>*/}
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate
                                                    to={'lobby'}
                                                    replace
                                                />
                                            }
                                        />
                                    </Routes>
                                    {(isExpired || isShowingLimitations) && (
                                        <LimitationModal />
                                    )}
                                </WithAnnouncements>
                            </WithCommsHub>
                        ) : (
                            <Routes>
                                <Route path="login" element={<Login />} />
                                <Route path="signup" element={<Signup />} />
                                <Route
                                    path="forgot-password"
                                    element={<Forgot />}
                                />
                                <Route
                                    path="password-reset"
                                    element={<Reset />}
                                />
                                <Route
                                    path="*"
                                    element={
                                        <Navigate
                                            to={{
                                                pathname: 'login',
                                                search: redirectionURLSearchParams.toString(),
                                            }}
                                            replace
                                        />
                                    }
                                />
                            </Routes>
                        )
                    }
                />
            </Routes>
        );

        // TODO: improve
        return (
            <ConfirmProvider>
                <Navigation
                    content={content}
                    hideAppBar={
                        !isAuthenticated ||
                        location.pathname.includes('onboarding')
                    }
                />
            </ConfirmProvider>
        );
    })
);

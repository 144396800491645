import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { HistoryStore } from '../../stores/history-store';
import { Fragment, useEffect } from 'react';
import HistorySectionImage from '../../../../assets/images/history-section-image.jpeg';
import Typography from '@mui/material/Typography';
import { HistoryGames } from '../history-games/history-games';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { SectionWithBackground } from '../../../common/components/section-with-background/section-with-background';
import { Leaderboard } from '../../../leaderboard/components/leaderboard';
import { AsyncList } from '../../../common/components/async-table-list/async-list';

export const HistoryPage = provide([HistoryStore])(
    observer(() => {
        const [
            {
                games,
                gamesLoadStatus,
                fetchGames,
                winStats,
                initHistory,
                gameIdForLeaderboard,
                hideLeaderboard,
            },
        ] = useDependencies(HistoryStore);

        useEffect(() => {
            initHistory();
        }, []);

        return (
            <Fragment>
                <SectionWithBackground
                    headline={
                        winStats
                            ? `${winStats.headToHeadWon}:${winStats.headToHeadPlayed}`
                            : ''
                    }
                    description={'HEAD TO HEAD WINS'}
                    imgSrc={HistorySectionImage}
                >
                    <Typography mb={3} variant={'h4'} fontWeight={900}>
                        History
                    </Typography>
                    <AsyncList
                        length={games.length}
                        loadStatus={gamesLoadStatus}
                        onFetch={fetchGames}
                    >
                        {(setLastElement) => (
                            <HistoryGames setLastElement={setLastElement} />
                        )}
                    </AsyncList>
                </SectionWithBackground>
                {gameIdForLeaderboard !== undefined && (
                    <Leaderboard
                        gameId={gameIdForLeaderboard}
                        onClose={hideLeaderboard}
                    />
                )}
            </Fragment>
        );
    })
);

import { Slice, createSlice } from "@reduxjs/toolkit";
import { authApi } from "./authApi";
import storage from "redux-persist/es/storage";
import { persistReducer } from "redux-persist";

export interface IAuthState {
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean;
}

const initialState: IAuthState = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
};

export const authSlice: Slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setCredentials: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken.tokenString;
      state.isAuthenticated = true;
    },
    logout: (state) => {
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, action) => {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken.tokenString;
        state.isAuthenticated = true;
      }
    );
  },
});

authSlice.reducer = persistReducer(
  {
    key: "auth",
    storage,
  },
  authSlice.reducer
);

import * as React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Box, Button } from "@mui/material";

import {
  filterRequiredMessages,
  validateEmail,
  validatePhoneNumber,
} from "../../utils";
import { REQUIRED_FIELD } from "../../utils/constants";

type TProps = {
  onSubmit: () => void;
  userData: { [key: string]: any };
  handleChange: (e: React.BaseSyntheticEvent) => void;
};

export const ContactContent = (props: TProps) => {
  const { userData, handleChange, onSubmit } = props;
  const [errors, setErrors] = React.useState<{ [key: string]: any }>({});

  const verify = (field: string) => {
    if (!userData[field]) {
      return REQUIRED_FIELD;
    }

    switch (field) {
      case "email":
        return validateEmail(userData.email);
      case "phoneNumber":
        return validatePhoneNumber(userData.phoneNumber);
    }
  };

  const handleVerify = (field?: string) => {
    if (field) {
      setErrors((prev) => ({
        ...prev,
        [field]: verify(field),
      }));
      return;
    }
  };

  const handleFocusOut = (e: React.BaseSyntheticEvent) =>
    handleVerify(e?.target?.name);

  const hasError = Object.keys(errors).reduce(
    (acc, key) => acc || !!errors[key],
    false
  );
  const hasUserData = Object.keys(userData).reduce(
    (acc, key) => acc + errors[key],
    ""
  );
  const visibleErrors = React.useMemo(
    () => filterRequiredMessages(errors),
    [errors]
  );

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            gutterBottom
            textAlign="center"
            fontWeight="bold"
          >
            Contact us
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Full Name"
            fullWidth
            name="fullName"
            autoComplete="fullName"
            variant="outlined"
            value={userData.fullName}
            onBlur={handleFocusOut}
            onChange={handleChange}
            error={!!visibleErrors.fullName}
            helperText={visibleErrors.fullName}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            label="Email"
            name="email"
            fullWidth
            autoComplete="email"
            variant="outlined"
            value={userData.email}
            onBlur={handleFocusOut}
            onChange={handleChange}
            error={!!visibleErrors.email}
            helperText={visibleErrors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            multiline
            name="message"
            placeholder="Message"
            style={{ width: "100%", marginBottom: 16 }}
            value={userData.message}
            onBlur={handleFocusOut}
            onChange={handleChange}
            error={!!visibleErrors.message}
            helperText={visibleErrors.message}
            minRows={4}
          />
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="flex-end" sx={{ width: "100%" }}>
        <Button
          variant="contained"
          onClick={onSubmit}
          sx={{ width: "100%", py: 2, fontWeight: "bold" }}
          disabled={hasError || !hasUserData}
        >
          Send
        </Button>
      </Box>
    </>
  );
};

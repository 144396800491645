import { injectable } from 'inversify';
import { LoadStatus } from '../../common/enums/load-status';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { GameLeaguePublicModel } from '../../common/api/api';

@injectable()
export class LeagueContentStore {
    league?: GameLeaguePublicModel;
    currentGameId?: number;
    leagueLoadStatus = LoadStatus.None;

    constructor() {
        makeAutoObservable(this);
    }

    init = async (leagueId: number) => {
        runInAction(() => {
            this.leagueLoadStatus = LoadStatus.Loading;
        });

        try {
            const [league, currentGameId] = await Promise.all([
                apiClient.gameLeaguesGET(leagueId, undefined),
                apiClient.id(leagueId),
            ]);

            runInAction(() => {
                this.league = league;
                this.currentGameId = currentGameId;
                this.leagueLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.leagueLoadStatus = LoadStatus.Error;
            });
        }
    };
}

import 'reflect-metadata';

import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import { App } from './pages/App';
import reportWebVitals from './reportWebVitals';
import {
    ThemeProvider,
    createTheme,
    ThemeOptions,
    responsiveFontSizes,
} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GilroyRegular from './fonts/Gilroy-Regular.ttf';
import GilroyBold from './fonts/Gilroy-Bold.ttf';
import GilroyLight from './fonts/Gilroy-Light.ttf';
import GilroyMedium from './fonts/Gilroy-Medium.ttf';
import GilroyExtraBold from './fonts/Gilroy-ExtraBold.ttf';
import { store } from './store/store';
import { Notifications } from './modules/common/components/notifications/notifications';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from "react-ga4";

ReactGA.initialize("G-HPCTZZZK5S");

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

declare global {
    interface Window {
        toastr: any;
    }
}

interface CustomThemeOptions extends ThemeOptions {
    palette: ThemeOptions['palette'] & {
        cell?: {
            positive: string;
            negative: string;
        };
    };
}

const themeOptions: CustomThemeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#4D62F5',
            light: '#e8eaf9',
        },
        cell: {
            positive: '#088722',
            negative: '#B20000',
        },
        secondary: {
            main: '#5BE734',
        },
        text: {
            primary: '#000000',
            secondary: '#707074',
        },
        error: {
            main: '#ff0000',
            light: '#fae0df',
        },
        success: {
            main: '#5BE734',
        },
        background: {
            default: '#f1f1f1',
        },
    },
    spacing: 8,
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: 'Gilroy, Arial',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 300;
          src: url(${GilroyLight}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: url(${GilroyRegular}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 500;
          src: url(${GilroyMedium}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 700;
          src: url(${GilroyBold}) format('ttf');
        }
        @font-face {
          font-family: 'Gilroy';
          font-style: normal;
          font-display: swap;
          font-weight: 900;
          src: url(${GilroyExtraBold}) format('ttf');
        }
      `,
        },
        MuiToolbar: {
            styleOverrides: {
                regular: {
                    height: 80,
                    minHeight: 80,
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                },
            },
        },
        // MuiTableCell: {
        //     styleOverrides: {
        //         root: {
        //             border: 'none',
        //         },
        //         body: {
        //             minHeight: 72,
        //         },
        //         head: {
        //             color: '#707074',
        //         },
        //     },
        // },
        // MuiTableBody: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 20,
        //             boxShadow:
        //                 '0 1px 2px 0 rgba(0, 0, 0, 0.3), 0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        //         },
        //     },
        // },
        // MuiTableRow: {
        //     styleOverrides: {
        //         root: {
        //             '&:nth-of-type(odd)': {
        //                 backgroundColor: '#FFFFFF',
        //             },
        //             '&:nth-of-type(even)': {
        //                 backgroundColor: '#F9F9FE',
        //             },
        //             '&:first-child': {
        //                 'td:first-child': {
        //                     borderTopLeftRadius: 20,
        //                 },
        //                 'td:last-child': {
        //                     borderTopRightRadius: 20,
        //                 },
        //             },
        //             '&:last-child': {
        //                 'td:first-child': {
        //                     borderBottomLeftRadius: 20,
        //                 },
        //                 'td:last-child': {
        //                     borderBottomRightRadius: 20,
        //                 },
        //             },
        //         },
        //     },
        // },
        MuiAvatar: {
            styleOverrides: {
                colorDefault: {
                    backgroundColor: '#FFFFFF',
                },
            },
        },
    },
};

let theme = createTheme(themeOptions);
theme = responsiveFontSizes(theme);

root.render(
    <Provider store={store}>
        {/*<PersistGate loading={null} persistor={persistor}>*/}
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Notifications>
                    {/*<ActiveLeagueContextProvider>*/}
                    <App />
                    {/*</ActiveLeagueContextProvider>*/}
                </Notifications>
            </ThemeProvider>
        </BrowserRouter>
        {/*</PersistGate>*/}
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../../../common/api/api';
import Box from '@mui/material/Box';
import { MatchOverviews } from '../../../../common/components/match-overviews/match-overviews';
import { CurrentGameSportLeagues } from './current-game-sport-leagues';

interface CurrentGameMatchOverviewsProps {
    onClickSportLeague: (sportLeagueId: number) => void;
}

export const CurrentGameMatchOverviews: FC<CurrentGameMatchOverviewsProps> =
    observer(({ onClickSportLeague }) => {
        const [{ matchOverviewsLoadStatus, matchOverviews }] = useDependencies(
            CurrentLeagueGameStore
        );

        const navigate = useNavigate();

        const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
            navigate(`${matchOverview.match.id}`);
        };

        return (
            <Box mb={2}>
                <Box p={2}>
                    <CurrentGameSportLeagues
                        onClickSportLeague={onClickSportLeague}
                    />
                </Box>
                {!!matchOverviews && (
                    <MatchOverviews
                        loadStatus={matchOverviewsLoadStatus}
                        matchOverviews={matchOverviews}
                        onMatchClick={handleMatchClick}
                    />
                )}
            </Box>
        );
    });

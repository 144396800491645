import { FC, Fragment, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { BettingHubStore } from '../stores/betting-hub-store';
import { provide } from '../../common/utils/ioc/provide';

export const WithBettingHub: FC<PropsWithChildren> = provide([BettingHubStore])(
    observer(({ children }) => {
        const [{ init, dispose }] = useDependencies(BettingHubStore);

        useEffect(() => {
            init();

            return () => {
                dispose();
            };
        }, []);

        return <Fragment>{children}</Fragment>;
    })
);

import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import {
    GameModel,
    PlayerTournamentWinStatsModel,
    TournamentStatusParam,
} from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { apiClient } from '../../common/api/api-client';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';

@injectable()
export class HistoryStore {
    gameIdForLeaderboard?: (typeof this.games)[number]['id'];
    games: GameModel[] = [];
    areAllGamesFetched = false;
    gamesLoadStatus = LoadStatus.None;
    winStats?: PlayerTournamentWinStatsModel;

    constructor() {
        makeAutoObservable(this);
    }

    initHistory = async () => {
        await Promise.all([this.fetchWinStats(), this.fetchGames()]);
    };

    fetchWinStats = async () => {
        try {
            const winStats = await apiClient.tournamentWinStats();

            runInAction(() => {
                this.winStats = winStats;
            });
        } catch {
            // skip
        }
    };

    fetchGames = async () => {
        if (
            this.gamesLoadStatus === LoadStatus.Loading ||
            this.areAllGamesFetched
        ) {
            return;
        }

        try {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Loading;
            });

            const { items: games, totalAmount } =
                await apiClient.tournamentsGET(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    TournamentStatusParam.Ended,
                    undefined,
                    this.games.length,
                    ASYNC_LOAD_LIMIT,
                    undefined
                );

            if (games) {
                runInAction(() => {
                    this.games.push(...games);
                });
            }

            if (totalAmount <= this.games.length) {
                this.areAllGamesFetched = true;
            }

            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Error;
            });
        }
    };

    showLeaderboard = (gameId: (typeof this.games)[number]['id']) => {
        this.gameIdForLeaderboard = gameId;
    };

    hideLeaderboard = () => {
        this.gameIdForLeaderboard = undefined;
    };
}

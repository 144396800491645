import { FC, PropsWithChildren } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { useIsMobile } from '../../hooks/use-is-mobile';

interface ConnectedOddTypographyProps
    extends PropsWithChildren<TypographyProps> {
    light?: boolean;
}

export const ConnectedOddTypography: FC<ConnectedOddTypographyProps> = ({
    light,
    children,
    ...props
}) => {
    const isMobile = useIsMobile();

    const fontVariant = isMobile ? 'body2' : light ? 'body1' : undefined;
    const fontWeight = isMobile || light ? 400 : 700;

    return (
        <Typography
            sx={{ mx: 'auto' }}
            textAlign={'center'}
            variant={fontVariant}
            fontWeight={fontWeight}
            {...props}
        >
            {children}
        </Typography>
    );
};

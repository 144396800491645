import { forwardRef, ReactElement } from 'react';
import { GameModel } from '../../../api/api';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { StatusChip } from '../../../../lobby/components/lobby-games/status-chip';
import { formatDateWithName } from '../../../../../utils';
import { Balance } from '../../../../../components/smallComponents';
import { isGameEnded } from '../../../utils/is-game-ended';
import { SportLeagueIconList } from '../../sport-league-icon-list/sport-league-icon-list';

interface GameListItemMobileProps {
    game: GameModel;
    actions: ReactElement;
    hideInitialBalance?: boolean;
    onClick?: CardProps['onClick'];
}

export const GameListItemMobile = forwardRef<
    HTMLDivElement,
    GameListItemMobileProps
>(({ actions, game, hideInitialBalance, onClick }, ref) => {
    return (
        <Card
            ref={ref}
            sx={{ boxShadow: 2, padding: '20px' }}
            onClick={onClick}
        >
            <Box
                display="flex"
                justifyContent={'space-between'}
                gap={2}
                alignItems="center"
                mb={1}
            >
                <SportLeagueIconList leagues={game.leagues} />
                {!!game.ranking && (
                    <Box
                        flexShrink={0}
                        display={'flex'}
                        alignItems={'center'}
                        gap={0.5}
                    >
                        <Typography variant={'caption'} color={'textSecondary'}>
                            My rank
                        </Typography>
                        <Typography variant={'body1'}>
                            #{game.ranking}
                        </Typography>
                    </Box>
                )}
            </Box>
            <Box
                display="flex"
                justifyContent={'space-between'}
                gap={2}
                alignItems="center"
                mb={0.5}
            >
                <Typography
                    variant={'subtitle2'}
                    fontWeight={900}
                    fontSize={18}
                >
                    #{game.id}
                </Typography>
                {isGameEnded(game) && <StatusChip status={game.status} />}
            </Box>
            <Typography variant={'body1'} mb={0.5}>
                {formatDateWithName(game.startDate)} -{' '}
                {formatDateWithName(game.endDate)}
            </Typography>
            <Typography variant={'body1'} mb={0.5}>
                {game.currentPlayerCount}/{game.maxPlayerCount} Players
            </Typography>
            {!hideInitialBalance && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    mb={0.5}
                >
                    <Typography variant={'caption'} color={'textSecondary'}>
                        Initial Balance
                    </Typography>
                    <Balance
                        amount={game.initialBalance}
                        textProps={{
                            variant: 'subtitle2',
                            fontWeight: 900,
                            fontSize: 18,
                        }}
                    />
                </Box>
            )}
            {game.currentBalance !== null && (
                <Box
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                    mb={2}
                >
                    <Typography variant={'caption'} color={'textSecondary'}>
                        {isGameEnded(game) ? 'Final' : 'Current'} Balance
                    </Typography>
                    <Balance
                        amount={game.currentBalance}
                        textProps={{
                            variant: 'subtitle2',
                            fontWeight: 900,
                            fontSize: 18,
                        }}
                    />
                </Box>
            )}
            <Box mt={2}>{actions}</Box>
        </Card>
    );
});

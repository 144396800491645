import { injectable } from 'inversify';
import {
    GameLeagueUpsertModel,
    IGameLeagueUpsertModel,
    SportLeagueCombinedModel,
    SportLeagueTargetParam,
} from '../../common/api/api';
import { LoadStatus } from '../../common/enums/load-status';
import { FieldState, FormState } from 'formstate';
import { makeAutoObservable, runInAction } from 'mobx';
import { apiClient } from '../../common/api/api-client';
import { getDateWithAppliedHour, getTomorrow } from '../../common/utils/dates';
import { formStateValidators } from '../../common/utils/form-state-validators';

@injectable()
export class CreateLeagueStore {
    sportLeagues: SportLeagueCombinedModel[] = [];
    createLeagueLoadStatus = LoadStatus.None;
    initialBalanceOptions: number[] = [];
    playoffPlayerOptions: number[] = [];

    formState = new FormState({
        name: new FieldState<string>('').validators(
            (value) => !value.trim() && 'Required'
        ),
        sportLeagueIds: new FieldState<number[]>([]).validators(
            (value) => value.length === 0 && 'Select a League'
        ),
        initialBalance: new FieldState<number>(0),
        maxPlayers: new FieldState<number | null>(null).validators(
            (value) => value === null && 'Required'
        ),
        playoffPlayers: new FieldState<number | null>(null).validators(
            (value) => value === null && 'Required'
        ),
        startDate: new FieldState<Date | null>(getTomorrow()).validators(
            formStateValidators.dateValidator
        ),
        startTime: new FieldState<Date | null>(getTomorrow()).validators(
            formStateValidators.dateValidator
        ),
    });

    constructor() {
        this.formState.disableAutoValidation();

        makeAutoObservable(this);
    }

    initCreateLeague = async () => {
        const [sportLeagues, initialBalanceOptions] = await Promise.all([
            apiClient.sportLeagues(
                undefined,
                SportLeagueTargetParam.GameLeagueCreate
            ),
            apiClient.initialBalanceOptions(),
        ]);

        runInAction(() => {
            this.sportLeagues = sportLeagues;
            this.initialBalanceOptions = initialBalanceOptions;
        });

        this.formState.$.initialBalance.onChange(initialBalanceOptions[0]);
    };

    submitCreateLeague = async () => {
        await this.formState.enableAutoValidationAndValidate();

        if (this.formState.hasError) {
            throw new Error('Validation error');
        }

        runInAction(() => {
            this.createLeagueLoadStatus = LoadStatus.Loading;
        });

        const formStateValues = this.formState.$;

        const initDate = getDateWithAppliedHour(
            formStateValues.startDate.$!,
            formStateValues.startTime.$!
        );

        const data: IGameLeagueUpsertModel = {
            name: formStateValues.name.$,
            sportLeagueIds: formStateValues.sportLeagueIds.$,
            maxPlayerCount: formStateValues.maxPlayers.$!,
            initialBalance: formStateValues.initialBalance.$,
            playoffPlayerCount: formStateValues.playoffPlayers.$!,
            isPrivate: false,
            initDate,
        };

        const payload = new GameLeagueUpsertModel(data);

        try {
            const gameLeague = await apiClient.gameLeaguesPOST(payload);

            runInAction(() => {
                this.createLeagueLoadStatus = LoadStatus.Ok;
            });

            return gameLeague;
        } catch {
            runInAction(() => {
                this.createLeagueLoadStatus = LoadStatus.Error;
            });

            throw new Error('something went wrong');
        }
    };

    selectSportLeague = (selectedSportLeagueId: number) => {
        const { sportLeagueIds } = this.formState.$;
        const isAdding = !sportLeagueIds.value.includes(selectedSportLeagueId);
        const result = isAdding
            ? [...sportLeagueIds.value, selectedSportLeagueId]
            : sportLeagueIds.value.filter(
                  (leagueId) => leagueId !== selectedSportLeagueId
              );

        if (!result.length) {
            sportLeagueIds.enableAutoValidationAndValidate();
        }

        sportLeagueIds.onChange(result);
    };

    changeMaxPlayers = (value: number | null) => {
        this.formState.$.maxPlayers.onChange(value);
        this.resetAvailablePlayoffPlayerOptions();
        this.fetchAvailablePlayoffPlayerOptions();
    };

    resetAvailablePlayoffPlayerOptions = () => {
        this.formState.$.playoffPlayers.onChange(null);
        this.playoffPlayerOptions = [];
    };

    fetchAvailablePlayoffPlayerOptions = async () => {
        const maxPlayers = this.formState.$.maxPlayers.value;

        if (maxPlayers === null) {
            return;
        }

        try {
            const playoffPlayerOptions =
                await apiClient.playoffPlayerOptions(maxPlayers);

            runInAction(() => {
                this.playoffPlayerOptions = playoffPlayerOptions;
            });
        } catch {
            // skip
        }
    };
}

import { FC } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

export const HeaderContainer: FC<BoxProps> = ({ children, ...props }) => {
    return (
        <Box px={{ xs: 2, sm: 0 }} {...props}>
            {children}
        </Box>
    );
};

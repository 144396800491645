import { FC, Fragment, PropsWithChildren, ReactNode, useEffect } from 'react';
import { observer } from 'mobx-react';
import { provide } from '../../../common/utils/ioc/provide';
import { AnnouncementsStore } from '../../stores/announcements-store';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import {
    FocusNotificationType,
    GeneralAnnouncementMessageModel,
    RankedGameCreatedMessageModel,
    RankingSeasonChangedMessageModel,
    RankUpdatedMessageModel,
} from '../../../common/api/api';
import { GeneralAnnouncement } from '../general/general-announcement';
import { RankedGameCreatedAnnouncement } from '../ranked-game-created/ranked-game-created-announcement';
import { RankUpdatedAnnouncement } from '../rank-updated/rank-updated-announcement';
import { RankingSeasonChangedAnnouncement } from '../ranking-season-changed/ranking-season-changed-announcement';

export const WithAnnouncements: FC<PropsWithChildren> = provide([
    AnnouncementsStore,
])(
    observer(({ children }) => {
        const [{ init, currentAnnouncement }] =
            useDependencies(AnnouncementsStore);

        useEffect(() => {
            init();
        }, []);

        let announcementComponent: ReactNode | undefined;

        switch (currentAnnouncement?.notification.type) {
            case FocusNotificationType.GeneralAnnouncement: {
                announcementComponent = (
                    <GeneralAnnouncement
                        content={
                            currentAnnouncement.content as GeneralAnnouncementMessageModel
                        }
                    />
                );

                break;
            }
            case FocusNotificationType.RankedGameCreated: {
                announcementComponent = (
                    <RankedGameCreatedAnnouncement
                        content={
                            currentAnnouncement.content as RankedGameCreatedMessageModel
                        }
                    />
                );

                break;
            }
            case FocusNotificationType.RankUpdate: {
                announcementComponent = (
                    <RankUpdatedAnnouncement
                        content={
                            currentAnnouncement.content as RankUpdatedMessageModel
                        }
                    />
                );

                break;
            }
            case FocusNotificationType.RankedSeasonChanged: {
                announcementComponent = (
                    <RankingSeasonChangedAnnouncement
                        content={
                            currentAnnouncement.content as RankingSeasonChangedMessageModel
                        }
                    />
                );

                break;
            }
        }

        return (
            <Fragment>
                {children}
                {announcementComponent}
            </Fragment>
        );
    })
);

import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import { GameModel, TournamentStatusParam } from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';
import { ASYNC_LOAD_LIMIT } from '../../common/utils/ux';

@injectable()
export class CurrentGamesStore {
    gamesLoadStatus = LoadStatus.None;
    areAllGamesFetched = false;
    games: GameModel[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    fetchGames = async () => {
        if (
            this.gamesLoadStatus === LoadStatus.Loading ||
            this.areAllGamesFetched
        ) {
            return;
        }

        try {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Loading;
            });

            const { items: games, totalAmount } =
                await apiClient.tournamentsGET(
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    TournamentStatusParam.Current,
                    undefined,
                    this.games.length,
                    ASYNC_LOAD_LIMIT,
                    undefined
                );

            if (games) {
                runInAction(() => {
                    this.games.push(...games);
                });
            }

            if (totalAmount <= this.games.length) {
                this.areAllGamesFetched = true;
            }

            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.gamesLoadStatus = LoadStatus.Error;
            });
        }
    };

    forfeitGame = async (gameId: (typeof this.games)[number]['id']) => {
        try {
            await apiClient.tournamentParticipationsDELETE(gameId);

            runInAction(() => {
                this.games = this.games.filter(({ id }) => id !== gameId);
            });
        } catch {
            // skip
        }
    };
}

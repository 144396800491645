import { FC } from 'react';
import { MatchOddModel, MatchWithOddsModel, TeamModel } from '../../api/api';
import { Box, Grid } from '@mui/material';
import Card from '@mui/material/Card';
import { ConnectedOdd } from '../connected-odd/connected-odd';
import { ODD_COL_SIZE } from './ui';
import { TeamInfo } from './team-info';

export interface MatchOverviewRowProps {
    currentTeam?: TeamModel;
    currentOdds: MatchOddModel[];
    matchOverview: MatchWithOddsModel;
    onMatchClick: (matchOverview: MatchWithOddsModel) => void;
}

export const MatchOverviewRow: FC<MatchOverviewRowProps> = ({
    matchOverview,
    currentTeam,
    currentOdds,
    onMatchClick,
}) => {
    return (
        <Grid container spacing={0.2}>
            <Grid item xs zeroMinWidth>
                <Card
                    sx={{
                        height: '100%',
                        cursor: 'pointer',
                        boxShadow: 'none',
                        minHeight: '74px',
                    }}
                    onClick={() => onMatchClick(matchOverview)}
                >
                    <Box
                        sx={{
                            height: '100%',
                            display: 'flex',
                            p: 1,
                            gap: 3,
                        }}
                    >
                        {currentTeam ? (
                            <TeamInfo team={currentTeam} />
                        ) : (
                            <Box display={'flex'} flexDirection={'column'}>
                                <TeamInfo
                                    team={matchOverview.match.teams![0]}
                                />
                                <TeamInfo
                                    team={matchOverview.match.teams![1]}
                                />
                            </Box>
                        )}
                    </Box>
                </Card>
            </Grid>
            {currentOdds.map((odd, index) => {
                return (
                    <Grid key={index} item xs={ODD_COL_SIZE}>
                        {/* odds may be null */}
                        {odd.americanStyleOdd !== undefined && (
                            <Card
                                sx={{
                                    height: '100%',
                                    boxShadow: 'none',
                                }}
                            >
                                <ConnectedOdd odd={odd} />
                            </Card>
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

import { FC, PropsWithChildren, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../../common/utils/ioc/provide';
import { StandingsStore } from '../../../stores/standings-store';
import Box from '@mui/material/Box';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { StandingsTable } from './standings-table';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import { FormControl, MenuItem, Select, useTheme } from '@mui/material';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { formatDateWithName } from '../../../../../utils';
import { ScoreboardRow } from './scoreboard-row';
import Grid from '@mui/material/Grid';
import { isOdd } from '../../../../common/utils/is-odd';

const ScoreboardGridItem: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme();

    return (
        <Grid
            item
            xs={12}
            sm={6}
            sx={{
                background: theme.palette.grey[300],
            }}
        >
            {children}
        </Grid>
    );
};

export const Standings = provide([StandingsStore])(
    observer(() => {
        const [
            {
                init,
                standings,
                scoreboard,
                weeks,
                roundNumber: selectedRoundNumber,
                changeRound,
            },
        ] = useDependencies(StandingsStore);

        useEffect(() => {
            init();
        }, []);

        const isMobile = useIsMobile();

        return standings ? (
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={4}
                px={{ sm: 4 }}
            >
                <Box
                    display={'flex'}
                    gap={3}
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{ xs: 'stretch', sm: 'start' }}
                >
                    {standings.conferences?.map((conference) => (
                        <StandingsTable
                            key={conference.id}
                            conference={conference}
                        />
                    ))}
                </Box>
                <Box>
                    <Box
                        px={2}
                        mb={2}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <Typography
                            variant={isMobile ? 'subtitle1' : 'h5'}
                            fontWeight={700}
                        >
                            Scoreboard
                        </Typography>
                        <FormControl size="small">
                            <Select
                                value={selectedRoundNumber ?? ''}
                                onChange={(event) => {
                                    changeRound(+event.target.value);
                                }}
                                sx={{ background: 'white' }}
                            >
                                {weeks.map(
                                    ({ roundNumber, startDate, endDate }) => (
                                        <MenuItem
                                            key={roundNumber}
                                            value={roundNumber}
                                        >
                                            Matchup {roundNumber} (
                                            {formatDateWithName(
                                                startDate,
                                                true
                                            )}{' '}
                                            -{' '}
                                            {formatDateWithName(endDate, true)})
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <Card
                        sx={{
                            boxShadow: { xs: 0, sm: 1 },
                            borderRadius: { xs: 0, sm: '20px' },
                        }}
                    >
                        <Grid container spacing={'1px'}>
                            {scoreboard.map((game) => {
                                return (
                                    <ScoreboardGridItem key={game.gameId}>
                                        <Box sx={{ background: 'white' }}>
                                            <ScoreboardRow game={game} />
                                        </Box>
                                    </ScoreboardGridItem>
                                );
                            })}
                            {!isMobile && isOdd(scoreboard.length) && (
                                <ScoreboardGridItem>
                                    <Box
                                        sx={{ background: 'white' }}
                                        height={'100%'}
                                    />
                                </ScoreboardGridItem>
                            )}
                        </Grid>
                    </Card>
                </Box>
                <Box />
            </Box>
        ) : null;
    })
);

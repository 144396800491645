import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import Box from '@mui/material/Box';
import { GameLeagueListItemMobile } from '../../../../common/components/game-leagues/mobile/game-league-list-item-mobile';
import { LiveLeaguesStore } from '../../../stores/live-leagues-store';
import { LastElementSetter } from '../../../../common/utils/types';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

interface LiveLeaguesMobileProps {
    setLastElement: LastElementSetter;
    onJoinLeague: (leagueId: number) => void;
}

export const LiveLeaguesMobile: FC<LiveLeaguesMobileProps> = observer(
    ({ setLastElement, onJoinLeague }) => {
        const [{ leagues }] = useDependencies(LiveLeaguesStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {leagues.map((league, index) => (
                    <GameLeagueListItemMobile
                        key={league.id}
                        league={league}
                        ref={
                            index === leagues.length - 1
                                ? setLastElement
                                : undefined
                        }
                        actions={
                            <Button
                                onClick={() => {
                                    onJoinLeague(league.id);
                                }}
                                endIcon={<DirectionsWalkIcon />}
                                fullWidth
                                variant={'outlined'}
                                sx={{
                                    boxShadow: 1,
                                }}
                            >
                                <Typography>Join</Typography>
                            </Button>
                        }
                    />
                ))}
            </Box>
        );
    }
);

export const containerStyles = {
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  width: "100%",
};

export const EMAIL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
export const ONLY_NUMBERS_REGEX = /^[0-9]*$/;

export const REQUIRED_FIELD = "Required";
export const DEFAULT_PRICE = 1.49;

export const IS_DEMO = false;

export const MONTHS = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

const getDaysInMonth = (month: number) => {
  const year = new Date().getFullYear();
  var date = new Date(year, month, 1);
  var days = [];
  while (date.getMonth() === month) {
    days.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return days;
};


export const PLAYOFF_PLAYER_COUNTS = [4, 8, 16];
export const MAX_PLAYERS_COUNT = [4,6,8,10,12,14,16,18,20]
import { RankingSeasonChangedMessageModel } from '../../../common/api/api';
import { observer } from 'mobx-react';
import { FC } from 'react';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { AnnouncementsStore } from '../../stores/announcements-store';
import { Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';

interface RankingSeasonChangedAnnouncementProps {
    content: RankingSeasonChangedMessageModel;
}

export const RankingSeasonChangedAnnouncement: FC<RankingSeasonChangedAnnouncementProps> =
    observer(({ content }) => {
        const [{ viewCurrentAnnouncement }] =
            useDependencies(AnnouncementsStore);

        return (
            <Dialog open onClose={viewCurrentAnnouncement}>
                <Paper sx={{ width: 400, height: 400, overflowY: 'auto' }}>
                    Ranking Season Changed Announcement
                    <pre>{JSON.stringify(content, null, 2)}</pre>
                </Paper>
            </Dialog>
        );
    });

import { injectable } from 'inversify';

import { GameContentBaseStore } from '../../common/stores/game-content-base-store';
import { action, makeObservable, observable } from 'mobx';

@injectable()
export class GameContentStore extends GameContentBaseStore {
    isShowingLeaderboard = false;
    isShowingBetHistory = false;

    constructor() {
        super();

        makeObservable(this, {
            isShowingLeaderboard: observable,
            showLeaderboard: action,
            hideLeaderboard: action,

            isShowingBetHistory: observable,
            showBetHistory: action,
            hideBetHistory: action,
        });
    }

    showLeaderboard = () => {
        this.isShowingLeaderboard = true;
    };

    hideLeaderboard = () => {
        this.isShowingLeaderboard = false;
    };

    showBetHistory = () => {
        this.isShowingBetHistory = true;
    };

    hideBetHistory = () => {
        this.isShowingBetHistory = false;
    };
}

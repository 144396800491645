import Box from '@mui/material/Box';
import { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LobbyGames } from '../lobby-games/lobby-games';
import { Participants } from '../../../participants/components/participants';
import { CreateGame } from '../../../create-game/components/create-game';
import { LobbyStore } from '../../stores/lobby-store';
import { observer } from 'mobx-react-lite';
import LobbySectionImage from '../../../../assets/images/lobby-section-image.png';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { provide } from '../../../common/utils/ioc/provide';
import { SectionWithBackground } from '../../../common/components/section-with-background/section-with-background';
import Link from '@mui/material/Link';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { GameFullModel } from '../../../common/api/api';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { InvitedGame } from '../invited-game/invited-game';

export const LobbyPage = provide([LobbyStore])(
    observer(() => {
        const [
            {
                gamesLoadStatus,
                gameForParticipants,
                fetchGames,
                showCreateGame,
                isCreatingGame,
                hideCreateGame,
                games,
                initInvitedGame,
                invitedGame,
                hideParticipants,
                getParticipants,
            },
            { notify },
        ] = useDependencies(LobbyStore, NotificationsStore);

        const [searchParams] = useSearchParams();

        useEffect(() => {
            // TODO: have globally defined keys
            const invitationId = searchParams.get('invitationId');
            const token = searchParams.get('token');

            if (invitationId) {
                initInvitedGame(
                    +invitationId,
                    token ? decodeURIComponent(token) : undefined
                );
            }
        }, []);

        useEffect(() => {
            fetchGames();
        }, []);

        const navigate = useNavigate();

        const createGameCallback = (game?: GameFullModel) => {
            if (game) {
                notify({
                    severity: 'success',
                    children: (
                        <Fragment>
                            <Typography>
                                Successfully created! ID: #{game.id}. See&nbsp;
                                <Link
                                    sx={{ cursor: 'pointer' }}
                                    onClick={() => {
                                        // closeNotification();
                                        navigate('/my-games/upcoming');
                                    }}
                                >
                                    Upcoming Games
                                </Link>
                                .
                            </Typography>
                        </Fragment>
                    ),
                });
            }
        };

        return (
            <Fragment>
                <SectionWithBackground
                    imgSrc={LobbySectionImage}
                    description={'PLAYER vs PLAYER'}
                    headline={'THE HOUSE NEVER WINS'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', sm: 'row' },
                            gap: 3,
                            mb: 3,
                        }}
                    >
                        <Typography variant={'h4'} fontWeight={900}>
                            Games
                        </Typography>
                        <Button
                            onClick={showCreateGame}
                            variant={'contained'}
                            sx={{ width: { xs: '100%', sm: '344px' } }}
                        >
                            Create Game
                        </Button>
                    </Box>
                    <AsyncList
                        length={games.length}
                        loadStatus={gamesLoadStatus}
                        onFetch={fetchGames}
                    >
                        {(setLastElement) => (
                            <LobbyGames setLastElement={setLastElement} />
                        )}
                    </AsyncList>
                </SectionWithBackground>
                {isCreatingGame && (
                    <CreateGame
                        successCallback={createGameCallback}
                        onClose={hideCreateGame}
                    />
                )}
                {!!invitedGame && <InvitedGame game={invitedGame} />}
                {!!gameForParticipants && (
                    <Participants
                        onClose={hideParticipants}
                        getParticipants={getParticipants}
                    />
                )}
            </Fragment>
        );
    })
);

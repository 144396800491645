import { observer } from 'mobx-react-lite';
import { provide } from '../../../../common/utils/ioc/provide';
import { ALL_OPTION_VALUE, MatchupStore } from '../../../stores/matchup-store';
import { FC, Fragment, useEffect } from 'react';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import {
    FormControl,
    IconButton,
    IconButtonProps,
    MenuItem,
    Select,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { GameMatchup } from '../game-matchup/game-matchup';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { formatDailyDate } from '../../../../../utils';
import { HeaderContainer } from '../../../../common/components/header-container/header-container';

const ArrowButtonIcon: FC<IconButtonProps> = ({ children, sx, ...props }) => {
    return (
        <IconButton
            sx={{
                borderRadius: 1,
                border: `1px solid #dedede`,
                background: 'white',
                ...sx,
            }}
            {...props}
        >
            {children}
        </IconButton>
    );
};

export const Matchup = provide([MatchupStore])(
    observer(() => {
        const [
            {
                init,
                playersWithBets,
                isNextDisabled,
                selectNext,
                isPrevDisabled,
                selectPrev,
                games,
                gameIndex,
                selectedTimestampOption,
                timestampOptions,
                changeTimestamp,
            },
        ] = useDependencies(MatchupStore);

        useEffect(() => {
            init();
        }, []);

        return (
            <Fragment>
                <Box
                    mb={{ xs: 2, sm: 1 }}
                    mx={{ xs: 2, sm: 0 }}
                    display={'flex'}
                    justifyContent={'end'}
                >
                    <FormControl size="small">
                        <Select
                            value={selectedTimestampOption ?? 'All'}
                            onChange={(event) => {
                                changeTimestamp(
                                    +event.target.value || undefined
                                );
                            }}
                            sx={{ background: 'white' }}
                        >
                            <MenuItem value={ALL_OPTION_VALUE ?? 'All'}>
                                All
                            </MenuItem>
                            {timestampOptions.map((timestamp) => {
                                return (
                                    <MenuItem key={timestamp} value={timestamp}>
                                        {formatDailyDate(new Date(timestamp))}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Box>
                <Box px={{ sm: 4 }}>
                    <HeaderContainer mb={{ xs: 2, sm: 3 }}>
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={{
                                xs: 'space-between',
                                sm: 'center',
                            }}
                            gap={3}
                        >
                            <ArrowButtonIcon
                                disabled={isPrevDisabled}
                                onClick={selectPrev}
                            >
                                <ArrowBackIosNewIcon />
                            </ArrowButtonIcon>
                            <Typography variant={'subtitle1'}>
                                {gameIndex + 1}/{games.length}
                            </Typography>
                            <ArrowButtonIcon
                                disabled={isNextDisabled}
                                onClick={selectNext}
                            >
                                <ArrowForwardIosIcon />
                            </ArrowButtonIcon>
                        </Box>
                    </HeaderContainer>
                    <GameMatchup playersWithBets={playersWithBets} />
                </Box>
            </Fragment>
        );
    })
);

export const filteredUniqueById = <T>(
    array: T[],
    getter: (item: T) => number | string
) => {
    const seenIds = new Set();

    return array.filter((item) => {
        const fieldValue = getter(item);

        if (fieldValue && !seenIds.has(fieldValue)) {
            seenIds.add(fieldValue);
            return true;
        }
        return false;
    });
};

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../common/utils/ioc/provide';
import { SlateStore } from '../stores/slate-store';
import {
    Navigate,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { CommonSportLayout } from '../../common/components/common-sport-layout/common-sport-layout';
import { BetSlip } from '../../bet-slip/components/bet-slip';
import { SlateLeagues } from './slate-leagues';
import { SlateMatchOverviews } from './slate-match-overviews';
import { BetSlipStore } from '../../bet-slip/stores/bet-slip-store';
import { SlateMatchOdds } from './slate-match-odds';
import { LoadStatus } from '../../common/enums/load-status';

export const Slate = provide([SlateStore, BetSlipStore])(
    observer(() => {
        const { leagueId: leagueIdParam, matchId: matchIdParam } = useParams();

        const [
            {
                sportLeagueId,
                sportLeaguesLoadStatus,
                init,
                selectSportLeague,
                selectMatch,
                sports,
            },
        ] = useDependencies(SlateStore);

        useEffect(() => {
            // TODO: add number validation
            if (leagueIdParam) {
                selectSportLeague(+leagueIdParam);
            }
        }, [leagueIdParam]);

        useEffect(() => {
            // TODO: add number validation
            selectMatch(matchIdParam ? +matchIdParam : undefined);
        }, [matchIdParam]);

        useEffect(() => {
            init();
        }, []);

        const navigate = useNavigate();

        const handleClickLeague = (selectedId: number, replace?: boolean) => {
            if (selectedId !== sportLeagueId) {
                navigate(`../${selectedId}`, { replace });
            }
        };

        useEffect(() => {
            if (
                sportLeaguesLoadStatus === LoadStatus.Ok &&
                leagueIdParam === undefined
            ) {
                const firstValidSport = sports.find(
                    (sport) => !!sport.leagues?.length
                );

                if (firstValidSport) {
                    const firstLeague = firstValidSport.leagues![0];
                    handleClickLeague(firstLeague.id, true);
                }
            }
        }, [sportLeaguesLoadStatus]);

        const handleBetSlipMatchClick = (matchId: number) => {
            navigate(`../${sportLeagueId}/${matchId}`);
        };

        return (
            <CommonSportLayout
                leagues={<SlateLeagues onClickLeague={handleClickLeague} />}
                content={
                    matchIdParam ? (
                        <SlateMatchOdds />
                    ) : (
                        <SlateMatchOverviews
                            onClickLeague={handleClickLeague}
                        />
                    )
                }
                betSlip={
                    <BetSlip
                        onMatchClick={handleBetSlipMatchClick}
                        isReview={true}
                    />
                }
            />
        );
    })
);

export const SlatePage = () => {
    return (
        <Routes>
            <Route path={':leagueId?/:matchId?'} element={<Slate />} />
            <Route path={'*'} element={<Navigate to={''} replace />} />
        </Routes>
    );
};

import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { CurrentLeagueGameStore } from '../../../stores/current-league-game-store';
import { useNavigate } from 'react-router-dom';
import { MatchOdds } from '../../../../common/components/match-odds/match-odds';
import Box from '@mui/material/Box';
import { BackButton } from '../../../../common/components/back-button/back-button';

export const CurrentGameMatchOdds = observer(() => {
    const [
        {
            matchInfo,
            matchSectionId,
            matchSections,
            matchOddsLoadStatus,
            selectOddSection,
            sportLeagueId,
            oddSubsectionGroups,
        },
    ] = useDependencies(CurrentLeagueGameStore);

    const navigate = useNavigate();

    return (
        <>
            <Box m={2}>
                <BackButton
                    label={'Matches'}
                    onClick={() => navigate(`../${sportLeagueId}`)}
                />
            </Box>
            {!!matchInfo && (
                <MatchOdds
                    onSelectSection={selectOddSection}
                    oddsLoadStatus={matchOddsLoadStatus}
                    oddSubsectionGroups={oddSubsectionGroups}
                    match={matchInfo}
                    matchSections={matchSections}
                    matchSectionId={matchSectionId}
                />
            )}
        </>
    );
});

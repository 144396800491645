import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { CurrentGamesStore } from '../../stores/current-games-store';
import { FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { TableColumnConfig } from '../../../common/utils/types';
import { GameModel } from '../../../common/api/api';
import { formatDateWithName } from '../../../../utils';
import { Balance } from '../../../../components/smallComponents';
import { TypographyTableCell } from '../../../common/components/table-cells/typography-table-cell';
import { SportLeagueIconList } from '../../../common/components/sport-league-icon-list/sport-league-icon-list';
import Table from '@mui/material/Table';
import { TableHead, useTheme } from '@mui/material';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import BlockIcon from '@mui/icons-material/Block';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { CurrentGamesMobile } from './mobile/current-games-mobile';
import { useConfirm } from 'material-ui-confirm';
import { noop } from '../../../common/utils/noop';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { CLICKABLE_TABLE_ROW_SX } from '../../../common/utils/ui';

const currentGamesColumns: TableColumnConfig<GameModel>[] = [
    {
        title: 'Game ID',
        getComponent: (game) => (
            <TypographyTableCell>#{game.id}</TypographyTableCell>
        ),
    },
    {
        title: 'Start Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.startDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'End Date',
        getComponent: (game) => (
            <TypographyTableCell>
                {formatDateWithName(game.endDate)}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Participants',
        getComponent: (game) => (
            <TypographyTableCell>
                {game.currentPlayerCount}/{game.maxPlayerCount}
            </TypographyTableCell>
        ),
    },
    {
        title: 'Current Balance',
        getComponent: (game) => (
            <Balance
                textProps={{ fontWeight: 700 }}
                amount={game.currentBalance}
            />
        ),
    },
    {
        title: 'Leagues',
        getComponent: (game) => <SportLeagueIconList leagues={game.leagues} />,
    },
    {
        title: 'Ranking',
        getComponent: (game) => (
            <TypographyTableCell>#{game.ranking}</TypographyTableCell>
        ),
    },
];

export interface CurrentGamesProps {
    onGameClick: (gameId: number) => void;
}

export const CurrentGames: FC<CurrentGamesProps> = provide([CurrentGamesStore])(
    observer(({ onGameClick }) => {
        const [{ fetchGames, games, forfeitGame, gamesLoadStatus }] =
            useDependencies(CurrentGamesStore);

        const theme = useTheme();

        useEffect(() => {
            fetchGames();
        }, []);

        const isMobile = useIsMobile();
        const confirm = useConfirm();

        const handleForfeit = (id: number) => {
            confirm({
                description: (
                    <span>
                        Are you sure you want to forfeit this game?
                        <br />
                        This action will be considered a loss.
                    </span>
                ),
                title: 'Forfeit Game',
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error',
                    fullWidth: true,
                },
                confirmationText: 'Yes, forfeit',
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'inherit',
                    fullWidth: true,
                },
            })
                .then(() => {
                    forfeitGame(id);
                })
                .catch(noop);
        };

        return (
            <AsyncList
                length={games.length}
                loadStatus={gamesLoadStatus}
                onFetch={fetchGames}
            >
                {(setLastElement) =>
                    isMobile ? (
                        <CurrentGamesMobile
                            onForfeit={handleForfeit}
                            onGameClick={onGameClick}
                            setGameElement={setLastElement}
                        />
                    ) : (
                        <CustomTable>
                            <TableHead>
                                <TableRow>
                                    {currentGamesColumns.map(
                                        (column, columnIndex) => (
                                            <TableCell key={columnIndex}>
                                                {column.title}
                                            </TableCell>
                                        )
                                    )}
                                    <TableCell />
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {games.map((game, gameIndex) => (
                                    <TableRow
                                        key={game.id}
                                        ref={
                                            gameIndex === games.length - 1
                                                ? setLastElement
                                                : undefined
                                        }
                                        sx={CLICKABLE_TABLE_ROW_SX}
                                        onClick={() => {
                                            onGameClick(game.id);
                                        }}
                                    >
                                        {currentGamesColumns.map(
                                            (column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.getComponent(game)}
                                                </TableCell>
                                            )
                                        )}
                                        <TableCell align={'right'}>
                                            <CustomIconButton
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    handleForfeit(game.id);
                                                }}
                                                color={theme.palette.error.main}
                                            >
                                                <BlockIcon />
                                            </CustomIconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </CustomTable>
                    )
                }
            </AsyncList>
        );
    })
);

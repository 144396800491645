import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { LeagueContentStore } from '../../stores/league-content-store';
import {
    Navigate,
    Link as RouterLink,
    Route,
    Routes,
    useNavigate,
    useParams,
} from 'react-router-dom';
import Box from '@mui/material/Box';
import { LeagueContentRoutes } from '../../utils/league-content-routes';
import { Tab, Tabs } from '@mui/material';
import Container from '@mui/material/Container';
import { BackButton } from '../../../common/components/back-button/back-button';
import { Fragment, useEffect } from 'react';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import Typography from '@mui/material/Typography';
import { StandingsMatchupPage } from '../standings-matchup-page/standings-matchup-page';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { asParentPath } from '../../../common/utils/as-parent-path';
import { LoadStatus } from '../../../common/enums/load-status';
import Button from '@mui/material/Button';

const LeagueContentPageMain = provide([LeagueContentStore])(
    observer(() => {
        const [{ init, league, leagueLoadStatus, currentGameId }] =
            useDependencies(LeagueContentStore);

        const navigate = useNavigate();
        const { tab, leagueId } = useParams();

        useEffect(() => {
            if (leagueId) {
                init(+leagueId);
            }
        }, []);

        const isMobile = useIsMobile();

        let availableRoutes = LeagueContentRoutes;

        if (!isMobile) {
            availableRoutes = availableRoutes.filter(
                ({ mobileOnly }) => !mobileOnly
            );
        }

        if (!availableRoutes.find(({ pathname }) => pathname === tab)) {
            return (
                <Navigate to={`../${availableRoutes[0].pathname}`} replace />
            );
        }

        const handleClickPlaceBets = () => {
            navigate(`/my-games/content/${currentGameId}`);
        };

        const tabs = (
            <Tabs value={tab} centered>
                {availableRoutes.map(({ name, pathname }) => {
                    return (
                        <Tab
                            sx={{ minWidth: 'unset' }}
                            key={name}
                            label={name}
                            component={RouterLink}
                            to={`../${pathname}`}
                            value={pathname}
                        />
                    );
                })}
            </Tabs>
        );

        return (
            <Container
                maxWidth={'xl'}
                sx={{
                    py: {
                        xs: 2,
                        sm: 3,
                    },
                    px: {
                        xs: 0,
                        sm: 15,
                    },
                }}
            >
                <Box px={{ xs: 2, sm: 0 }} mb={2}>
                    <Box mb={1}>
                        <BackButton
                            label={'Leagues'}
                            onClick={() => navigate('../..')}
                        />
                    </Box>
                    <Typography
                        variant="h4"
                        fontWeight={900}
                        sx={{ mb: { xs: 1, sm: 3 } }}
                    >
                        {league?.name}
                    </Typography>
                    {!isMobile && (
                        <Box
                            display={'flex'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                        >
                            {tabs}
                            <Button
                                disabled={currentGameId === undefined}
                                variant={'contained'}
                                onClick={handleClickPlaceBets}
                            >
                                Place Bets
                            </Button>
                        </Box>
                    )}
                </Box>
                {isMobile && tabs}
                {leagueLoadStatus === LoadStatus.Ok &&
                    availableRoutes.map(({ pathname, Component }) => {
                        if (tab === pathname) {
                            return <Component key={pathname} />;
                        }

                        return <Fragment key={pathname} />;
                    })}
            </Container>
        );
    })
);

export const LeagueContentPage = () => {
    return (
        <Routes>
            <Route
                path="standings/:gameId"
                element={<StandingsMatchupPage />}
            />
            <Route
                path={asParentPath(':tab?')}
                element={<LeagueContentPageMain />}
            />
        </Routes>
    );
};

import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../../common/utils/ioc/provide';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { TableHead, useTheme } from '@mui/material';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { Box } from '@mui/system';
import { SearchLeagues } from '../search-leagues';
import { MyLeaguesStore } from '../../stores/my-leagues-store';
import { AsyncList } from '../../../common/components/async-table-list/async-list';
import { useNavigate } from 'react-router-dom';
import { leaguesListColumns } from '../../utils/leagues-list-columns';
import { MyLeaguesMobile } from './mobile/my-leagues-mobile';
import { CustomIconButton } from '../../../common/components/custom-icon-button/custom-icon-button';
import LogoutIcon from '@mui/icons-material/Logout';
import { noop } from '../../../common/utils/noop';
import { GameLeaguePublicModel, GameModel } from '../../../common/api/api';
import { useConfirm } from 'material-ui-confirm';
import Button from '@mui/material/Button';
import { NotificationsStore } from '../../../common/stores/notifications-store';
import { CustomTable } from '../../../common/components/custom-table/custom-table';
import { CLICKABLE_TABLE_ROW_SX } from '../../../common/utils/ui';
import ShareIcon from '@mui/icons-material/Share';
import { InvitePlayers } from '../../../invite-players/components/invite-players';

export const MyLeagues = provide([MyLeaguesStore])(
    observer(() => {
        const [
            {
                fetchLeagues,
                leaguesLoadStatus,
                leaveLeague,
                searchLeagues,
                leagues,
                getActiveGame,
                getShareLink,
                startInvitation,
                sendInvite,
                endInvitation,
                leagueToShare,
            },
            { notify },
        ] = useDependencies(MyLeaguesStore, NotificationsStore);

        const theme = useTheme();
        const confirm = useConfirm();

        useEffect(() => {
            fetchLeagues();
        }, []);

        const isMobile = useIsMobile();
        const navigate = useNavigate();

        const handleLeagueClick = (league: GameLeaguePublicModel) => {
            if (!league.hasStarted) {
                return;
            }

            navigate(`../content/${league.id}`);
        };

        const handleLeave = (league: GameLeaguePublicModel) => {
            confirm({
                description: 'Are you sure you want to leave this league?',
                title: `Leave League`,
                confirmationButtonProps: {
                    variant: 'contained',
                    color: 'error',
                    fullWidth: true,
                },
                confirmationText: `Yes, Leave`,
                cancellationButtonProps: {
                    variant: 'contained',
                    color: 'inherit',
                    fullWidth: true,
                },
            })
                .then(() => {
                    leaveLeague(league);
                })
                .catch(noop);
        };

        const handleShare = (league: GameLeaguePublicModel) => {
            startInvitation(league);
        };

        const handlePlaceBets = (league: GameLeaguePublicModel) => {
            getActiveGame(league).then((gameId) => {
                if (gameId) {
                    navigate(`/my-games/content/${gameId}`);
                } else {
                    notify({
                        severity: 'error',
                        children:
                            "There isn't an active game, please come back later",
                    });
                }
            });
        };

        return (
            <Box
                display="flex"
                flexDirection="column"
                alignItems={{ sm: 'start' }}
                gap={3}
            >
                <SearchLeagues onSearch={searchLeagues} />
                <AsyncList
                    length={leagues.length}
                    loadStatus={leaguesLoadStatus}
                    onFetch={fetchLeagues}
                >
                    {(setLastElement) =>
                        isMobile ? (
                            <MyLeaguesMobile
                                onLeave={handleLeave}
                                onShare={handleShare}
                                onPlaceBets={handlePlaceBets}
                                setLastElement={setLastElement}
                                onClick={handleLeagueClick}
                            />
                        ) : (
                            <CustomTable>
                                <TableHead>
                                    <TableRow>
                                        {leaguesListColumns.map(
                                            (column, columnIndex) => (
                                                <TableCell key={columnIndex}>
                                                    {column.title}
                                                </TableCell>
                                            )
                                        )}
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leagues.map((league, leagueIndex) => (
                                        <TableRow
                                            key={league.id}
                                            ref={
                                                leagueIndex ===
                                                leagues.length - 1
                                                    ? setLastElement
                                                    : undefined
                                            }
                                            sx={
                                                league.hasStarted
                                                    ? CLICKABLE_TABLE_ROW_SX
                                                    : undefined
                                            }
                                            onClick={() => {
                                                handleLeagueClick(league);
                                            }}
                                        >
                                            {leaguesListColumns.map(
                                                (column, columnIndex) => (
                                                    <TableCell
                                                        key={columnIndex}
                                                    >
                                                        {column.getComponent(
                                                            league
                                                        )}
                                                    </TableCell>
                                                )
                                            )}
                                            <TableCell align={'right'}>
                                                {league.hasStarted ? (
                                                    <Button
                                                        sx={{ boxShadow: 1 }}
                                                        variant={'outlined'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handlePlaceBets(
                                                                league
                                                            );
                                                        }}
                                                    >
                                                        Place Bets
                                                    </Button>
                                                ) : (
                                                    <Box
                                                        display={'flex'}
                                                        gap={2}
                                                        justifyContent={
                                                            'flex-end'
                                                        }
                                                    >
                                                        <CustomIconButton
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                handleShare(
                                                                    league
                                                                );
                                                            }}
                                                            color={
                                                                theme.palette
                                                                    .primary
                                                                    .main
                                                            }
                                                        >
                                                            <ShareIcon />
                                                        </CustomIconButton>
                                                        <CustomIconButton
                                                            onClick={(
                                                                event
                                                            ) => {
                                                                event.stopPropagation();
                                                                handleLeave(
                                                                    league
                                                                );
                                                            }}
                                                            color={
                                                                theme.palette
                                                                    .error.main
                                                            }
                                                        >
                                                            <LogoutIcon />
                                                        </CustomIconButton>
                                                    </Box>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </CustomTable>
                        )
                    }
                </AsyncList>
                {!!leagueToShare && (
                    <InvitePlayers
                        onInvite={sendInvite}
                        onClose={endInvitation}
                        getShareLink={getShareLink}
                    />
                )}
            </Box>
        );
    })
);

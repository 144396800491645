import { injectable } from 'inversify';
import { LeaguesListBaseStore } from './leagues-list-base-store';
import { apiClient } from '../../common/api/api-client';
import { runInAction } from 'mobx';

@injectable()
export class LiveLeaguesStore extends LeaguesListBaseStore {
    isParticipating = false;

    joinLeague = async (leagueId: number) => {
        const league = this.leagues.find(({ id }) => id === leagueId);

        if (!league) {
            throw new Error('game not found');
        }

        await apiClient.participationPOST(leagueId, undefined);

        runInAction(() => {
            this.leagues = this.leagues.filter(({ id }) => id !== leagueId);
        });

        return league;
    };
}

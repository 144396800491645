import { FC } from 'react';
import { SubsectionRowProps } from '../subsection-row';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import { MAX_MATCH_ODD_COLUMNS } from '../../../../slate/utils/ui';
import { ConnectedOdd } from '../../connected-odd/connected-odd';
import Box from '@mui/material/Box';

export const SubsectionRowMobile: FC<SubsectionRowProps> = ({ row }) => {
    return (
        <Grid container spacing={0.5}>
            <Grid item xs={3} alignContent={'center'}>
                <Typography variant={'body2'}>{row.header}</Typography>
            </Grid>
            <Grid item xs={9} container spacing={0.5}>
                {row.odds?.map((odd, index, odds) => {
                    return (
                        <Grid
                            key={index}
                            item
                            xs={
                                12 /
                                Math.min(MAX_MATCH_ODD_COLUMNS, odds.length)
                            }
                        >
                            <Box sx={{ height: '100%', minHeight: '48px' }}>
                                {odd.americanStyleOdd !== undefined && (
                                    <ConnectedOdd odd={odd} />
                                )}
                            </Box>
                        </Grid>
                    );
                })}
            </Grid>
        </Grid>
    );
};

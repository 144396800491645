import { GameLeagueScoreboardItemModel } from '../../../../common/api/api';
import { FC } from 'react';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { PlayerInfo } from './player-info';
import { Balance } from '../../../../../components/smallComponents';
import { IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

interface ScoreboardRowProps {
    game: GameLeagueScoreboardItemModel;
}

export const ScoreboardRow: FC<ScoreboardRowProps> = ({ game }) => {
    const isMobile = useIsMobile();
    const navigate = useNavigate();

    return (
        <Box display={'flex'} alignItems={'center'} p={2} gap={1}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                flex={'1 1 auto'}
                gap={{ xs: 1, sm: 2 }}
            >
                {game.players?.map((player) => (
                    <Box
                        key={player.id}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                    >
                        <PlayerInfo player={player} />
                        <Box sx={{ minWidth: { xs: 86, sm: 130 } }}>
                            <Balance
                                amount={player.score}
                                textProps={{
                                    fontWeight: 700,
                                    variant: isMobile ? 'subtitle2' : 'h5',
                                }}
                            />
                        </Box>
                    </Box>
                ))}
            </Box>
            <Box flex={'0 0 auto'}>
                <IconButton
                    color={'primary'}
                    onClick={() => navigate(`${game.gameId}`)}
                >
                    <ArrowForwardIosIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

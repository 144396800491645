import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { GameLeaguePublicModel } from '../../../../common/api/api';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { MyLeaguesStore } from '../../../stores/my-leagues-store';
import Box from '@mui/material/Box';
import { GameLeagueListItemMobile } from '../../../../common/components/game-leagues/mobile/game-league-list-item-mobile';
import { LastElementSetter } from '../../../../common/utils/types';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import ShareIcon from '@mui/icons-material/Share';

interface MyLeaguesMobileProps {
    setLastElement: LastElementSetter;
    onLeave: (league: GameLeaguePublicModel) => void;
    onPlaceBets: (league: GameLeaguePublicModel) => void;
    onClick: (league: GameLeaguePublicModel) => void;
    onShare: (league: GameLeaguePublicModel) => void;
}

export const MyLeaguesMobile: FC<MyLeaguesMobileProps> = observer(
    ({ onClick, onPlaceBets, setLastElement, onShare, onLeave }) => {
        const [{ leagues }] = useDependencies(MyLeaguesStore);

        const theme = useTheme();

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {leagues.map((league, index) => (
                    <GameLeagueListItemMobile
                        key={league.id}
                        league={league}
                        ref={
                            index === leagues.length - 1
                                ? setLastElement
                                : undefined
                        }
                        onClick={() => {
                            onClick(league);
                        }}
                        actions={
                            league.hasStarted ? (
                                <Button
                                    sx={{ boxShadow: 1 }}
                                    variant={'outlined'}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        onPlaceBets(league);
                                    }}
                                >
                                    Place Bets
                                </Button>
                            ) : (
                                <Box display={'flex'} gap={2}>
                                    <Button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onShare(league);
                                        }}
                                        startIcon={<ShareIcon />}
                                        fullWidth
                                        sx={{
                                            border: '1px solid',
                                            boxShadow: 1,
                                        }}
                                    >
                                        <Typography>Share</Typography>
                                    </Button>
                                    <Button
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onLeave(league);
                                        }}
                                        startIcon={<LogoutIcon />}
                                        fullWidth
                                        sx={{
                                            border: `1px solid ${theme.palette.error.main}`,
                                            color: theme.palette.error.main,
                                            boxShadow: 1,
                                        }}
                                    >
                                        <Typography>Leave</Typography>
                                    </Button>
                                </Box>
                            )
                        }
                    />
                ))}
            </Box>
        );
    }
);

import {
    GameFullModel,
    IdNameModel,
    ILeagueTitleModel,
    MatchModel,
    MatchWithOddsPagingModel,
    OddGroupModel,
    SportFilterModel,
} from '../api/api';
import { LoadStatus } from '../enums/load-status';
import { action, makeObservable, observable, runInAction, when } from 'mobx';
import { apiClient } from '../api/api-client';
import { injectable } from 'inversify';

@injectable()
export class GameContentBaseStore {
    gameId?: number;
    game?: GameFullModel;

    sports: SportFilterModel[] = [];
    sportLeagueId?: ILeagueTitleModel['id'];
    matchId?: MatchModel['id'];

    matchOverviews?: MatchWithOddsPagingModel;
    matchOverviewsLoadStatus = LoadStatus.None;

    sportLeaguesLoadStatus = LoadStatus.None;
    matchOddsLoadStatus = LoadStatus.None;
    matchInfo?: MatchModel;
    matchSectionId?: (typeof this.matchSections)[number]['id'];
    matchSections: IdNameModel[] = [];
    oddSubsectionGroups: OddGroupModel[] = [];

    constructor() {
        makeObservable(this, {
            game: observable,
            sports: observable,
            sportLeagueId: observable,
            matchId: observable,
            matchOverviews: observable,
            matchOverviewsLoadStatus: observable,
            sportLeaguesLoadStatus: observable,
            matchOddsLoadStatus: observable,
            matchInfo: observable,
            matchSectionId: observable,
            matchSections: observable,
            oddSubsectionGroups: observable,

            resetMatchOdds: action,
            selectSportLeague: action,
            selectMatch: action,
        });
    }

    init = async (gameId?: GameFullModel['id']) => {
        runInAction(() => {
            this.gameId = gameId;
            this.sportLeaguesLoadStatus = LoadStatus.Loading;
        });

        try {
            const [game, sports] = await Promise.all([
                gameId
                    ? apiClient.tournamentsGET2(gameId, undefined)
                    : undefined,
                apiClient.withSports(false, gameId),
            ]);

            runInAction(() => {
                this.game = game;
                this.sports = sports;
                this.sportLeaguesLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.sportLeaguesLoadStatus = LoadStatus.Error;
            });
        }
    };

    selectSportLeague = (sportLeagueId: number) => {
        this.sportLeagueId = sportLeagueId;

        this.fetchMatchOverviews();
    };

    selectMatch = (matchId?: number) => {
        this.matchId = matchId;

        if (matchId !== undefined) {
            this.fetchMatch();
        } else {
            this.resetMatchOdds();
        }
    };

    resetMatchOdds = () => {
        this.matchInfo = undefined;
        this.matchSectionId = undefined;
        this.matchSections = [];
        this.oddSubsectionGroups = [];
    };

    fetchMatchOverviews = async () => {
        runInAction(() => {
            this.matchOverviewsLoadStatus = LoadStatus.Loading;
        });

        if (this.gameId !== undefined) {
            await when(() => !!this.game);
        }

        try {
            const count = 50; // TODO: Add lazy loading

            const matchOverviews = await apiClient.withOdds(
                this.sportLeagueId,
                undefined,
                undefined,
                new Date(),
                undefined,
                undefined,
                this.game?.id,
                undefined,
                0,
                count
            );

            runInAction(() => {
                this.matchOverviews = matchOverviews;
                this.matchOverviewsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.matchOverviewsLoadStatus = LoadStatus.Error;
            });
        }
    };

    fetchMatch = async () => {
        if (!this.matchId) return;

        try {
            const [matchInfo, matchSections] = await Promise.all([
                apiClient.matches(this.matchId),
                apiClient.sections(this.matchId),
            ]);

            runInAction(() => {
                this.matchInfo = matchInfo;
                this.matchSections = matchSections;
            });

            if (matchSections.length) {
                this.selectOddSection(matchSections[0].id);
            }
        } catch {
            // skip
        }
    };

    selectOddSection = async (matchSectionId: typeof this.matchSectionId) => {
        runInAction(() => {
            this.matchSectionId = matchSectionId;
            this.matchOddsLoadStatus = LoadStatus.Loading;
        });

        try {
            const oddSubsectionGroups = await apiClient.odds(
                this.matchInfo!.id,
                this.matchSectionId
            );

            runInAction(() => {
                this.oddSubsectionGroups = oddSubsectionGroups;
                this.matchOddsLoadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.matchOddsLoadStatus = LoadStatus.Error;
            });
        }
    };
}

import { FC, Fragment } from 'react';
import { MatchOverviewsProps } from '../match-overviews';
import Grid from '@mui/material/Grid';
import { Box, Typography } from '@mui/material';
import { MatchOverviewMobile } from './match-overview-mobile';
import { ODD_COL_SIZE } from '../ui';

export const MatchOverviewsMobile: FC<MatchOverviewsProps> = ({
    matchOverviews,
    onMatchClick,
}) => {
    const columnNames = matchOverviews.columnHeaders ?? [];
    const matchOverviewItems = matchOverviews.items ?? [];

    return (
        <Fragment>
            <Box px={2}>
                <Grid container spacing={0.5}>
                    <Grid item xs />
                    {columnNames.map((columnName) => (
                        <Grid
                            item
                            xs={ODD_COL_SIZE}
                            key={columnName}
                            alignContent={'end'}
                        >
                            <Box display={'flex'} justifyContent={'center'}>
                                <Typography
                                    color={'textSecondary'}
                                    variant={'caption'}
                                    align={'center'}
                                    sx={{
                                        wordBreak: 'break-word',
                                    }}
                                >
                                    {columnName}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            {matchOverviewItems.map((matchOverview) => {
                return (
                    <Box mb={0.5} key={matchOverview.match.id}>
                        <MatchOverviewMobile
                            onMatchClick={onMatchClick}
                            matchOverview={matchOverview}
                        />
                    </Box>
                );
            })}
        </Fragment>
    );
};

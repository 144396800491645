import { injectable } from 'inversify';
import { FriendshipStatus } from '../../common/api/api';
import { PlayerFriendsBaseStore } from './player-friends-base-store';
import { apiClient } from '../../common/api/api-client';

@injectable()
export class FriendRequestsStore extends PlayerFriendsBaseStore {
    friendShipStatus = FriendshipStatus.RequestReceived;

    acceptFriendRequest = async (id: number) => {
        await apiClient.friendsPOST(id);
        this.incrementReducedPlayersCount();
    };

    denyFriendRequest = async (id: number) => {
        await apiClient.friendsDELETE(id);
        this.incrementReducedPlayersCount();
    };
}

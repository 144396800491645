import { makeAutoObservable } from 'mobx';
import { AlertProps } from '@mui/material';
import { ReactNode } from 'react';
import { injectable } from 'inversify';
import { EnqueueSnackbar } from 'notistack';

@injectable()
export class NotificationsStore {
    constructor() {
        makeAutoObservable(this);
    }

    enqueueSnackbar!: EnqueueSnackbar;

    init = (enqueueSnackbar: EnqueueSnackbar) => {
        this.enqueueSnackbar = enqueueSnackbar;
    };

    notify = ({
        severity,
        children,
    }: {
        severity: AlertProps['severity'];
        children: ReactNode;
    }) => {
        this.enqueueSnackbar({
            message: children,
            variant: severity,
        });
    };
}

import * as React from "react";
import "../Auth.css";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "../../../utils/axios";
import Grid from "@mui/material/Grid";
import { REQUIRED_FIELD } from "../../../utils/constants";
import { useIsMobile } from "../../../hooks";
import { ForgotMobile } from "./mobileForgot";

export default function Forgot() {
  const [userData, setUserData] = React.useState<{ [key: string]: any }>({});
  const [errors, setErrors] = React.useState<{ [key: string]: any }>({});
  const isMobile = useIsMobile();

  const verify = (field: string) => {
    if (!userData[field]) {
      return REQUIRED_FIELD;
    }
  };

  const handleChange = (e: React.BaseSyntheticEvent) => {
    const { name, value } = e?.target;
    if (!name) return;
    setUserData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleVerify = (field?: string) => {
    if (field) {
      setErrors((prev) => ({
        ...prev,
        [field]: verify(field),
      }));
      return;
    }

    setErrors({
      email: verify("email"),
    });
  };

  const handleFocusOut = (e: React.BaseSyntheticEvent) =>
    handleVerify(e?.target?.name);

  const handleSubmit = async (
    event: React.FormEvent | null,
    resetData?: {
      email: string;
    }
  ) => {
    event?.preventDefault();

    const { confirm, ...sendData } = userData;
    try {
      const data = await axios.post(
        "/users/password/token",
        resetData ? resetData : sendData
      );
      //@ts-ignore
      window.toastr.success(
        "Recovery email was sent. Please check your email."
      );
    } catch (error) {
      console.error(error);
    }
  };

  const hasError = Object.keys(errors).reduce(
    (acc, key) => acc || !!errors[key],
    false
  );
  const hasUserData = Object.keys(userData).reduce(
    (acc, key) => acc + errors[key],
    ""
  );

  return !isMobile ? (
    <Container component="form" onSubmit={handleSubmit}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password?
        </Typography>
        <Typography>Enter your email to recover the password.</Typography>
        <Box sx={{ mt: 1, width: { sx: "100%", sm: "40%" } }}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onBlur={handleFocusOut}
                onChange={handleChange}
                // error={!!errors.email}
                // helperText={errors.email}
                variant="standard"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={!hasUserData || hasError}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Send
          </Button>
        </Box>
      </Box>
    </Container>
  ) : (
    <ForgotMobile
      onSend={(resetData) => {
        handleSubmit(null, resetData);
      }}
    />
  );
}

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { SlateStore } from '../stores/slate-store';
import { Box, Typography, useTheme } from '@mui/material';
import { MatchOverviews } from '../../common/components/match-overviews/match-overviews';
import { SlateLeagues, SlateLeaguesProps } from './slate-leagues';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../common/api/api';
import { useIsMobile } from '../../common/hooks/use-is-mobile';

interface SlateMatchOverviewsProps {
    onClickLeague: SlateLeaguesProps['onClickLeague'];
}

export const SlateMatchOverviews: FC<SlateMatchOverviewsProps> = observer(
    ({ onClickLeague }) => {
        const [{ matchOverviewsLoadStatus, matchOverviews }] =
            useDependencies(SlateStore);
        const theme = useTheme();
        const isMobile = useIsMobile();

        const navigate = useNavigate();

        const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
            navigate(`${matchOverview.match.id}`);
        };

        return (
            <Box mb={{ xs: 2, sm: 0 }}>
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    gap={2}
                    p={{ xs: 2, sm: 0 }}
                    mb={2}
                >
                    <Typography
                        variant="h4"
                        sx={{
                            fontWeight: 900,
                            color: theme.palette.primary.main,
                        }}
                    >
                        SLATE
                    </Typography>
                    {isMobile && <SlateLeagues onClickLeague={onClickLeague} />}
                </Box>
                {!!matchOverviews && (
                    <MatchOverviews
                        loadStatus={matchOverviewsLoadStatus}
                        matchOverviews={matchOverviews}
                        onMatchClick={handleMatchClick}
                    />
                )}
            </Box>
        );
    }
);

import { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { IHistoryGamesProps } from '../history-games';
import { Box } from '@mui/system';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useDependencies } from '../../../../common/utils/ioc/use-dependencies';
import { HistoryStore } from '../../../stores/history-store';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { GameListItemMobile } from '../../../../common/components/games/mobile/game-list-item-mobile';

export const HistoryGamesMobile: FC<IHistoryGamesProps> = observer(
    ({ setLastElement }) => {
        const [{ games, showLeaderboard }] = useDependencies(HistoryStore);

        return (
            <Box display="flex" flexDirection="column" gap={2}>
                {games.map((game, index) => {
                    return (
                        <GameListItemMobile
                            ref={
                                index === games.length - 1
                                    ? setLastElement
                                    : undefined
                            }
                            key={game.id}
                            game={game}
                            actions={
                                <Button
                                    onClick={() => {
                                        showLeaderboard(game.id);
                                    }}
                                    startIcon={<LeaderboardIcon />}
                                    fullWidth
                                    sx={{
                                        border: '1px solid #FF7D00',
                                        color: '#FF7D00',
                                        boxShadow: 1,
                                    }}
                                >
                                    <Typography>Leaderboard</Typography>
                                </Button>
                            }
                        />
                    );
                })}
            </Box>
        );
    }
);

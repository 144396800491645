import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { SlateStore } from '../stores/slate-store';
import { BackButton } from '../../common/components/back-button/back-button';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { MatchOdds } from '../../common/components/match-odds/match-odds';

export const SlateMatchOdds = observer(() => {
    const [
        {
            matchInfo,
            matchSectionId,
            matchSections,
            matchOddsLoadStatus,
            selectOddSection,
            sportLeagueId,
            oddSubsectionGroups,
        },
    ] = useDependencies(SlateStore);

    const navigate = useNavigate();

    return (
        <>
            <Box m={{ xs: 2, sm: 0 }} mb={{ sm: 1 }}>
                <BackButton
                    label={'Matches'}
                    onClick={() => navigate(`../${sportLeagueId}`)}
                />
            </Box>
            {!!matchInfo && (
                <MatchOdds
                    onSelectSection={selectOddSection}
                    oddsLoadStatus={matchOddsLoadStatus}
                    oddSubsectionGroups={oddSubsectionGroups}
                    match={matchInfo}
                    matchSections={matchSections}
                    matchSectionId={matchSectionId}
                />
            )}
        </>
    );
});

import * as React from "react";
import '../Auth.css';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import axios from "../../../utils/axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { REQUIRED_FIELD } from "../../../utils/constants";
import { filterRequiredMessages } from "../../../utils";

const DefaultPassword = {
  newPassword: '',
  confirmPassword: '',
}

export default function Reset() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = React.useState<string>()
  const navigate = useNavigate()
  const [userData, setUserData] = React.useState<{ [key: string]: any }>(DefaultPassword);
  const [errors, setErrors] = React.useState<{ [key: string]: any }>({});

  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault()
    axios.patch('users/password/token', {
        newPassword: userData.newPassword,
        token,
    })
      .then(({ data }: any) => {
        setUserData(DefaultPassword);
        const { accessToken } = data;
        localStorage.setItem('token', accessToken);
        //@ts-ignore
        window.toastr.success("Password changed successfully");
        navigate('/')
      })
      .catch(console.log);
  }

  React.useEffect(() => {
    const tokenParam = searchParams.get('token');
    if (tokenParam) {
      setToken(tokenParam);
    } else {
      navigate('/')
    }
  }, [])


  const verify = (field: string) => {
    if (!userData[field]) {
      return REQUIRED_FIELD;
    }

    switch (field) {
      case 'confirmPassword':
        if (userData.confirmPassword !== userData.newPassword) {
          return "Passwords should match";
        }
        break;
      case 'newPassword':
        if (userData.newPassword?.length < 6) {
          return "Password length must be 6 or longer"
        }
        break;
    }
  }
  const handleChange = (e: React.BaseSyntheticEvent) => {
    const { name, value } = e?.target;
    if (!name) return;

    if (name.includes('Password')) {
      setUserData(prev => ({
        ...prev,
        [name]: value,
      }))
      return;
    }
    setUserData(prev => ({
      ...prev,
      [name]: value,
    }))
  }

  const handleVerify = (field?: string) => {
    if (field) {
      setErrors(prev => ({
        ...prev,
        [field]: verify(field),
      }));
      return;
    }
  }

  const handleFocusOut = (e: React.BaseSyntheticEvent) => handleVerify(e?.target?.name);


  const hasError = Object.keys(errors).reduce((acc, key) => acc || !!errors[key], false);
  const hasUserData = Object.keys(userData).reduce((acc, key) => acc + errors[key], '');
  const visibleErrors = React.useMemo(() => filterRequiredMessages(errors), [errors]);

  return (
    <Container component="form" onSubmit={handleSubmit}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Password Reset
        </Typography>
        <Typography>
          Enter your new password.
        </Typography>
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} >
              <TextField
                required
                label="New Password"
                name="newPassword"
                fullWidth
                type="password"
                autoComplete="newPassword"
                variant="standard"
                onBlur={handleFocusOut}
                onChange={handleChange}
                value={userData.newPassword}
                error={!!visibleErrors.newPassword}
                helperText={visibleErrors.newPassword}
              /></Grid>
            <Grid item xs={12} >

              <TextField
                required
                label="Confirm Password"
                name="confirmPassword"
                fullWidth
                type="password"
                autoComplete="confirmPassword"
                variant="standard"
                onBlur={handleFocusOut}
                onChange={handleChange}
                value={userData.confirmPassword}
                error={!!visibleErrors.confirmPassword}
                helperText={visibleErrors.confirmPassword}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            disabled={!hasUserData || hasError}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Reset
          </Button>

        </Box>
      </Box>
    </Container>
  );
}

import { FC } from 'react';
import { PlayerInfoModel } from '../../common/api/api';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

export const Participant: FC<{ participant: PlayerInfoModel }> = ({
    participant,
}) => {
    const { avatarUrl, username } = participant;

    return (
        <Card sx={{ width: '100%', px: 4, py: 2 }}>
            <Box display="flex" alignItems={'center'} gap={1.5}>
                <Avatar sx={{ width: 48, height: 48 }} src={avatarUrl} />
                <Typography variant={'body1'}>{username}</Typography>
            </Box>
        </Card>
    );
};

import { Box } from '@mui/material';
import { FC, ReactNode } from 'react';
import Card from '@mui/material/Card';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { MobileBetSlipContainer } from '../mobile-bet-slip-container/mobile-bet-slip-container';

interface ICommonSportLayout {
    leagues?: ReactNode;
    content: ReactNode;
    betSlip: ReactNode;
    actions?: ReactNode;
}

export const CommonSportLayout: FC<ICommonSportLayout> = ({
    leagues,
    betSlip,
    content,
    actions,
}) => {
    const isMobile = useIsMobile();

    return isMobile ? (
        <>
            <Box>{content}</Box>
            <MobileBetSlipContainer>{betSlip}</MobileBetSlipContainer>
        </>
    ) : (
        <Box
            height={'100%'}
            width={'100%'}
            display={'flex'}
            alignItems={'stretch'}
            gap={2}
        >
            <Box
                sx={{
                    flex: '0 0 238px',
                    background: '#ffffff',
                    boxShadow:
                        '0px 1px 2px 0px rgba(0, 0, 0, 0.24), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
                }}
            >
                <Box
                    sx={{
                        p: '24px 32px 24px 32px',
                        position: 'sticky',
                        top: 0,
                        flex: '0 0 auto',
                    }}
                >
                    {leagues}
                </Box>
            </Box>
            <Box sx={{ flex: '1 1 auto' }} py={3}>
                {content}
            </Box>
            <Box
                sx={{
                    my: 3,
                    mr: 2,
                    flex: '0 0 auto',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                }}
            >
                {actions}
                <Box
                    sx={{
                        position: 'sticky',
                        top: '24px',
                        // maxHeight: `calc(100vh - ${122 + (actions ? 52 : 0)}px)`,
                    }}
                >
                    <Card
                        sx={{
                            maxHeight: '100%',
                            width: '340px',
                            boxShadow:
                                '0px 6px 6px 0px rgba(0, 0, 0, 0.23), 0px 10px 20px 0px rgba(0, 0, 0, 0.19)',
                        }}
                    >
                        {betSlip}
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};

import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { LoadStatus } from '../../enums/load-status';
import { EmptyList } from '../../../../components/EmptyList';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { LastElementSetter } from '../../utils/types';

interface AsyncListProps {
    length: number;
    loadStatus: LoadStatus;
    onFetch: () => void;
    children: (setLastElement: LastElementSetter) => ReactNode;
    prefetch?: boolean;
}

export const AsyncList: FC<AsyncListProps> = ({
    children,
    length,
    loadStatus,
    onFetch,
    prefetch,
}) => {
    const [lastElement, setLastElement] = useState<HTMLElement | null>(null);

    const intersectionObserver = useMemo(() => {
        return new IntersectionObserver((entries) => {
            const lastElementEntry = entries[0];

            if (lastElementEntry.isIntersecting) {
                onFetch();
            }
        });
    }, [onFetch]);

    useEffect(() => {
        if (prefetch) {
            onFetch();
        }
    }, []);

    useEffect(() => {
        if (lastElement) {
            intersectionObserver.observe(lastElement);
        }

        return () => {
            intersectionObserver.disconnect();
        };
    }, [lastElement, intersectionObserver]);

    return (
        <Box display={'flex'} flexDirection={'column'} width={'100%'}>
            {children(setLastElement)}
            {!length && loadStatus === LoadStatus.Ok && <EmptyList />}
            {loadStatus === LoadStatus.Loading && (
                <Box display={'flex'} justifyContent={'center'} pt={3}>
                    <CircularProgress />
                </Box>
            )}
        </Box>
    );
};

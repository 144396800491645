import { Chip, ChipProps } from '@mui/material'
import { TournamentStatusEnum } from '../../../common/api/api'
import { FC } from 'react'

interface iStatusChipProps {
    status: TournamentStatusEnum
}

const mapStatusToProps: Record<TournamentStatusEnum, ChipProps['color']> = {
    [TournamentStatusEnum.Completed]: 'success',
    [TournamentStatusEnum.Cancelled]: 'error',
    [TournamentStatusEnum.Active]: 'primary',
}

export const StatusChip: FC<iStatusChipProps> = ({ status }) => {
    return (
        <Chip size={'small'} color={mapStatusToProps[status]} label={status} />
    )
}

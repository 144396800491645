import { GameLeaguePublicModel } from '../../../api/api';
import { forwardRef, ReactElement } from 'react';
import Card, { CardProps } from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Chip } from '@mui/material';
import { SportLeagueIconList } from '../../sport-league-icon-list/sport-league-icon-list';

interface GameLeagueListItemMobileProps {
    league: GameLeaguePublicModel;
    actions?: ReactElement;
    onClick?: CardProps['onClick'];
}

export const GameLeagueListItemMobile = forwardRef<
    HTMLDivElement,
    GameLeagueListItemMobileProps
>(({ actions, onClick, league }, ref) => {
    return (
        <Card
            ref={ref}
            sx={{ boxShadow: 2, padding: '20px' }}
            onClick={onClick}
        >
            <Box mb={0.5}>
                <SportLeagueIconList leagues={league.leagues} />
            </Box>
            <Typography
                mb={0.5}
                variant={'subtitle2'}
                fontWeight={900}
                fontSize={18}
            >
                {league.name}
            </Typography>
            <Typography variant={'body1'} mb={1}>
                {league.currentPlayerCount}/{league.maxPlayerCount} Participants
            </Typography>
            <Box
                display={'flex'}
                flexDirection={'column'}
                gap={1}
                alignItems={'start'}
            >
                <Typography color={'textSecondary'} variant={'caption'}>
                    Created by
                </Typography>
                <Chip
                    sx={{ border: 'none' }}
                    variant={'outlined'}
                    avatar={<Avatar src={league.createdByPlayer.avatarUrl} />}
                    label={league.createdByPlayer.username}
                />
            </Box>
            {!!actions && <Box mt={2}>{actions}</Box>}
        </Card>
    );
});

import { Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { GameContentStore } from '../../stores/game-content-store';
import Button from '@mui/material/Button';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const GameContentActions = observer(() => {
    const [{ showLeaderboard, showBetHistory }] =
        useDependencies(GameContentStore);

    return (
        <Box display={'flex'} gap={2} width={'100%'}>
            <Button
                fullWidth
                size={'large'}
                onClick={showBetHistory}
                sx={{ background: 'white', boxShadow: 1 }}
            >
                <Typography fontWeight={700}>Bet History</Typography>
            </Button>
            <Button
                fullWidth
                size={'large'}
                endIcon={<LeaderboardIcon />}
                onClick={showLeaderboard}
                sx={{ background: 'white', color: '#FF7D00', boxShadow: 1 }}
            >
                <Typography fontWeight={700}>Leaderboard</Typography>
            </Button>
        </Box>
    );
});

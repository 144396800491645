import { FC, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { MatchWithOddsPagingModel } from '../../api/api';
import { useIsMobile } from '../../hooks/use-is-mobile';
import { LoadStatus } from '../../enums/load-status';
import { Box, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment/moment';
import { EmptyList } from '../../../../components/EmptyList';
import { MatchOverview } from './match-overview';
import { MatchOverviewsMobile } from './mobile/match-overviews-mobile';
import { DATE_COL_SIZE, ODD_COL_SIZE } from './ui';
import { MatchOverviewRowProps } from './match-overview-row';

export interface MatchOverviewsProps {
    matchOverviews: MatchWithOddsPagingModel;
    loadStatus: LoadStatus;
    onMatchClick: MatchOverviewRowProps['onMatchClick'];
}

export const MatchOverviews: FC<MatchOverviewsProps> = observer((props) => {
    const { matchOverviews, loadStatus, onMatchClick } = props;

    const isMobile = useIsMobile();

    if (loadStatus === LoadStatus.Loading) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress sx={{ mt: 10 }} />
            </Box>
        );
    }

    const columnNames = matchOverviews.columnHeaders ?? [];
    const matchOverviewItems = matchOverviews.items ?? [];

    return (
        <Fragment>
            {isMobile ? (
                <MatchOverviewsMobile {...props} />
            ) : (
                <Fragment>
                    <Grid
                        container
                        sx={{
                            '& .MuiTypography-root': {
                                fontWeight: 600,
                                color: '#adadae',
                                fontSize: '14px',
                            },
                        }}
                        spacing={2}
                    >
                        <Grid item xs={DATE_COL_SIZE} />
                        <Grid item xs container>
                            <Grid item xs alignContent={'end'}>
                                <Typography>Teams</Typography>
                            </Grid>
                            {columnNames.map((columnName) => (
                                <Grid
                                    item
                                    xs={ODD_COL_SIZE}
                                    key={columnName}
                                    alignContent={'end'}
                                >
                                    <Typography
                                        sx={{
                                            textAlign: 'center',
                                            wordBreak: 'break-word',
                                        }}
                                    >
                                        {columnName}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    {matchOverviewItems.map((matchOverview, index) => {
                        const prevMatchOverview = matchOverviewItems[index - 1];
                        const isFirstMatchOfDay = prevMatchOverview
                            ? !moment(matchOverview.match.startDate).isSame(
                                  prevMatchOverview.match.startDate,
                                  'day'
                              )
                            : true;

                        return (
                            <Box
                                mt={
                                    index === 0
                                        ? 0
                                        : isFirstMatchOfDay
                                          ? 4
                                          : 0.5
                                }
                                key={matchOverview.match.id}
                            >
                                <MatchOverview
                                    onMatchClick={onMatchClick}
                                    matchOverview={matchOverview}
                                    isFirstMatchOfDay={isFirstMatchOfDay}
                                />
                            </Box>
                        );
                    })}
                </Fragment>
            )}
            {loadStatus === LoadStatus.Ok && matchOverviewItems.length === 0 ? (
                <Box my={5}>
                    <EmptyList />
                </Box>
            ) : null}
        </Fragment>
    );
});

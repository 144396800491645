import type { SVGProps } from "react";

const BalanceBetIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="104"
    height="50"
    viewBox="0 0 104 50"
    fill="none"
  >
    <path
      d="M13.7831 0V5.72446L12.6351 6.84681L13.7831 7.96916V13.75H0V0H13.7831ZM5.91189 4.96605H7.87848V3.81552H5.91189V4.96605ZM5.91189 9.9321H7.87848V8.78158H5.91189V9.9321Z"
      fill="white"
    />
    <path
      d="M28.8192 0V13.7476H22.9156V11.456H20.9494V13.7476H15.0361V0H28.8192ZM20.9494 7.64044H22.9156V3.81552H20.9494V7.64044Z"
      fill="white"
    />
    <path
      d="M36.7451 0V9.9321H43.8553V13.7476H30.0722V0H36.7478H36.7451Z"
      fill="white"
    />
    <path
      d="M58.8914 0V13.7476H52.9878V11.456H51.0216V13.7476H45.1083V0H58.8914ZM51.0216 7.64044H52.9878V3.81552H51.0216V7.64044Z"
      fill="white"
    />
    <path
      d="M73.9275 0V13.7476H68.564L65.5079 7.25772V13.7476H60.1444V0H65.5079L68.564 6.48991V0H73.9275Z"
      fill="white"
    />
    <path
      d="M88.9637 0V5.34878H83.0601V3.81552H81.0938V9.9321H83.0601V8.39885H88.9637V13.7476H75.1805V0H88.9637Z"
      fill="white"
    />
    <path
      d="M104 0V3.81552H96.1299V4.96605H104V8.78158H96.1299V9.9321H104V13.7476H90.2167V0H104Z"
      fill="white"
    />
    <path
      d="M32.7857 17.4999V31.0286L30.0568 33.6791L32.7857 36.3297V49.9951H0V17.4999H32.7857Z"
      fill="white"
    />
    <path
      d="M68.3929 17.5024V26.5223H49.6725V29.2413H68.3929V38.2612H49.6725V40.9801H68.3929V50H35.6072V17.5024H68.3929Z"
      fill="white"
    />
    <path
      d="M104 17.5023V26.5222H94.6386V49.9999H80.5732V26.5199H71.2118V17.5H103.998L104 17.5023Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.1229 26.9937C7.13648 27.3444 7.24635 27.3873 7.24635 27.3873C7.24635 27.3873 8.474 28.1316 8.84659 28.7686C10.0527 28.2199 11.61 29.4461 11.61 29.4461L22.7066 40.0477L27.0942 37.748C27.0942 37.748 20.4902 30.312 18.386 28.0219C16.2818 25.7317 14.5263 26.1158 13.1458 26.6859C12.4221 26.9937 11.2493 27.5853 10.294 28.0887M15.2691 31.7792C14.8105 31.1685 12.5725 28.9761 12.5725 28.9761L16.6329 27.0485C17.2586 27.4541 19.4082 29.8636 19.4082 29.8636L15.2715 31.7649V31.7792H15.2691ZM6.91436 30.6293C7.1126 30.8297 7.26545 30.9586 7.37532 31.0683C7.30367 31.4571 7.28934 31.8555 7.33233 32.2492C7.36099 33.2296 7.59983 34.1934 8.03214 35.0761C9.28367 37.748 11.7844 39.1937 13.6258 38.3396C13.7644 38.2657 13.8981 38.1774 14.0199 38.0772C16.609 37.3973 20.8389 41.2524 20.8389 41.2524L22.5729 40.2028L11.4548 29.5916C11.3664 29.5343 10.38 28.7471 9.2168 28.8759C9.05916 28.8998 8.9063 28.9451 8.75822 29.0071C8.34025 28.437 7.81002 27.9598 7.19858 27.6068C6.3889 27.1463 5.72014 29.4365 6.91436 30.6293ZM13.4491 36.653C12.3767 37.4856 10.5328 36.9393 9.32667 35.4053C8.12051 33.8714 8.05841 31.9653 9.13082 31.1112C10.2032 30.2572 12.059 30.8059 13.2532 32.3374C14.4594 33.8714 14.5215 35.8204 13.4491 36.653ZM7.04094 29.7323C6.99795 29.7323 6.27426 29.3578 7.06483 29.3578H7.07199C7.19619 29.3506 7.28934 29.2433 7.28218 29.1192C7.28218 29.1002 7.28218 29.0811 7.28218 29.062C7.26068 28.9523 7.15559 28.8783 7.04572 28.8998C6.93585 28.8998 6.82599 28.8831 6.71851 28.8544C6.78299 28.6588 6.91436 28.5491 7.04572 28.5491C7.26546 28.5491 7.43981 28.8139 7.43981 29.1407C7.43981 29.4675 7.26546 29.7323 7.04572 29.7323H7.04094Z"
      fill="#2B5FD9"
    />
  </svg>
);

export default BalanceBetIcon;

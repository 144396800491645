import Avatar from '@mui/material/Avatar';
import { ColoredBorder } from '../../../common/components/colored-border/colored-border';
import { FC } from 'react';

export enum UserAvatarType {
    Selected,
    Available,
}

interface UserAvatarProps {
    active?: boolean;
    src?: string;
    type: UserAvatarType;
    onClick?: () => void;
}

export const UserAvatar: FC<UserAvatarProps> = ({
    active,
    src,
    type,
    onClick,
}) => {
    const size = type === UserAvatarType.Selected ? 80 : 56;

    return (
        <ColoredBorder
            active={active}
            sx={{
                borderRadius: 100,
                boxShadow: 3,
                cursor: onClick ? 'pointer' : 'default',
            }}
            borderSize={'2px'}
            onClick={onClick}
        >
            <Avatar sx={{ width: size, height: size }} src={src} />
        </ColoredBorder>
    );
};

import { FC, PropsWithChildren } from 'react';
import { ButtonProps, IconButton } from '@mui/material';

interface CustomIconButton extends PropsWithChildren {
    color: string;
    onClick: ButtonProps['onClick'];
}

export const CustomIconButton: FC<CustomIconButton> = ({
    children,
    color,
    onClick,
}) => {
    return (
        <IconButton
            size={'small'}
            onClick={onClick}
            sx={{
                borderRadius: '8px',
                border: `1px solid ${color}`,
                boxShadow: 1,
                color,
            }}
        >
            {children}
        </IconButton>
    );
};

export const SecondRankIcon = () => (
    <svg
        width="20"
        height="39"
        viewBox="0 0 20 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.99903 35.3L6.29903 38.1C5.89902 38.4 5.49902 38.4083 5.09902 38.125C4.69902 37.8417 4.58236 37.4667 4.74902 37L6.19903 32.4L2.54902 29.8C2.14902 29.5333 2.03236 29.1667 2.19902 28.7C2.36569 28.2333 2.68236 28 3.14902 28H7.59903L8.99903 23.4L1.89902 19.2C1.29902 18.8333 0.832357 18.35 0.499024 17.75C0.16569 17.15 -0.000976562 16.4667 -0.000976562 15.7V4C-0.000976562 2.9 0.39069 1.95833 1.17402 1.175C1.95736 0.391667 2.89902 0 3.99902 0H15.999C17.099 0 18.0407 0.391667 18.824 1.175C19.6074 1.95833 19.999 2.9 19.999 4V15.7C19.999 16.4667 19.8324 17.15 19.499 17.75C19.1657 18.35 18.699 18.8333 18.099 19.2L10.999 23.4L12.399 28H16.849C17.3157 28 17.6324 28.2333 17.799 28.7C17.9657 29.1667 17.849 29.5333 17.449 29.8L13.799 32.4L15.249 37C15.4157 37.4667 15.299 37.8417 14.899 38.125C14.499 38.4083 14.099 38.4 13.699 38.1L9.99903 35.3ZM7.99903 4V18.1L9.99903 19.3L11.999 18.1V4H7.99903Z"
            fill="url(#paint0_linear_1996_3992)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_1996_3992"
                x1="9.99903"
                y1="0"
                x2="9.99903"
                y2="38.3314"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#8C8C94" />
                <stop offset="1" stopColor="#484A4E" />
            </linearGradient>
        </defs>
    </svg>
);

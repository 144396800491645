export const TOOLBAR_HEIGHT = 72;
export const TRIAL_BANNER_HEIGHT = 40;

export const CLICKABLE_TABLE_ROW_SX = {
    cursor: 'pointer',
    '&:hover': {
        background: '#eeeeee !important',
    },
};

export const getAppBarHeight = (isSubscriptionInfoShown: boolean) => {
    return TOOLBAR_HEIGHT + (isSubscriptionInfoShown ? TRIAL_BANNER_HEIGHT : 0);
};

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Box from '@mui/material/Box';
import { GamePlayer } from '../../../../common/components/game-player/game-player';
import { Bet } from '../../../../common/components/bet/bet';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { GameMatchupMobile } from './mobile/game-matchup-mobile';
import { PlayerWithBets } from '../../../utils/types';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

export interface GameMatchupProps {
    playersWithBets: PlayerWithBets[];
}

export const GameMatchup: FC<GameMatchupProps> = observer(
    ({ playersWithBets }) => {
        const isMobile = useIsMobile();

        if (isMobile) {
            return <GameMatchupMobile playersWithBets={playersWithBets} />;
        }

        return (
            <Box
                display={'flex'}
                gap={10}
                justifyContent={'space-between'}
                px={4}
            >
                {playersWithBets.map(({ player, bets }) => (
                    <Box
                        key={player.id}
                        flex={'1 1 0'}
                        display={'flex'}
                        flexDirection={'column'}
                        gap={1}
                    >
                        <GamePlayer player={player} centered />
                        {bets.map((bet, index) => {
                            return (
                                <Bet key={`${player.id}-${index}`} bet={bet} />
                            );
                        })}
                    </Box>
                ))}
                {playersWithBets.length === 1 && (
                    <Box flex={'1 1 0'}>
                        <Card>
                            <Box
                                height={76}
                                display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Typography variant={'h4'}>BYE</Typography>
                            </Box>
                        </Card>
                    </Box>
                )}
            </Box>
        );
    }
);

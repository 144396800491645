import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ActiveLeague from '../../../../icons/ActiveLeague';
import { ISportFilterModel } from '../../api/api';
import { LeaguesMobile } from './mobile/leagues-mobile';
import { useIsMobile } from '../../hooks/use-is-mobile';

export interface ILeaguesProps {
    sports: ISportFilterModel[];
    selectedLeagueId?: number;
    onChangeLeagueId: (leagueId: number) => void;
}

export const Leagues: FC<ILeaguesProps> = (props) => {
    const { sports, selectedLeagueId, onChangeLeagueId } = props;
    const isMobile = useIsMobile();

    if (isMobile) {
        return <LeaguesMobile {...props} />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px',
            }}
        >
            {sports.map((sport) => {
                return (
                    <Box key={sport.id}>
                        <Typography
                            sx={{
                                color: '#2B5FD9',
                            }}
                            mb={1}
                        >
                            {sport.name}
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '8px',
                            }}
                        >
                            {sport.leagues?.map((league) => {
                                return (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            position: 'relative',
                                        }}
                                        key={league.id}
                                    >
                                        {league.id === selectedLeagueId ? (
                                            <Box
                                                sx={{
                                                    position: 'absolute',
                                                    left: '-16px',
                                                }}
                                            >
                                                <ActiveLeague />
                                            </Box>
                                        ) : null}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                gap: '12px',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                onChangeLeagueId(league.id);
                                            }}
                                        >
                                            <Box
                                                display={'flex'}
                                                alignItems={'center'}
                                                justifyContent={'center'}
                                                sx={{ width: 24, height: 24 }}
                                            >
                                                <img
                                                    src={league.logoUrl!}
                                                    alt="icon"
                                                    style={{
                                                        maxWidth: '24px',
                                                        maxHeight: '24px',
                                                    }}
                                                />
                                            </Box>
                                            <Typography
                                                fontSize={18}
                                                style={{
                                                    fontWeight: 800,
                                                    color: '#2B5FD9',
                                                }}
                                            >
                                                {league.name}
                                            </Typography>
                                        </Box>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

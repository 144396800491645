import { Box, Typography, useTheme } from '@mui/material';
import BalanceBetIcon from '../../icons/BalanceBet';
import FbIcon from '../../icons/Fb';
import InstaIcon from '../../icons/insta';
import XIcon from '../../icons/X';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../modules/common/utils/ioc/use-dependencies';
import { AppStore } from '../../modules/common/stores/app-store';

export const Footer = observer(() => {
    const [{ isMobileBetSlipVisible }] = useDependencies(AppStore);

    const currentYear = new Date().getFullYear();

    return (
        <Box
            sx={{
                backgroundColor: '#2B5FD9',
                pb: isMobileBetSlipVisible ? 10 : 0,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    p: '24px 36px',
                }}
            >
                <Box>
                    <BalanceBetIcon />
                </Box>
                <Box>
                    <Typography variant="body2" color={'#FFFFFF'} mb={1}>
                        Our Socials
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                        }}
                    >
                        {/*<a*/}
                        {/*    href="https://www.facebook.com/"*/}
                        {/*    target="_blank"*/}
                        {/*    rel="noopener noreferrer"*/}
                        {/*>*/}
                        {/*    <FbIcon />*/}
                        {/*</a>*/}
                        <a
                            href="https://www.instagram.com/balancebet/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <InstaIcon />
                        </a>
                        <a
                            href="https://twitter.com/balancebett"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <XIcon />
                        </a>
                    </Box>
                </Box>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    background: '#FFF',
                    textAlign: 'center',
                }}
            >
                <Link
                    target={'_blank'}
                    component={'a'}
                    href={'https://balancebet.com/privacy-policy'}
                    underline={'hover'}
                    color={'#1D1D20'}
                >
                    Privacy Policy
                </Link>
                <Link
                    target={'_blank'}
                    component={'a'}
                    href={'https://balancebet.com/terms-conditions'}
                    underline={'hover'}
                    color={'#1D1D20'}
                >
                    Terms & Conditions
                </Link>
                <Link
                    underline={'hover'}
                    component={RouterLink}
                    to={'/contacts'}
                    color={'#1D1D20'}
                >
                    Feedback
                </Link>
                <Link
                    underline={'hover'}
                    component={RouterLink}
                    to={'/contacts'}
                    color={'#1D1D20'}
                >
                    Contact Us
                </Link>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    background: '#2B5FD9',
                }}
            >
                <Typography color={'#FFF'}>
                    Copyright © {currentYear} Sports Betting - All Rights
                    Reserved.
                </Typography>
            </Box>
        </Box>
    );
});

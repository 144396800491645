import { MatchOddModel } from '../../common/api/api';

export const toAmerican = (coefficient: number): number => {
    if (coefficient <= 1) {
        throw new Error('Coefficient must be greater than 1.');
    }

    const value =
        coefficient >= 2 ? (coefficient - 1) * 100 : -100 / (coefficient - 1);

    return Math.round(value);
};

export const calculateTotalCoefficient = (odds: MatchOddModel[]): number => {
    const totalCoefficient = odds.reduce(
        (total, odd) => total * (odd.coefficient ?? 1),
        1
    );

    return totalCoefficient;
};

import { FC, forwardRef, Fragment, PropsWithChildren, useEffect } from 'react';
import { NotificationsStore } from '../../stores/notifications-store';
import { Alert, AlertProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import { provide } from '../../utils/ioc/provide';
import {
    CustomContentProps,
    SnackbarContent,
    SnackbarProvider,
    useSnackbar,
} from 'notistack';

const NotificationsUnwrapped: FC<PropsWithChildren> = provide([
    NotificationsStore,
])(
    observer(({ children }) => {
        const [{ init }] = useDependencies(NotificationsStore);
        const { enqueueSnackbar } = useSnackbar();

        useEffect(() => {
            init(enqueueSnackbar);
        }, []);

        return <Fragment>{children}</Fragment>;
    })
);

const CustomSnackbarNotification = forwardRef<
    HTMLDivElement,
    CustomContentProps
>((props, ref) => {
    const { closeSnackbar } = useSnackbar();

    const { id, message, variant, ...other } = props;

    return (
        <SnackbarContent key={id} ref={ref} role="alert" {...other}>
            <Alert
                sx={{ minWidth: 300 }}
                onClose={() => closeSnackbar(id)}
                elevation={4}
                severity={variant as AlertProps['severity']}
            >
                {message}
            </Alert>
        </SnackbarContent>
    );
});

CustomSnackbarNotification.displayName = 'CustomSnackbarNotification';

export const Notifications: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            autoHideDuration={5000}
            Components={{
                success: CustomSnackbarNotification,
                warning: CustomSnackbarNotification,
                error: CustomSnackbarNotification,
                info: CustomSnackbarNotification,
                default: CustomSnackbarNotification,
            }}
        >
            <NotificationsUnwrapped>{children}</NotificationsUnwrapped>
        </SnackbarProvider>
    );
};

import Box from '@mui/material/Box';
import { Divider, Typography, useTheme } from '@mui/material';
import { FC, Fragment } from 'react';
import { OddGroupModel } from '../../api/api';
import { Subsection } from './subsection';

interface SubsectionGroupProps {
    subsectionGroup: OddGroupModel;
}

export const SubsectionGroup: FC<SubsectionGroupProps> = ({
    subsectionGroup,
}) => {
    const theme = useTheme();

    const { name, subsections } = subsectionGroup;

    return (
        <Box>
            {!!name && (
                <Box mx={{ xs: 2, sm: 0 }}>
                    <Typography
                        variant={'h4'}
                        color={theme.palette.grey['700']}
                        fontWeight={900}
                    >
                        {name}
                    </Typography>
                    <Divider sx={{ mb: 2, mt: 1 }} />
                </Box>
            )}
            {subsections?.map((subsection, index) => {
                return (
                    <Box key={index} mb={2}>
                        <Subsection subsection={subsection} />
                    </Box>
                );
            })}
        </Box>
    );
};

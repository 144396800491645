import { FC, PropsWithChildren, useEffect } from 'react';
import Box from '@mui/material/Box';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import { AppStore } from '../../stores/app-store';

export const MobileBetSlipContainer: FC<PropsWithChildren> = observer(
    ({ children }) => {
        const [{ setIsMobileBetSlipVisible }] = useDependencies(AppStore);

        useEffect(() => {
            setIsMobileBetSlipVisible(true);

            return () => setIsMobileBetSlipVisible(false);
        }, []);

        return (
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 0,
                    width: '100%',
                    zIndex: 1200, // to be lower than modals
                    boxShadow: 'rgba(0, 0, 0, 0.23) 0px -2px 6px 0px',
                }}
            >
                {children}
            </Box>
        );
    }
);

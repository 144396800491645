import { injectable } from 'inversify';
import { makeAutoObservable, runInAction } from 'mobx';
import { BetGetModel, BetStateEnum } from '../../common/api/api';
import { apiClient } from '../../common/api/api-client';

@injectable()
export class BetHistoryStore {
    allBets: BetGetModel[] = [];
    selectedBetState = BetStateEnum.All;

    constructor() {
        makeAutoObservable(this);
    }

    init = async (gameId: number) => {
        try {
            const allBets = await apiClient.getBets(
                gameId,
                undefined,
                BetStateEnum.All,
                undefined
            );

            runInAction(() => {
                this.allBets = allBets;
            });
        } catch {
            // skip
        }
    };

    selectBetState = (betState: BetStateEnum) => {
        this.selectedBetState = betState;
    };

    get openBets() {
        return this.allBets.filter((bet) => bet.state === BetStateEnum.Open);
    }

    get closedBets() {
        return this.allBets.filter((bet) => bet.state === BetStateEnum.Closed);
    }

    get betsToShow() {
        switch (this.selectedBetState) {
            case BetStateEnum.All: {
                return this.allBets;
            }
            case BetStateEnum.Open: {
                return this.openBets;
            }
            case BetStateEnum.Closed: {
                return this.closedBets;
            }
            default: {
                return this.allBets;
            }
        }
    }
}

import { FC } from 'react';
import { MatchOverviewRowProps } from '../match-overview-row';
import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ConnectedOdd } from '../../connected-odd/connected-odd';
import { ODD_COL_SIZE } from '../ui';
import { TeamInfo } from '../team-info';

export const MatchOverviewRowMobile: FC<MatchOverviewRowProps> = ({
    matchOverview,
    currentOdds,
    currentTeam,
    onMatchClick,
}) => {
    const { teams } = matchOverview.match;

    return (
        <Grid container spacing={0.5}>
            <Grid
                item
                xs
                zeroMinWidth
                onClick={() => onMatchClick(matchOverview)}
                alignContent={'center'}
            >
                {currentTeam ? (
                    <TeamInfo team={currentTeam} />
                ) : (
                    <Box display={'flex'} flexDirection={'column'}>
                        <TeamInfo team={teams![0]} />
                        <TeamInfo team={teams![1]} />
                    </Box>
                )}
            </Grid>
            {currentOdds.map((odd, index) => {
                return (
                    <Grid key={index} item xs={ODD_COL_SIZE}>
                        {/* odds may be null */}
                        {odd.americanStyleOdd !== undefined && (
                            <ConnectedOdd odd={odd} />
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

import {
    ChangeEvent,
    ChangeEventHandler,
    FC,
    useCallback,
    useMemo,
} from 'react';
import TextField from '@mui/material/TextField';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import debounce from 'lodash.debounce';
import { DEBOUNCE_DELAY } from '../../common/utils/ux';

interface SearchLeaguesProps {
    onSearch: (term: string) => void;
}

export const SearchLeagues: FC<SearchLeaguesProps> = ({ onSearch }) => {
    const handleDebouncedSearch = useMemo(
        () =>
            debounce((event: ChangeEvent<HTMLInputElement>) => {
                onSearch(event.target.value);
            }, DEBOUNCE_DELAY),
        [onSearch]
    );

    return (
        <TextField
            margin={'none'}
            onChange={handleDebouncedSearch}
            size={'small'}
            label="Search"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
        />
    );
};

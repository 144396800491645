import { ConferencePublicModel } from '../../../../common/api/api';
import { FC } from 'react';
import Table from '@mui/material/Table';
import { TableHead } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import TableContainer from '@mui/material/TableContainer';
import { CustomTable } from '../../../../common/components/custom-table/custom-table';
import { useIsMobile } from '../../../../common/hooks/use-is-mobile';
import { PlayerInfo } from './player-info';
import { StandingsTypographyCell } from './standings-typography-cell';

interface StandingsTableProps {
    conference: ConferencePublicModel;
}

export const StandingsTable: FC<StandingsTableProps> = ({ conference }) => {
    const isMobile = useIsMobile();
    const pointCellSx = { textAlign: 'center' };

    return (
        <CustomTable>
            <TableHead>
                <TableCell>
                    <Typography
                        variant={isMobile ? 'subtitle1' : 'h5'}
                        fontWeight={700}
                        color={'textPrimary'}
                    >
                        {conference.name}
                    </Typography>
                </TableCell>
                <TableCell sx={pointCellSx}>W</TableCell>
                <TableCell sx={pointCellSx}>T</TableCell>
                <TableCell sx={pointCellSx}>L</TableCell>
                <TableCell sx={pointCellSx}>PT</TableCell>
            </TableHead>
            <TableBody>
                {conference.players?.map((player, index) => {
                    return (
                        <TableRow key={player.id}>
                            <TableCell>
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    gap={2}
                                >
                                    <Typography
                                        variant={'h5'}
                                        fontWeight={700}
                                        align={'center'}
                                        sx={{ minWidth: 26 }}
                                    >
                                        {index + 1}
                                    </Typography>
                                    <PlayerInfo player={player} />
                                </Box>
                            </TableCell>
                            <TableCell>
                                <StandingsTypographyCell sx={pointCellSx}>
                                    {player.stats.winCount}
                                </StandingsTypographyCell>
                            </TableCell>
                            <TableCell>
                                <StandingsTypographyCell sx={pointCellSx}>
                                    {player.stats.tieCount}
                                </StandingsTypographyCell>
                            </TableCell>
                            <TableCell>
                                <StandingsTypographyCell sx={pointCellSx}>
                                    {player.stats.loseCount}
                                </StandingsTypographyCell>
                            </TableCell>
                            <TableCell>
                                <StandingsTypographyCell sx={pointCellSx}>
                                    {player.stats.points}
                                </StandingsTypographyCell>
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </CustomTable>
    );
};

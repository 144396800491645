import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { provide } from '../../common/utils/ioc/provide';
import { InvitePlayersStore } from '../stores/invite-players-store';
import Dialog from '@mui/material/Dialog';
import { useIsMobile } from '../../common/hooks/use-is-mobile';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import { PlayerUsernameModel } from '../../common/api/api';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { LoadStatus } from '../../common/enums/load-status';
import Button from '@mui/material/Button';
import ShareIcon from '@mui/icons-material/Share';
import { doesSupportSharing } from '../utils/does-support-sharing';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { ShareLinkGetter } from '../utils/types';
import { noop } from '../../common/utils/noop';
import { NotificationsStore } from '../../common/stores/notifications-store';
import DoneAllIcon from '@mui/icons-material/DoneAll';

interface InvitationProps {
    onInvite: (usernames: PlayerUsernameModel[]) => Promise<void>;
    onClose: () => void;
    getShareLink: ShareLinkGetter;
}

export const InvitePlayers: FC<InvitationProps> = provide([InvitePlayersStore])(
    observer(({ onClose, onInvite, getShareLink }) => {
        const [
            {
                selectedPlayers,
                init,
                searchedPlayers,
                searchPlayers,
                loadStatus,
                setSelectedPlayers,
                shareLink,
            },
            { notify },
        ] = useDependencies(InvitePlayersStore, NotificationsStore);

        const [isCopied, setIsCopied] = useState(false);

        useEffect(() => {
            init(getShareLink);
        }, []);

        const isMobile = useIsMobile();

        const handleCopy = () => {
            if (shareLink) {
                navigator.clipboard.writeText(shareLink).then(() => {
                    setIsCopied(true);
                });
            }
        };

        const handleShare = () => {
            navigator
                .share({
                    url: shareLink,
                })
                .catch(noop);
        };

        const handleSendInvite = () => {
            onInvite(selectedPlayers)
                .then(() => {
                    notify({
                        severity: 'success',
                        children: 'Sent successfully!',
                    });

                    onClose();
                })
                .catch(noop);
        };

        const canShare = doesSupportSharing() && isMobile;

        return (
            <Dialog
                fullScreen={isMobile}
                open
                onClose={onClose}
                fullWidth
                maxWidth={'sm'}
            >
                <Box
                    display="flex"
                    justifyContent={'center'}
                    alignItems={'center'}
                    px={2}
                    sx={{ height: 68 }}
                >
                    <DialogTitle sx={{ m: 0, p: 2 }}>
                        <Typography
                            fontWeight={900}
                            fontSize={24}
                            color={'primary'}
                        >
                            Invite Players
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={onClose}
                        sx={{ p: 0, position: 'absolute', right: 22 }}
                    >
                        <CloseIcon sx={{ width: 32, height: 32 }} />
                    </IconButton>
                </Box>
                <DialogContent sx={{ background: '#f9f9f9', px: 3 }}>
                    <Autocomplete
                        onChange={(_event, value) => {
                            setSelectedPlayers(value);
                        }}
                        onInputChange={(_event, searchTerm) => {
                            searchPlayers(searchTerm);
                        }}
                        isOptionEqualToValue={(option, value) =>
                            option.username === value.username
                        }
                        loading={loadStatus === LoadStatus.Loading}
                        value={selectedPlayers}
                        multiple
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Players"
                                placeholder="Search Players"
                            />
                        )}
                        options={searchedPlayers}
                        getOptionLabel={(option) => option.username!}
                    />
                </DialogContent>
                <Box p={3} display={'flex'} alignItems={'center'} gap={2}>
                    <Button
                        onClick={canShare ? handleShare : handleCopy}
                        disabled={!shareLink}
                        fullWidth
                        variant={'outlined'}
                        color={'inherit'}
                        startIcon={
                            canShare ? (
                                <ShareIcon />
                            ) : isCopied ? (
                                <DoneAllIcon />
                            ) : (
                                <ContentCopyIcon />
                            )
                        }
                    >
                        {canShare
                            ? 'Share Link'
                            : isCopied
                              ? 'Copied!'
                              : 'Copy Link'}
                    </Button>
                    <Button
                        disabled={selectedPlayers.length === 0}
                        fullWidth
                        color={'primary'}
                        variant={'contained'}
                        onClick={handleSendInvite}
                    >
                        Send Invite
                    </Button>
                </Box>
            </Dialog>
        );
    })
);

import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import * as Yup from "yup";

import { useFormik } from "formik";
import { TLoginCredentials } from "../../../types";
import { useNavigate } from "react-router-dom";

type TInitialValues = {
  email: string;
};

const initialValues: TInitialValues = {
  email: "",
};

interface IForgotMobile {
  onSend: (credentials: { email: string }) => void;
}

const validationSchema = Yup.object({
  email: Yup.string().email().max(255).required("required field"),
});
export const ForgotMobile = ({ onSend }: IForgotMobile) => {
  const navigate = useNavigate();
  const onSubmit = (values: TInitialValues) => {
    // onLogin({
    //   password: values.password,
    //   login: values.userNameEmail,
    // });
    onSend({
      email: values.email,
    });
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  const { handleSubmit, handleChange, values, errors, touched, handleBlur } =
    formik;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        textAlign: "center",
        width: "100%",
        justifyContent: "center",
        mt: 3,
        background: "#FFF",
      }}
      p={2}
    >
      <Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
        <Typography variant="h4" color="#1D1D20" fontWeight={"800"}>
          Forgot Password
        </Typography>
        <Typography variant="body1" color="#1D1D20" fontWeight={"500"}>
          Enter your email to recover your password.
        </Typography>
      </Stack>

      <form onSubmit={handleSubmit}>
        <Stack spacing={3} mt={3}>
          {" "}
          <TextField
            fullWidth
            label="Email Address *"
            onChange={handleChange}
            name="email"
            value={values.email}
            error={!!(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
          />
          <Button
            variant="contained"
            size="large"
            sx={{
              textTransform: "none",
            }}
            type="submit"
            disabled={!formik.isValid}
          >
            <Typography fontSize={"20px"}>Send</Typography>
          </Button>
        </Stack>
      </form>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
        Don’t have an account yet?{" "}
        <Button
          variant="text"
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            navigate("/signup");
          }}
        >
          Sign up
        </Button>
      </Stack>
    </Box>
  );
};

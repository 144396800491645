import React from "react";
import ILLUSTRARIONSVG from "./empty.svg";
import "./index.scss";

export const EmptyList = () => {
  return (
    <div className="empty_list_container">
      <img alt="empty" src={ILLUSTRARIONSVG} className="illustration" />
      <p className="message">Nothing yet</p>
    </div>
  );
};

import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "./baseQuery";
import { ISportWithFilters } from "../pages/Slate/types";
import {
  BetHistoryTypes,
  TAddNewBet,
  TBetHistory,
  TGetMatchesWithOddsResponse,
  TGetStandings,
  TGetTournamentParticipationsModel,
  TLeagueTournamenActiveData,
  TOddsSectionsModel,
  TTournamentParticipatingById,
} from "../types";

export const gamesApi = createApi({
  reducerPath: "gamesApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getLeaguesWithFilters: builder.query<
      ISportWithFilters[],
      {
        tournamentId?: string | number;
      }
    >({
      query: ({ tournamentId }) => ({
        url: tournamentId
          ? `SportLeagues/filters/withSports?includeUnavailable=false&tournamentId=${tournamentId}`
          : `SportLeagues/filters/withSports?includeUnavailable=false`,
      }),
    }),
    getBetHistory: builder.query<
      TBetHistory[],
      {
        tournamentId: string;
        status: BetHistoryTypes;
        playerId?: number;
      }
    >({
      query: ({ tournamentId, status, playerId }) => ({
        url: `Bets`,
        params: {
          tournamentId,
          state: status,
          playerId,
        },
      }),
    }),
    getMacthesWithOdds: builder.query<
      TGetMatchesWithOddsResponse,
      {
        tournamentId?: string | number;
        start: number;
        count: number;
        sportId: number;
        leagueId: number;
        minEndDate?: string;
      }
    >({
      query: ({
        tournamentId,
        start,
        count,
        sportId,
        leagueId,
        minEndDate,
      }) => ({
        url: `matches/withOdds`,
        params: {
          tournamentId,
          start,
          count,
          sportId,
          leagueId,
          minEndDate,
        },
      }),
    }),
    addNewBet: builder.mutation<void, TAddNewBet>({
      query: ({ amount, matchOddIds, tournamentId }) => ({
        url: "bets",
        method: "POST",
        body: {
          amount,
          matchOddIds,
          tournamentId,
        },
      }),
    }),
    getTournamentsParticipating: builder.query<
      TTournamentParticipatingById,
      {
        tournamentId?: string;
      }
    >({
      query: ({ tournamentId }) => ({
        url: `/tournaments/${tournamentId}/participating`,
      }),
    }),
    getBetsConflicts: builder.query<number[], number[]>({
      query: (oddIds) => ({
        url: `/Bets/conflicts`,
        method: "Post",
        body: oddIds,
      }),
    }),
    getBalance: builder.query<
      {
        balance: number;
      },
      {
        tournamentId?: number;
      }
    >({
      query: ({ tournamentId }) => ({
        url: `/Tournaments/${tournamentId}/balance`,
      }),
    }),
    getLeagueTournamentData: builder.query<
      TLeagueTournamenActiveData,
      {
        leagueId: string;
      }
    >({
      query: ({ leagueId }) => ({
        url: `/GameLeagues/${leagueId}/tournament/active`,
      }),
    }),
    getTournamentParticipations: builder.query<
      TGetTournamentParticipationsModel,
      {
        tournamentId: string;
      }
    >({
      query: ({ tournamentId }) => ({
        url: `/tournamentParticipations/${tournamentId}`,
      }),
    }),
    getStandings: builder.query<
      TGetStandings,
      {
        leagueId: string;
      }
    >({
      query: ({ leagueId }) => ({
        url: `gameleagues/${leagueId}/standings`,
      }),
    }),
    getMatchOddSections: builder.query<
      TOddsSectionsModel[],
      {
        matchId: number;
      }
    >({
      query: ({ matchId }) => ({
        url: `Matches/${matchId}/odds/sections`,
      }),
    }),
  }),
});
// setTournamentsParticipating;

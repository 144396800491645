import { injectable } from 'inversify';
import { FriendshipStatus } from '../../common/api/api';
import { PlayerFriendsBaseStore } from './player-friends-base-store';
import { apiClient } from '../../common/api/api-client';

@injectable()
export class MyFriendsStore extends PlayerFriendsBaseStore {
    friendShipStatus = FriendshipStatus.Friends;

    removeFriend = async (id: number) => {
        await apiClient.friendsDELETE(id);
        this.incrementReducedPlayersCount();
    };
}

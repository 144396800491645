import { observer } from 'mobx-react-lite';
import { useTheme } from '@mui/material';
import { TRIAL_BANNER_HEIGHT } from '../../utils/ui';
import Box from '@mui/material/Box';
import { useDependencies } from '../../utils/ioc/use-dependencies';
import { AuthStore } from '../../stores/auth-store';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Fragment } from 'react';

export const SubscriptionBanner = observer(() => {
    const [
        {
            remainingSubscriptionDays,
            reactivateSubscription,
            subscribe,
            isTrial,
        },
    ] = useDependencies(AuthStore);
    const theme = useTheme();

    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
                height: TRIAL_BANNER_HEIGHT,
                bgcolor: theme.palette.grey[50],
            }}
        >
            <Typography variant={'subtitle2'} fontWeight={900}>
                {isTrial ? (
                    <Fragment>
                        {remainingSubscriptionDays} days until the end of free
                        trial.{' '}
                        <Link sx={{ cursor: 'pointer' }} onClick={subscribe}>
                            Upgrade now!
                        </Link>
                    </Fragment>
                ) : (
                    <Fragment>
                        Your subscription ends in {remainingSubscriptionDays}{' '}
                        days.{' '}
                        <Link
                            sx={{ cursor: 'pointer' }}
                            onClick={reactivateSubscription}
                        >
                            Reactivate now!
                        </Link>
                    </Fragment>
                )}
            </Typography>
        </Box>
    );
});

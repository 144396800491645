import Close from '@mui/icons-material/Close';
import { Box, IconButton, SvgIcon, Typography } from '@mui/material';
import { FC } from 'react';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { IBetSlipOdd, BetSlipStore } from '../stores/bet-slip-store';
import { observer } from 'mobx-react-lite';
import { formatAmericanOdd } from '../../common/utils/format-american-odd';
import WarningIcon from '@mui/icons-material/Warning';
import { BetSlipProps } from './bet-slip';

interface BetSlipOddProps {
    betSlipOdd: IBetSlipOdd;
    onMatchClick: BetSlipProps['onMatchClick'];
}

export const BetSlipOdd: FC<BetSlipOddProps> = observer(
    ({ betSlipOdd, onMatchClick }) => {
        const [{ conflicts, selectOdd }] = useDependencies(BetSlipStore);

        const { betSlipName, betType, americanStyleOdd, param } =
            betSlipOdd.odd;
        const { id: matchId, teams } = betSlipOdd.matchInfo;

        const hasConflict = conflicts.has(betSlipOdd.odd.id);

        return (
            <Box display="flex" flexDirection="column" gap={0.5}>
                <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                >
                    <Box display={'flex'} gap={2}>
                        {!!betSlipName && (
                            <Typography variant="subtitle2" fontWeight={900}>
                                {betSlipName}
                            </Typography>
                        )}
                        <Typography variant="subtitle2" fontWeight={900}>
                            {param}
                        </Typography>
                    </Box>
                    <Box display={'flex'} gap={2} alignItems={'center'}>
                        <Typography
                            variant="subtitle2"
                            fontWeight={900}
                            color={'primary'}
                        >
                            {formatAmericanOdd(americanStyleOdd!)}
                        </Typography>
                        <IconButton
                            size={'small'}
                            onClick={() => selectOdd(betSlipOdd)}
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </Box>
                <Typography variant="body2" color={'textSecondary'}>
                    {betType.name}
                </Typography>
                {hasConflict ? (
                    <SvgIcon color={'error'}>
                        <WarningIcon />
                    </SvgIcon>
                ) : null}
                <Typography
                    variant="body1"
                    sx={{
                        mb: 1,
                        cursor: 'pointer',
                    }}
                    onClick={() => {
                        onMatchClick(matchId);
                    }}
                >
                    {teams[0]} @ {teams[1]}
                </Typography>
            </Box>
        );
    }
);

import { injectable } from 'inversify';
import { apiClient } from '../../common/api/api-client';
import { makeAutoObservable, runInAction } from 'mobx';
import { LoadStatus } from '../../common/enums/load-status';
import { PlayerWithBets } from '../utils/types';

@injectable()
export class StandingsMatchupStore {
    loadStatus = LoadStatus.None;
    playersWithBets: PlayerWithBets[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    init = async (gameId: number) => {
        runInAction(() => {
            this.loadStatus = LoadStatus.Loading;
        });

        try {
            const gameInfo = await apiClient.tournamentsGET2(gameId, undefined);

            const { players } = gameInfo;

            if (!players) {
                return;
            }

            const bets = await Promise.all(
                players.map((player) =>
                    apiClient.getBets(gameId, player.id, undefined, undefined)
                )
            );

            const playersWithBets = players.map((player, index) => ({
                player,
                bets: bets[index],
            }));

            runInAction(() => {
                this.playersWithBets = playersWithBets;
                this.loadStatus = LoadStatus.Ok;
            });
        } catch {
            runInAction(() => {
                this.loadStatus = LoadStatus.Error;
            });
        }
    };
}

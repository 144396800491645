import { useEffect, useState, useRef } from "react";

const MOBILE_SIZE = 480;

export const useEventListener = (
  eventName: string,
  handler: (event: MouseEvent) => void,
  element: HTMLElement | null | Window = window
): void => {
  const savedHandler = useRef(handler);

  useEffect(() => {
    savedHandler.current = handler;
  }, [handler, savedHandler]);

  useEffect(() => {
    const isSupported = element && element.addEventListener;
    if (!isSupported) return;

    const eventListener = (event: MouseEvent) => savedHandler.current(event);

    element.addEventListener(eventName, eventListener as EventListener);
    return () => {
      element.removeEventListener(eventName, eventListener as EventListener);
    };
  }, [eventName, element, savedHandler]);
};

export const useWindowSize = (): { width: number; height: number } => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = (): void => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEventListener("resize", handleResize);

  useEventListener("orientationchange", handleResize);

  return windowSize;
};

export const useIsMobile = (size: number = MOBILE_SIZE): boolean => {
  const windowSize = useWindowSize();
  return windowSize.width <= size;
};

declare type TSizes = {
  width: number;
  height: number;
};
export interface TRef extends React.RefObject<HTMLElement> {
  clientWidth: number;
  clientHeight: number;
}
export const useGetElemSizes = (elemRef: HTMLElement | TRef | null): TSizes => {
  if (!elemRef) {
    return {
      width: 0,
      height: 0,
    };
  }
  return { width: elemRef.clientWidth, height: elemRef.clientHeight };
};

export const useMaxScroll = () => {
  const [isMaxScroll, setIsMaxScroll] = useState(false);

  const checkMaxScroll = () => {
    if (
      window.scrollY + 1 >
      document.body.scrollHeight - document.body.offsetHeight
    ) {
      setIsMaxScroll(true);
      return;
    }
    setIsMaxScroll(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", checkMaxScroll);

    return () => document.removeEventListener("scroll", checkMaxScroll);
  }, []);

  return isMaxScroll;
};

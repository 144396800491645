import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { GameContentStore } from '../../stores/game-content-store';
import { Leagues } from '../../../common/components/leagues/leagues';

export interface GameContentLeaguesProps {
    onClickLeague: (leagueId: number) => void;
}

export const GameContentLeagues: FC<GameContentLeaguesProps> = observer(
    ({ onClickLeague }) => {
        const [{ sportLeagueId, sports }] = useDependencies(GameContentStore);

        return (
            <Leagues
                sports={sports}
                selectedLeagueId={sportLeagueId}
                onChangeLeagueId={onClickLeague}
            />
        );
    }
);

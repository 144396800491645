import { Store } from "@reduxjs/toolkit";
import {
  BaseQueryApi,
  FetchArgs,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { authSlice } from "./auth/authSlice";

export const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    // const { accessToken } = (getState() as ReturnType<Store["getState"]>).auth;
    const accessToken = localStorage.getItem("token")
    if (accessToken) {
      headers.set("Authorization", `Bearer ${accessToken}`);
    }
  },
});

export const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: any
) => {
  let response = await baseQuery(args, api, extraOptions);
//   if (response.error && response.error.status === 401) {
//     const refreshTokenString = (api.getState() as ReturnType<Store["getState"]>)
//       .auth.refreshToken;

//     const refreshTokenResult = await baseQuery(
//       {
//         url: "/Authentication/refreshToken",
//         method: "POST",
//         body: { tokenString: refreshTokenString },
//       },
//       api,
//       extraOptions
//     );
//     if (refreshTokenResult?.data) {
//       api.dispatch(
//         authSlice.actions.setCredentials({ ...refreshTokenResult.data })
//       );
//       //retry original query
//       response = await baseQuery(args, api, extraOptions);
//     } else {
//       api.dispatch(authSlice.actions.logout({}));
//     }
//   }

//   if (response.error) {
//   }

  return response;
};

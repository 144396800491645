import { FC, Fragment, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import { useIsMobile } from '../../common/hooks/use-is-mobile';

export const PlayersContainer: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme();

    const isMobile = useIsMobile();

    if (isMobile) {
        return <Fragment>{children}</Fragment>;
    }

    return (
        <Box
            sx={{
                borderRadius: 1,
                background: '#F9F9FE',
                border: '4px solid',
                borderColor: theme.palette.grey[300],
            }}
            p={3}
        >
            {children}
        </Box>
    );
};

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { IdNameModel } from '../../api/api';
import { FC, SyntheticEvent } from 'react';
import Box from '@mui/material/Box';

export interface MatchSectionProps {
    sections: IdNameModel[];
    onChange: (sectionId: IdNameModel['id']) => void;
    selectedSectionId?: number;
}

export const MatchSections: FC<MatchSectionProps> = ({
    sections,
    selectedSectionId,
    onChange,
}) => {
    const handleChange = (_event: SyntheticEvent, value: number) => {
        onChange(value);
    };

    return (
        <Box sx={{ position: 'relative', height: '48px' }}>
            <Box sx={{ position: 'absolute', maxWidth: '100%' }}>
                <Tabs
                    value={selectedSectionId}
                    variant={'scrollable'}
                    scrollButtons={'auto'}
                    onChange={handleChange}
                >
                    {sections.map((section) => {
                        return (
                            <Tab
                                value={section.id}
                                key={section.id}
                                label={section.name}
                            />
                        );
                    })}
                </Tabs>
            </Box>
        </Box>
    );
};

import moment from 'moment/moment';

export const getDailyTimestampsOfRange = (startDate: Date, endDate: Date) => {
    const start = moment(startDate);
    const end = moment(endDate);

    const result = [];

    const current = start.clone();
    while (current.isSameOrBefore(end, 'day')) {
        result.push(current.startOf('day').valueOf());
        current.add(1, 'day');
    }

    return result;
};

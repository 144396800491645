import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import {
    GameContentLeagues,
    GameContentLeaguesProps,
} from './game-content-leagues';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { Box, Chip, Typography, useTheme } from '@mui/material';
import { useIsMobile } from '../../../common/hooks/use-is-mobile';
import { useNavigate } from 'react-router-dom';
import { MatchWithOddsModel } from '../../../common/api/api';
import { GameContentStore } from '../../stores/game-content-store';
import { MatchOverviews } from '../../../common/components/match-overviews/match-overviews';
import { formatDateWithName } from '../../../../utils';
import { GameContentActions } from './game-content-actions';
import { BackButton } from '../../../common/components/back-button/back-button';

interface GameContentMatchOverviewsProps {
    onClickLeague: GameContentLeaguesProps['onClickLeague'];
}

export const GameContentMatchOverviews: FC<GameContentMatchOverviewsProps> =
    observer(({ onClickLeague }) => {
        const [{ matchOverviewsLoadStatus, game, matchOverviews }] =
            useDependencies(GameContentStore);
        const theme = useTheme();
        const isMobile = useIsMobile();

        const navigate = useNavigate();

        const handleMatchClick = (matchOverview: MatchWithOddsModel) => {
            navigate(`${matchOverview.match.id}`);
        };

        return (
            <Box mb={{ xs: 2, sm: 0 }}>
                <Box p={{ xs: 2, sm: 0 }} mb={{ sm: 2 }}>
                    {game?.gameLeagueId && (
                        <Box mb={2}>
                            <BackButton
                                isBack={false}
                                label={'League Details'}
                                onClick={() =>
                                    navigate(
                                        `/leagues/content/${game.gameLeagueId}`
                                    )
                                }
                            />
                        </Box>
                    )}
                    <Box
                        display={'flex'}
                        flexDirection={{ xs: 'column', sm: 'row' }}
                        alignItems={{ xs: 'start', sm: 'center' }}
                        gap={2}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontWeight: 900,
                                color: theme.palette.primary.main,
                            }}
                        >
                            Game {game ? `#${game.id}` : ''}
                        </Typography>
                        {!!game && (
                            <Chip
                                sx={{ background: 'white' }}
                                label={`${formatDateWithName(game.startDate)} - ${formatDateWithName(game.endDate)}`}
                            />
                        )}
                        {isMobile && <GameContentActions />}
                        {isMobile && (
                            <GameContentLeagues onClickLeague={onClickLeague} />
                        )}
                    </Box>
                </Box>
                {!!matchOverviews && (
                    <MatchOverviews
                        loadStatus={matchOverviewsLoadStatus}
                        matchOverviews={matchOverviews}
                        onMatchClick={handleMatchClick}
                    />
                )}
            </Box>
        );
    });

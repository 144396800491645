import { injectable } from 'inversify';
import { FriendshipStatus, PlayerFriendModel } from '../../common/api/api';
import { PlayerFriendsBaseStore } from './player-friends-base-store';
import { apiClient } from '../../common/api/api-client';
import { runInAction } from 'mobx';

@injectable()
export class AddFriendsStore extends PlayerFriendsBaseStore {
    friendShipStatus = FriendshipStatus.NotFriends;

    addFriend = async (id: number) => {
        await apiClient.friendsPOST(id);

        const addedPlayerIndex = this.players.findIndex(
            (player) => player.id === id
        );

        if (addedPlayerIndex !== -1) {
            runInAction(() => {
                this.players[addedPlayerIndex] = {
                    ...this.players[addedPlayerIndex],
                    friendshipStatus: FriendshipStatus.RequestSent,
                } as PlayerFriendModel;
            });
        }
    };

    acceptFriendRequest = async (id: number) => {
        await apiClient.friendsPOST(id);
        this.incrementReducedPlayersCount();
    };

    denyFriendRequest = async (id: number) => {
        await apiClient.friendsDELETE(id);
    };
}

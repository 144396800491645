import { Stack, TextField, InputAdornment } from "@mui/material";
import AtmIcon from "../../icons/Atm";

interface ITotalWinSection {
  onStakeChange: (value: string) => void;
}

export const TotalWinSection = ({ onStakeChange }: ITotalWinSection) => {
  return (
    <Stack direction={"row"} gap={2} borderRadius={"8px"}>
      <TextField
        autoComplete="off"
        InputProps={{
          inputProps: {
            min: 0,
            p: "10px",
          },
          sx: {
            borderRadius: 0.5,
            backgroundColor: "#FFF",
            height: "44px",
            "& input": {
              WebkitAppearance: "none", // Remove arrows in Chrome and Safari
              MozAppearance: "textfield", // Remove arrows in Firefox
            },
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none", // Remove arrows in Chrome
              },
          },
          startAdornment: (
            <InputAdornment position="start">
              <AtmIcon />
            </InputAdornment>
          ),
          type: "number",
        }}
        sx={{
          color: "#707074",
          fontWeight: "500",
          width: "130px",
        }}
        onChange={(e) => onStakeChange(e.target.value)}
        inputMode="numeric"
      />
    </Stack>
  );
};

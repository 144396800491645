import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { baseQueryWithReauth } from "../baseQuery";
import { TSignUpCredentials } from "../../types";

export type LoginCredentialsModel = {
  login: string;
  password: string;
};

export type Tokens = {
  accessToken: string;
  expireAt: string;
  refreshToken: {
    expireAt: string;
    tokenString: string;
  };
};

export type GetAccessTokenCredentialsModel = {
  refreshTokenString: string;
};

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    login: builder.mutation<Tokens, LoginCredentialsModel>({
      query: (credentials) => ({
        url: "/Authentication/accessToken",
        method: "POST",
        body: credentials,
      }),
    }),
    register: builder.mutation<Tokens, TSignUpCredentials>({
      query: (credentials) => ({
        url: "/Users",
        method: "POST",
        body: credentials,
      }),
    }),
    getAccessToken: builder.mutation<Tokens, GetAccessTokenCredentialsModel>({
      query: (credentials) => ({
        url: "/Authentication/refreshToken",
        method: "POST",
        body: {
          tokenString: credentials.refreshTokenString,
        },
      }),
    }),
  }),
});

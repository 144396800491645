import { injectable } from 'inversify';
import { HubConnection } from '@microsoft/signalr';
import { makeAutoObservable } from 'mobx';
import * as signalR from '@microsoft/signalr';
import {
    GeneralAnnouncementMessageModelFocusNotificationMessageModel,
    RankedGameCreatedMessageModelFocusNotificationMessageModel,
    RankingSeasonChangedMessageModelFocusNotificationMessageModel,
    RankUpdatedMessageModelFocusNotificationMessageModel,
} from '../../common/api/api';

const sequentialMessages = ['ReceiveNotificationCountAsync'];

const mapEventsToConstructors: Record<string, { fromJS: (data: any) => any }> =
    {
        ReceiveGeneralAnnouncementAsync:
            GeneralAnnouncementMessageModelFocusNotificationMessageModel,
        RankedGameCreatedAsync:
            RankedGameCreatedMessageModelFocusNotificationMessageModel,
        ReceiveRankUpdateAsync:
            RankUpdatedMessageModelFocusNotificationMessageModel,
        RankingSeasonChangedAsync:
            RankingSeasonChangedMessageModelFocusNotificationMessageModel,
    };

@injectable()
export class CommsHubStore {
    connection: HubConnection;

    constructor() {
        makeAutoObservable(this);

        const connection = new signalR.HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_BASE_URL_V2}/hubs/CommsHub`, {
                accessTokenFactory: () => localStorage.getItem('token') ?? '',
            })
            .withAutomaticReconnect()
            .configureLogging(signalR.LogLevel.Information)
            .build();

        const originalConnectionOn = connection.on.bind(connection);

        connection.on = (event, handler, ...connectionRestArgs) => {
            let lastTimestamp = 0;
            const overridable = sequentialMessages.includes(event);

            const newHandler: typeof handler = (
                data,
                rawDate: Date,
                ...handlerRestArgs
            ) => {
                let finalData = data;
                const date = new Date(rawDate);
                const receivedTimestamp = date.getTime();

                if (mapEventsToConstructors[event]) {
                    finalData =
                        mapEventsToConstructors[event].fromJS(finalData);
                }

                if (overridable && receivedTimestamp < lastTimestamp) {
                    return;
                }

                handler(finalData, date, ...handlerRestArgs);
                lastTimestamp = receivedTimestamp;
            };

            return originalConnectionOn(
                event,
                newHandler,
                ...connectionRestArgs
            );
        };

        this.connection = connection;
    }

    init = async () => {
        try {
            await this.connection.start();
        } catch {
            //
        }
    };

    dispose = async () => {
        try {
            await this.connection?.stop();
        } catch {
            //
        }
    };
}

import { FC } from 'react';
import { FirstRankIcon } from '../../../leaderboard/components/icons/first-rank-icon';
import { SecondRankIcon } from '../../../leaderboard/components/icons/second-rank-icon';
import { ThirdRankIcon } from '../../../leaderboard/components/icons/third-rank-icon';
import Typography from '@mui/material/Typography';

export const Rank: FC<{ rank: number }> = ({ rank }) => {
    if (rank === 1) {
        return <FirstRankIcon />;
    }
    if (rank === 2) {
        return <SecondRankIcon />;
    }
    if (rank === 3) {
        return <ThirdRankIcon />;
    }

    return (
        <Typography variant={'subtitle1'} fontWeight={600}>
            {rank}
        </Typography>
    );
};

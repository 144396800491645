import { observer } from 'mobx-react-lite';
import { provide } from '../../common/utils/ioc/provide';
import { LeaguesStore } from '../stores/leagues-store';
import Box from '@mui/material/Box';
import LeaguesSectionImage from '../../../assets/images/leagues-section-image.jpeg';
import { SectionWithBackground } from '../../common/components/section-with-background/section-with-background';
import Typography from '@mui/material/Typography';
import { Tab, Tabs } from '@mui/material';
import {
    Navigate,
    Link as RouterLink,
    Route,
    Routes,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import Button from '@mui/material/Button';
import { asParentPath } from '../../common/utils/as-parent-path';
import { MyLeagues } from './my-leagues/my-leagues';
import { LiveLeagues } from './live-leagues/live-leagues';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { CreateLeague } from './create-league/create-league';
import { GameLeaguePublicModel } from '../../common/api/api';
import { Fragment, useEffect, useState } from 'react';
import Link from '@mui/material/Link';
import { NotificationsStore } from '../../common/stores/notifications-store';
import { LeaguesRoutes } from '../utils/leagues-routes';
import { LeagueContentPage } from './content/league-content-page';
import { InvitedLeague } from './invited-league/invited-league';
import { Participants } from '../../participants/components/participants';

const LeaguesPageMain = provide([LeaguesStore])(
    observer(() => {
        const [
            {
                isCreatingLeague,
                hideCreateLeague,
                showCreateLeague,
                initInvitedLeague,
                invitedLeague,
                hideParticipants,
                getParticipants,
                leagueForParticipants,
            },
            { notify },
        ] = useDependencies(LeaguesStore, NotificationsStore);
        const navigate = useNavigate();

        const [myLeaguesKey, setMyLeaguesKey] = useState(() => Math.random());

        const { statusTab } = useParams();

        const [searchParams] = useSearchParams();

        useEffect(() => {
            // TODO: have globally defined keys
            const invitationId = searchParams.get('invitationId');
            const token = searchParams.get('token');

            if (invitationId) {
                initInvitedLeague(
                    +invitationId,
                    token ? decodeURIComponent(token) : undefined
                );
            }
        }, []);

        if (
            !Object.values(LeaguesRoutes).includes(statusTab as LeaguesRoutes)
        ) {
            return <Navigate to={LeaguesRoutes['My Leagues']} replace />;
        }

        const createLeagueCallback = (gameLeague?: GameLeaguePublicModel) => {
            if (statusTab === LeaguesRoutes['My Leagues']) {
                setMyLeaguesKey(Math.random());
            }

            if (gameLeague) {
                notify({
                    severity: 'success',
                    children: (
                        <Fragment>
                            <Typography>
                                Successfully created!
                                {statusTab ===
                                    LeaguesRoutes['Live Leagues'] && (
                                    <Fragment>
                                        &nbsp;See&nbsp;
                                        <Link
                                            sx={{ cursor: 'pointer' }}
                                            onClick={() => {
                                                // closeNotification();
                                                navigate(
                                                    `../${LeaguesRoutes['My Leagues']}`
                                                );
                                            }}
                                        >
                                            My Leagues
                                        </Link>
                                        .
                                    </Fragment>
                                )}
                            </Typography>
                        </Fragment>
                    ),
                });
            }
        };

        return (
            <Fragment>
                <SectionWithBackground
                    imgSrc={LeaguesSectionImage}
                    description={'PLACEHOLDER TEXT'}
                    headline={'PLACEHOLDER TEXT'}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexDirection: { xs: 'column', sm: 'row' },
                            alignItems: 'start',
                            gap: 3,
                            mb: 3,
                        }}
                    >
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'h4'} fontWeight={900} mb={1}>
                                Leagues
                            </Typography>
                            <Tabs value={statusTab}>
                                {Object.entries(LeaguesRoutes).map(
                                    ([label, route]) => (
                                        <Tab
                                            key={label}
                                            label={label}
                                            component={RouterLink}
                                            to={`../${route}`}
                                            value={route}
                                        />
                                    )
                                )}
                            </Tabs>
                        </Box>
                        <Button
                            onClick={showCreateLeague}
                            variant={'contained'}
                            sx={{ width: { xs: '100%', sm: '344px' } }}
                        >
                            Create League
                        </Button>
                    </Box>
                    {statusTab === LeaguesRoutes['My Leagues'] && (
                        <MyLeagues key={myLeaguesKey} />
                    )}
                    {statusTab === LeaguesRoutes['Live Leagues'] && (
                        <LiveLeagues />
                    )}
                    {isCreatingLeague && (
                        <CreateLeague
                            onClose={hideCreateLeague}
                            successCallback={createLeagueCallback}
                        />
                    )}
                </SectionWithBackground>
                {!!invitedLeague && <InvitedLeague league={invitedLeague} />}
                {!!leagueForParticipants && (
                    <Participants
                        onClose={hideParticipants}
                        getParticipants={getParticipants}
                    />
                )}
            </Fragment>
        );
    })
);

export const LeaguesPage = () => {
    return (
        <Routes>
            <Route path={':statusTab?'} element={<LeaguesPageMain />} />
            <Route
                path={asParentPath('content/:leagueId')}
                element={<LeagueContentPage />}
            />
            <Route path={'*'} element={<Navigate to={''} replace />} />
        </Routes>
    );
};

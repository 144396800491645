import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Avatar, Container, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout } from '@mui/icons-material';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import PeopleIcon from '@mui/icons-material/People';
import Notifications from '../../pages/Notifications';
import balanceBetLogo from '../../images/BalanceBetLogo.svg';
import './index.scss';
import GradientContainer from '../GradientContainer';
import { Footer } from '../Footers';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../modules/common/utils/ioc/use-dependencies';
import { AuthStore } from '../../modules/common/stores/auth-store';
import { getAppBarHeight, TOOLBAR_HEIGHT } from '../../modules/common/utils/ui';
import { SubscriptionBanner } from '../../modules/common/components/subscription-banner/subscription-banner';
import { NotificationsStore } from '../../modules/common/stores/notifications-store';
import { Fragment, useEffect, useState, MouseEvent, useRef } from 'react';
import { UserNotifications } from '../../modules/user-notifications/components/user-notifications';
import { useIsMobile } from '../../modules/common/hooks/use-is-mobile';

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
    content: React.ReactNode;
    isFooterLink?: boolean;
    hideAppBar?: boolean;
}

const drawerWidth = 240;

export function DesktopMenuItem({ item, isFooterLink }: any) {
    const navigate = useNavigate();
    const goTo = (path: string) => () => navigate(path);

    return (
        <Button
            disableElevation
            disableTouchRipple
            key={item.title}
            onClick={goTo(item.path)}
            style={{
                fontWeight: isFooterLink ? 'light' : 'bold',
                fontSize: 14,
                color: '#ffffff',
                textTransform: 'none',
            }}
        >
            {item.title}
        </Button>
    );
}

const Navigation = observer((props: Props) => {
    const [
        { me, unAuthorize, isAuthenticated, hasSubscriptionRemainingDays },
        { notify },
    ] = useDependencies(AuthStore, NotificationsStore);
    const { window: injectedWindow, content, hideAppBar } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const clearSuccessKey = () => {
            searchParams.delete('success');
            setSearchParams(searchParams);
        };

        if (searchParams.get('success') === 'true') {
            clearSuccessKey();
            notify({ severity: 'success', children: 'Transaction succeeded!' });
        } else if (searchParams.get('success') === 'false') {
            clearSuccessKey();
            notify({ severity: 'error', children: 'Transaction failed!' });
        }
    }, []);

    const rightNavItems = isAuthenticated
        ? [
              { title: 'Lobby', path: '/lobby' },
              { title: 'Leagues', path: '/leagues' },
              { title: 'Slate', path: '/slate' },
              { title: 'My Games', path: '/my-games' },
              { title: 'History', path: '/history' },
          ]
        : [];

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const goTo = (path: string) => () => navigate(path);

    const container =
        injectedWindow !== undefined
            ? () => injectedWindow().document.body
            : undefined;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logOut = () => {
        unAuthorize();
    };

    const mainContentElRef = useRef<HTMLDivElement>();

    useEffect(() => {
        mainContentElRef.current?.scroll({ top: 0 });
    }, [location.pathname]);

    const isMobile = useIsMobile();

    const profile = !me ? null : (
        <>
            <Container>
                {/* <Button variant="contained" onClick={buyLeaguePass} sx={{ maxWidth: 32, minWidth: 32, p: 0, mr: -2 }}>{profileInfo.leaguePassCount}</Button> */}
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    {me.avatarUrl ? (
                        <GradientContainer
                            src={me.avatarUrl}
                            width={48}
                            height={48}
                        />
                    ) : (
                        <Avatar />
                    )}
                </IconButton>
            </Container>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={goTo('/profile')}>
                    <Avatar src={me.avatarUrl} />
                    Profile
                </MenuItem>
                {isMobile && (
                    <MenuItem onClick={goTo('/friends')}>
                        <ListItemIcon>
                            <PeopleIcon />
                        </ListItemIcon>
                        Friends
                    </MenuItem>
                )}
                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    );

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                Sports betting
            </Typography>
            <Divider />
            <List>
                {rightNavItems.map((item) => (
                    <ListItem
                        key={item.title}
                        disablePadding
                        onClick={goTo(item.path)}
                    >
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText
                                primary={item.title}
                                sx={{ fontWeight: 'bold' }}
                            />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    // if (isLanding) {
    //     return (
    //         <div style={{ width: '100%', height: '100%' }}>
    //             <Box
    //                 sx={{
    //                     display: { xs: 'flex' },
    //                     justifyContent: 'space-between',
    //                     width: '100%',
    //                 }}
    //             >
    //                 <div className="navigation_container">
    //                     <Box
    //                         sx={{
    //                             display: { xs: 'flex', sm: 'none' },
    //                             justifyContent: 'space-between',
    //                             width: '100%',
    //                         }}
    //                     >
    //                         <IconButton
    //                             color="primary"
    //                             aria-label="open drawer"
    //                             edge="start"
    //                             onClick={handleDrawerToggle}
    //                             sx={{ mr: 2, display: { sm: 'none' } }}
    //                         >
    //                             <MenuIcon />
    //                         </IconButton>
    //
    //                         <div
    //                             style={{
    //                                 display: 'flex',
    //                                 alignItems: 'center',
    //                             }}
    //                         >
    //                             {!!me && <Notifications />}
    //                             {profile}
    //                         </div>
    //                     </Box>
    //                     <Box
    //                         onClick={navigateToLandingPage}
    //                         sx={{
    //                             display: { xs: 'none', sm: 'flex' },
    //                             alignItems: 'center',
    //                             cursor: 'pointer',
    //                         }}
    //                     >
    //                         <Box
    //                             sx={{ height: 48, ml: 5 }}
    //                             component="img"
    //                             alt="logo"
    //                             src={balanceBetLogo}
    //                         />
    //                     </Box>
    //                     <Box
    //                         sx={{
    //                             display: {
    //                                 xs: 'none',
    //                                 sm: 'flex',
    //                                 gap: '16px',
    //                             },
    //                         }}
    //                     >
    //                         {rightNavItems.map((item, index) => (
    //                             <DesktopMenuItem item={item} key={index} />
    //                         ))}
    //                         <div
    //                             style={{
    //                                 display: 'flex',
    //                                 alignItems: 'center',
    //                             }}
    //                         >
    //                             {!!me && <Notifications />}
    //                             {profile}
    //                         </div>
    //                     </Box>
    //                 </div>
    //             </Box>
    //             <Box component="nav">
    //                 <Drawer
    //                     container={container}
    //                     variant="temporary"
    //                     open={mobileOpen}
    //                     onClose={handleDrawerToggle}
    //                     ModalProps={{
    //                         keepMounted: true, // Better open performance on mobile.
    //                     }}
    //                     sx={{
    //                         display: { xs: 'block', sm: 'none' },
    //                         '& .MuiDrawer-paper': {
    //                             boxSizing: 'border-box',
    //                             width: drawerWidth,
    //                         },
    //                     }}
    //                 >
    //                     {drawer}
    //                 </Drawer>
    //             </Box>
    //             {content}
    //             <Footer />
    //         </div>
    //     );
    // }

    const appBarHeight = getAppBarHeight(hasSubscriptionRemainingDays);
    const contentMinHeightSx = `calc(100dvh - ${hideAppBar ? 0 : appBarHeight}px)`;

    return (
        <Box
            display="flex"
            flexDirection="column"
            sx={{
                width: '100%',
                height: '100%',
            }}
            className="root"
        >
            {!hideAppBar && (
                <Fragment>
                    <Box flex={'0 0 auto'}>
                        <Box sx={{ height: appBarHeight }}>
                            <AppBar
                                color="default"
                                sx={{
                                    background: '#2B5FD9',
                                    height: appBarHeight,
                                }}
                            >
                                {hasSubscriptionRemainingDays && (
                                    <Box flex={'1 0 auto'}>
                                        <SubscriptionBanner />
                                    </Box>
                                )}
                                <Toolbar
                                    sx={{
                                        minHeight: TOOLBAR_HEIGHT,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: { xs: 'flex', sm: 'none' },
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        {isAuthenticated && (
                                            <IconButton
                                                color="inherit"
                                                aria-label="open drawer"
                                                edge="start"
                                                onClick={handleDrawerToggle}
                                                sx={{
                                                    mr: 2,
                                                    display: { sm: 'none' },
                                                }}
                                            >
                                                <MenuIcon />
                                            </IconButton>
                                        )}
                                        <div
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {/*{!!me && <Notifications />}*/}
                                            <UserNotifications />
                                            {profile}
                                        </div>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: { xs: 'none', sm: 'flex' },
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}
                                    >
                                        <Box
                                            onClick={() => navigate('/lobby')}
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'flex',
                                                    alignItems: 'center',
                                                    cursor: 'pointer',
                                                },
                                            }}
                                        >
                                            <Box
                                                sx={{ height: 48, ml: 5 }}
                                                component="img"
                                                alt="logo"
                                                src={balanceBetLogo}
                                            />
                                        </Box>
                                        <Box
                                            sx={{
                                                display: {
                                                    xs: 'none',
                                                    sm: 'flex',
                                                    gap: '16px',
                                                },
                                            }}
                                        >
                                            {rightNavItems.map(
                                                (item, index) => (
                                                    <DesktopMenuItem
                                                        item={item}
                                                        key={index}
                                                    />
                                                )
                                            )}
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                {/*{!!me && <Notifications />}*/}
                                                <UserNotifications />
                                                {profile}
                                            </div>
                                        </Box>
                                    </Box>
                                </Toolbar>
                            </AppBar>
                        </Box>
                    </Box>
                    <Drawer
                        component="nav"
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': {
                                boxSizing: 'border-box',
                                width: drawerWidth,
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Fragment>
            )}
            <Box
                flex={'1 1 auto'}
                component="main"
                sx={{
                    height: `calc(100vh - ${getAppBarHeight(hasSubscriptionRemainingDays)}px)`,
                    overflowY: 'auto',
                }}
                ref={mainContentElRef}
            >
                <Box
                    sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Box flex={'1 1 auto'}>
                        <Box minHeight={contentMinHeightSx} height={'100%'}>
                            {content}
                        </Box>
                    </Box>
                    <Footer />
                </Box>
            </Box>
        </Box>
    );
});

export default Navigation;

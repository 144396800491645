import { configureStore } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';

import { persistStore } from 'redux-persist';
import { authSlice } from '../apiService/auth/authSlice';
import { authApi } from '../apiService/auth/authApi';
import { gamesApi } from '../apiService/games';
import { gameSlice } from '../slices/gameSlice';

enableMapSet();

export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        authApi: authApi.reducer,
        gamesApi: gamesApi.reducer,
        gamesSlice: gameSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(authApi.middleware, gamesApi.middleware),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { CurrentLeagueGame } from '../components/content/current-league-game/current-league-game';
import { Matchup as MatchupComponent } from '../components/content/matchup/matchup';
import { Standings as StandingsComponent } from '../components/content/standings/standings';

export const LeagueContentRoutes = [
    {
        name: 'Bet',
        pathname: 'bet',
        Component: CurrentLeagueGame,
        mobileOnly: true,
    },
    { name: 'Matchup', pathname: 'matchup', Component: MatchupComponent },
    { name: 'Standings', pathname: 'standings', Component: StandingsComponent },
    { name: 'Playoff', pathname: 'playoff', Component: () => null },
];

import { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../../common/utils/ioc/use-dependencies';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { BackButton } from '../../../common/components/back-button/back-button';
import { MatchOdds } from '../../../common/components/match-odds/match-odds';
import { GameContentStore } from '../../stores/game-content-store';

export const GameContentMatchOdds = observer(() => {
    const [
        {
            matchInfo,
            matchSectionId,
            matchSections,
            matchOddsLoadStatus,
            selectOddSection,
            sportLeagueId,
            oddSubsectionGroups,
        },
    ] = useDependencies(GameContentStore);

    const navigate = useNavigate();

    return (
        <>
            <Box m={{ xs: 2, sm: 0 }} mb={{ sm: 1 }}>
                <BackButton
                    label={'Matches'}
                    onClick={() => navigate(`../${sportLeagueId}`)}
                />
            </Box>
            {!!matchInfo && (
                <MatchOdds
                    onSelectSection={selectOddSection}
                    oddsLoadStatus={matchOddsLoadStatus}
                    oddSubsectionGroups={oddSubsectionGroups}
                    match={matchInfo}
                    matchSections={matchSections}
                    matchSectionId={matchSectionId}
                />
            )}
        </>
    );
});

import moment from 'moment/moment';

export const getTomorrow = () => moment().add(1, 'day').toDate();

export const getDateWithAppliedHour = (date: Date, time: Date) => {
    const finalDate = new Date(date);

    finalDate.setHours(time.getHours());
    finalDate.setMinutes(0);
    finalDate.setSeconds(0);
    finalDate.setMilliseconds(0);

    return finalDate;
};

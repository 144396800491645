import { observer } from 'mobx-react-lite';
import { useDependencies } from '../../common/utils/ioc/use-dependencies';
import { LeaderboardStore } from '../stores/leaderboard-store';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import { BackButton } from '../../common/components/back-button/back-button';
import { Bet } from '../../common/components/bet/bet';
import { GamePlayer } from '../../common/components/game-player/game-player';

export const BetHistoryContent = observer(() => {
    const [{ hideBetHistory, userForBetHistory, betHistory }] =
        useDependencies(LeaderboardStore);

    return (
        <DialogContent sx={{ background: '#f9f9f9' }}>
            <Box mb={2}>
                <BackButton label={'Leaderboard'} onClick={hideBetHistory} />
            </Box>
            <Box px={{ sm: '100px' }}>
                <Box display={'flex'} flexDirection={'column'} gap={1}>
                    {userForBetHistory && (
                        <GamePlayer
                            centered
                            rank={userForBetHistory.rank}
                            player={userForBetHistory.info}
                        />
                    )}
                    {betHistory.map((bet, index) => {
                        return <Bet key={index} bet={bet} />;
                    })}
                </Box>
            </Box>
        </DialogContent>
    );
});
